import {
  iLimit,
  iUseTableTdComponentPreview,
} from "components/editor-builder/component-preview/TableComponentPreview/TableTdComponentPreview";
import {
  DEFAULT_WIDTH_HEADER_TABLE,
  GRID_TEMPLATE_SIZE,
} from "constants/document";
import {
  CellProperty,
  CellSizeSetting,
  DocumentTemplateType,
  LinkedDataField,
  LinkedDynamicFieldsType,
  TableDefaultStyle,
  TableElementType,
  TemplateComponentType,
} from "constants/enum";
import { CellType, TemplateComponent } from "interfaces/models/component";
import { cloneDeep } from "lodash-es";
import Component from "models/component";
import { checkExistsLinkedTable, onDispatchComponents } from "models/document";
import {
  isCommissiontingTableTemplate,
  isPhotoLedgerTemplate,
} from "models/documentCategory";
import {
  DEFAULT_BORDER_COLOR,
  DEFAULT_BORDER_COLOR_ACTIVE,
} from "pages/document/template-page/hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setComponents,
  setComponentSelected,
  setIsComponentDragging,
  setIsEditText,
  setSelectedCell,
} from "redux/documentSlice";
import {
  getAllSubCellSizeAfterResize,
  getIndexCell,
  getLinkedTable,
  getMinColumnWidth,
  getMinHeightCurrentRow,
  getMinWidthCurrentColumn,
  getParentCell,
  getTableSize,
  getTableSizeIncludeSubTable,
  resizeSubTable,
  updateDOM,
  updateRowDOM,
  updateSubTableDOM,
} from "utils/document";
import {
  getAllCells,
  getAllParentCells,
  getCellByCellId,
  getCellPositionByCellId,
  getCellsByColumnId,
  getCellsByRowId,
  getComponentByCellId,
  updateCells,
} from "utils/tableCell";

const useTableTdComponentPreview = (props: iUseTableTdComponentPreview) => {
  const {
    displaySize,
    zoomRatio,
    component,
    cellSize,
    cell,
    isComponentDragging,
    isDuplicateRow,
    canEditCell,
    selectedCells,
    components,
    sizePageRatio,
    pageDirectionRatio,
    documentContainerSize,
    isTableSelected,
    trId,
    tableId,
    currentTemplate,
    componentSelected,
    isResizing,
    onSelectCell,
    numOfColumns,
    columnIndex,
    rowIndex,
    isLastColumn,
    isLastRow,
    isSelected,
    borderColor,
    prevCellEl,
    isResizable,
  } = props;

  const [shouldShowInput, setShouldShowInput] = useState<boolean>(false);
  const [name, setName] = useState<string>(cell?.value ?? "");

  const dispatch = useDispatch();

  const inputRef = useRef<any>(null);
  const nextCell = useRef<CellType>();
  const minCurrentCellSize = useRef<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });
  const [type, setType] = useState("");
  const [currentCellResized, setCurrentCellResized] =
    useState<CellType | null>();

  const positionMouseDown = useRef({ x: 0, y: 0 });
  const currentCellSize = useRef({ width: 0, height: 0 });
  const isResizeCell = useRef(false);
  const sizeDifference = useRef({ width: 0, height: 0 });
  const currentComponent = useRef({} as TemplateComponent);
  const allCellWidth = useRef<number[]>([]);
  const allCellHeight = useRef<number[]>([]);
  const allCellInitWidth = useRef<number[]>([]);
  const repeatCellHeight = useRef<number>(0);
  const mapRowsHeightInitByRowId = useRef<{
    [key: string]: number;
  }>({});
  const mapCellsSizeInitByCellId = useRef<{
    [key: string]: { width: number; height: number };
  }>({});
  const mapCellsSizeByCellId = useRef<{
    [key: string]: {
      width: number;
      height: number;
    };
  }>({});

  const isExistsLinkedTable = useMemo(
    () => checkExistsLinkedTable(components),
    [components]
  );

  const limit: iLimit = useMemo(
    () => ({
      width:
        documentContainerSize.width - TableDefaultStyle.DEFAULT_BORDER_SIZE,
      height:
        documentContainerSize.height - TableDefaultStyle.DEFAULT_BORDER_SIZE,
    }),
    [documentContainerSize.height, documentContainerSize.width]
  );

  const isSelectedAsCopy = useMemo(() => {
    if (
      currentTemplate?.documentType === DocumentTemplateType.SELF_INSPECTION
    ) {
      return false;
    }

    return (
      tableId === componentSelected.componentId &&
      !!componentSelected.linkedHeaderId &&
      !!selectedCells.find(
        (item) =>
          item.cellId !== cell.cellId &&
          item.position?.idColumn === cell.position?.idColumn &&
          !item.isSubCell
      ) &&
      componentSelected?.detail?.isRepeat
    );
  }, [componentSelected, currentTemplate, cell, selectedCells, tableId]);

  useEffect(() => {
    if (cell?.value !== undefined) {
      setName(cell.value);
    }
  }, [cell?.value]);

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, [inputRef, shouldShowInput]);

  const removeListeners = () => {
    window.removeEventListener("mousemove", resizeMouseMove);
    window.removeEventListener("mouseup", resizeMouseUp);
  };
  useEffect(() => {
    if (currentCellResized?.cellId && currentCellResized?.cellId !== "") {
      window.addEventListener("mousemove", resizeMouseMove);
      window.addEventListener("mouseup", resizeMouseUp);
    }

    return () => {
      removeListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCellResized]);

  useEffect(() => {
    if (inputRef.current?.value) {
      updateCellValue(inputRef.current?.value);
    }

    if (isResizing) {
      setShouldShowInput(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResizing]);

  const transformCellWidth = (width: number) => {
    return (width / component.size.width) * displaySize.width;
  };
  const transformCellHeight = useCallback(
    (height: number) => {
      return (height / component.size.height) * displaySize.height;
    },
    [component.size.height, displaySize.height]
  );

  const updateCellValue = (value: string) => {
    const updatedCells = [{ ...cell, value }];

    // update all cells of the affected row
    selectedCells.forEach((selectedCell) => {
      if (!selectedCell?.position) return;

      // Get cells of row
      const allCellsInCurrentRow = getCellsByRowId({
        rowId: selectedCell.position.idRow as string,
        component,
      });

      // mapping value to dynamicFieldLabel field
      allCellsInCurrentRow.forEach((cell) => {
        if (
          cell.cellId !== selectedCell.cellId &&
          cell?.cellLinkedData?.field ===
            LinkedDataField.COMMON.DYNAMIC_FIELDS_FOR_ITEM &&
          cell?.cellLinkedData?.options?.dynamicFieldLabelForCell ===
            selectedCell?.cellLinkedData?.field
        ) {
          const cloneCell = cloneDeep(cell);
          updatedCells.push({
            ...cloneCell,
            cellLinkedData: {
              ...cloneCell?.cellLinkedData,
              options: {
                ...cloneCell?.cellLinkedData?.options,
                dynamicFieldLabel: value,
              },
            },
          } as any);
        }
      });
    });

    updateCells({
      updatedCells,
      updatedComponentIds: [String(component.componentId)],
      components,
      documentType: currentTemplate?.documentType as DocumentTemplateType,
      dispatch,
    });
  };

  const handleBlur = () => {
    updateCellValue(name);
    dispatch(setIsEditText(false));
  };

  const handleOnClick = (e: any) => {
    if (shouldShowInput) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const currentComponent = getComponentByCellId({
      cellId: cell.cellId,
      components,
    });

    if (isComponentDragging) {
      dispatch(setIsComponentDragging(false));
    }

    if (isDuplicateRow) {
      dispatch(setComponentSelected(currentComponent as TemplateComponent));
      dispatch(setSelectedCell([]));

      return;
    }

    const isText =
      cell.cellProperty === CellProperty.TEXT ||
      cell.cellLinkedData?.type === CellProperty.TEXT ||
      cell.cellProperty === CellProperty.NO ||
      cell.cellLinkedData?.type === CellProperty.DYNAMIC_FIELDS_FOR_ITEM;

    if (!canEditCell) {
      dispatch(setIsEditText(true));
    } else if (isText) {
      handleBlur();
    }

    onSelectCell(e, cell);
  };

  const targetLink = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (cell.style?.attach) {
      window.open(cell.style.attach);
    }
  };

  const getWidthCellMerged = (
    arrWidth: number[],
    start: number,
    end: number
  ) => {
    return arrWidth.reduce((width, item, index) => {
      if (index < start || index > end) return width;
      else return width + item;
    }, 0);
  };

  const resizeMouseDown = (
    e: any,
    currentCell: CellType,
    resizeType: TableElementType
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setType(resizeType);
    setCurrentCellResized(currentCell);

    currentComponent.current = cloneDeep(component);
    const tableSize = getTableSize(currentComponent.current);

    const scaleWidth = zoomRatio * sizePageRatio * pageDirectionRatio;
    const scaleHeight = (zoomRatio / sizePageRatio) * pageDirectionRatio;

    currentComponent.current.detail?.rows?.forEach((row) => {
      row.cells?.forEach((cell, index) => {
        const arrWidth = row.cells?.map((i) => i.width ?? 0) ?? [];

        if (cell.cellId === currentCell.cellId) {
          const newCell = row.cells![index + cell.colSpan!];
          nextCell.current =
            index + cell.colSpan! !== tableSize.column
              ? {
                  ...newCell,
                  width:
                    newCell?.colSpan && newCell?.colSpan > 1
                      ? (Number(newCell.width) -
                          getWidthCellMerged(
                            arrWidth,
                            index + cell.colSpan! + 1,
                            index + cell.colSpan! + newCell.colSpan - 1
                          )) *
                        scaleWidth
                      : Number(newCell?.width) * scaleWidth,
                }
              : undefined;
        }
      });
    });

    if (!currentCell) return;

    minCurrentCellSize.current = {
      width: getMinWidthCurrentColumn(currentCell, currentComponent.current),
      height: getMinHeightCurrentRow(currentCell, currentComponent.current),
    };

    positionMouseDown.current = { x: e.clientX, y: e.clientY };
    let currentCellHeight = currentCell?.height!;
    if (
      currentCell?.subTable?.rows?.length &&
      (isTableSelected || currentComponent.current.detail?.isRepeat)
    ) {
      minCurrentCellSize.current = {
        ...minCurrentCellSize.current,
        height: GRID_TEMPLATE_SIZE,
      };
      currentCellHeight = Math.max(
        Math.min(
          ...currentCell?.subTable?.rows?.flatMap(
            (row) => row?.cells?.map((cell) => cell.height!) || []
          )
        ),
        GRID_TEMPLATE_SIZE
      );
    }

    currentCellSize.current = {
      width: currentCell?.width! * scaleWidth || 0,
      height: transformCellHeight(currentCellHeight),
      //height: currentCellHeight! * scaleHeight,
    };

    const allCells = getAllCells(currentComponent.current);

    // store init value
    mapCellsSizeInitByCellId.current = mapCellsSizeByCellId.current =
      Object.assign(
        {},
        ...(allCells?.map((c) => {
          return {
            [c.cellId || ""]: {
              width: (c?.width || 0) * scaleWidth,
              height: transformCellHeight(c?.height || 0),
            },
          };
        }) || ({} as any))
      );

    mapRowsHeightInitByRowId.current = Object.assign(
      {},
      ...(currentComponent.current.detail?.rows?.map((row) => {
        const rowEle = document.getElementById(row.idRow);

        return {
          [row.idRow]: rowEle
            ? Number(getComputedStyle(rowEle).height.replace("px", ""))
            : 0,
        };
      }) || ({} as any))
    );

    const handleMapCellWidth = (cell: CellType, index: number) => {
      let cellWidth = cell?.width!;
      if (cell?.colSpan && cell?.colSpan > 1) {
        const cells =
          currentComponent.current.detail?.rows![0].cells?.map(
            (item) => item.width ?? 0
          ) ?? [];

        cellWidth =
          cell?.width! -
          getWidthCellMerged(cells, index + 1, index + cell.colSpan - 1);
      }

      return cellWidth * scaleWidth ?? 0;
    };

    allCellInitWidth.current =
      currentComponent.current.detail?.rows![0].cells?.map(
        handleMapCellWidth
      ) ?? [];
    allCellWidth.current =
      currentComponent.current.detail?.rows![0].cells?.map(
        handleMapCellWidth
      ) ?? [];

    allCellHeight.current =
      currentComponent.current.detail?.rows![0].cells?.map((cell, index) => {
        let cellHeigth = cell.height!;

        if (cell?.rowSpan && cell?.rowSpan > 1) {
          const cells =
            currentComponent.current.detail?.rows![0].cells?.map(
              (item) => item.height ?? 0
            ) ?? [];

          cellHeigth =
            cell?.height! -
            getWidthCellMerged(cells, index + 1, index + cell.rowSpan - 1);
        }

        return cellHeigth * scaleHeight ?? 0;
      }) ?? [];
  };

  const resizeMouseMoveAllColumn = (deltaX: number) => {
    if (!currentCellResized) {
      return;
    }

    const cellMaxWidthInit = Math.max(...allCellInitWidth.current);
    const allCellByRowId = getCellsByRowId({
      rowId: currentCellResized.position?.idRow || "",
      component: currentComponent.current,
    });
    const allParentCells = getAllParentCells(currentComponent.current)?.filter(
      (cell) => cell.colSpan !== 0
    );

    const numOfCol = getTableSizeIncludeSubTable(
      currentComponent.current
    ).column;

    const offsetWidth = allCellByRowId?.reduce(
      (acc, curr) =>
        acc +
        cellMaxWidthInit -
        mapCellsSizeInitByCellId.current[curr.cellId].width,
      0
    );

    const componentIns = new Component({
      component: currentComponent.current,
      components,
      documentContainerSize,
      scale: zoomRatio,
    });

    let availableSpaceRight =
      componentIns.availableSpace.right * zoomRatio - offsetWidth;

    const isHasLinkedTable =
      component.type === TemplateComponentType.TableHeader &&
      isExistsLinkedTable;
    const linkedTable = getLinkedTable({ components });
    if (isHasLinkedTable) {
      const linkedTableIns = new Component({
        component: linkedTable,
        components,
        documentContainerSize,
        scale: zoomRatio,
      });
      const minRight = Math.min(
        ...[
          componentIns.availableSpace.right,
          linkedTableIns.availableSpace.right,
        ]
      );
      availableSpaceRight = minRight - offsetWidth;
    }

    const isDiffWidth = allCellWidth.current.some(
      (c) => allCellWidth.current[0] - c
    );

    if (deltaX * numOfCol >= availableSpaceRight || isDiffWidth) {
      deltaX = availableSpaceRight / numOfCol;
    }

    sizeDifference.current.width = deltaX;
    if (!nextCell.current) {
      return;
    }

    // Handle change size cell
    const sizeCellCanMove = cellMaxWidthInit + deltaX;

    const componentElement = document.getElementById(
      `resize-${currentComponent.current.componentId}`
    ) as HTMLDivElement;

    const minColumnW = getMinColumnWidth(currentComponent.current);
    const minW = minColumnW.reduce(
      (prev, cur) => prev + cur,
      CellSizeSetting.MIN_WIDTH
    );
    const indexColumn = getCellPositionByCellId({
      cellId: String(currentCellResized?.cellId),
      component,
    })?.index?.col;

    allCellWidth.current = allCellWidth.current.map((_, index) =>
      index >= indexColumn && index < indexColumn + cell.colSpan!
        ? sizeCellCanMove / cell.colSpan!
        : sizeCellCanMove
    );

    if (sizeCellCanMove <= minW / numOfCol) {
      allCellWidth.current = allCellWidth.current.map(() => minW / numOfCol);

      return;
    }

    allParentCells.forEach((cell) => {
      const width = sizeCellCanMove - TableDefaultStyle.DEFAULT_BORDER_SIZE;
      if (componentElement) {
        const widthComponentElement = allCellWidth.current.reduce(
          (acc, cur) => acc + cur,
          0
        );
        componentElement.style.width = `${widthComponentElement}px`;
      }

      updateDOM(cell, width, CellSizeSetting.WIDTH);
    });

    if (isHasLinkedTable) {
      const componentLinkedElement = document.getElementById(
        `resize-${linkedTable.componentId}`
      ) as HTMLDivElement;

      if (componentLinkedElement) {
        const widthComponentElement = allCellWidth.current.reduce(
          (acc, cur) => acc + cur,
          0
        );

        componentLinkedElement.style.width = `${widthComponentElement}px`;
      }

      linkedTable.detail?.rows?.forEach((row) => {
        row.cells?.forEach((cell) => {
          const width = sizeCellCanMove - TableDefaultStyle.DEFAULT_BORDER_SIZE;

          updateDOM(cell, width, CellSizeSetting.WIDTH);
        });
      });
    }
  };

  const resizeMouseMoveSingleColumn = (delta: number) => {
    if (!currentCellResized) {
      return;
    }

    const indexCell = getIndexCell(currentCellResized);
    const listCols =
      component?.detail?.rows?.[indexCell?.row]?.cells?.filter(
        (c) => c.colSpan
      ) || [];
    indexCell.col = listCols.findIndex(
      (cell) => cell.cellId === currentCellResized.cellId
    );

    const idxNextCol = indexCell.col + 1;

    //const offsetColSpan = (currentCellResized.colSpan ?? 1) - 1;
    const maxMovingWidth =
      transformCellWidth(currentCellResized.width!) +
      transformCellWidth(listCols?.[idxNextCol]?.width!) -
      CellSizeSetting.MIN_WIDTH -
      TableDefaultStyle.DEFAULT_BORDER_SIZE;

    const widthMoving = delta;
    const sizeCellCanMove = currentCellSize.current.width + widthMoving;
    const minWidthCellResize =
      minCurrentCellSize.current.width * (currentCellResized.colSpan || 1);

    if (
      sizeCellCanMove > maxMovingWidth ||
      sizeCellCanMove <= minWidthCellResize
    ) {
      return;
    }

    const getAllCellsInColByIndexCol = (idxCol: number) => {
      return getCellsByColumnId({
        columnId: listCols?.[idxCol]?.position?.idColumn as string,
        component,
      });
    };

    const allCellsInCurrentCol = getCellsByColumnId({
      columnId: currentCellResized.position?.idColumn as string,
      component,
    });
    const allCellsInNextCol = getAllCellsInColByIndexCol(idxNextCol);

    const resizeCell = (cells: CellType[], type: "current" | "next") => {
      cells.forEach((cell) => {
        let width =
          transformCellWidth(cell.width!) +
          (type === "current" ? widthMoving : widthMoving * -1);
        const subColLength = cell?.subTable?.rows?.[0]?.cells?.length || 1;
        const minWidth = subColLength * CellSizeSetting.MIN_WIDTH;

        if (cell.colSpan! > 1 && currentCellResized.colSpan! === 1) {
          return;
        }

        if (Math.floor(width) <= minWidth) {
          width = minWidth;
        }

        // handle case merge col
        if (
          currentCellResized?.colSpan! > 1 &&
          cell.colSpan === 1 &&
          cell.position?.idColumn === currentCellResized.position?.idColumn
        ) {
          width =
            transformCellWidth(cell.width!) +
            (type === "current"
              ? widthMoving / currentCellResized?.colSpan!
              : (widthMoving * -1) / currentCellResized?.colSpan!);

          const indexCell = getIndexCell(cell);
          const listCol =
            component?.detail?.rows?.[indexCell.row].cells?.filter(
              (c) => c.colSpan
            ) || [];

          mapCellsSizeByCellId.current[cell.cellId!] = {
            ...mapCellsSizeByCellId.current?.[cell.cellId!],
            width,
          };

          updateDOM(cell, width, CellSizeSetting.WIDTH);
          const arr = Array.apply(
            null,
            new Array(currentCellResized?.colSpan!)
          );

          arr.forEach((_, i) => {
            if (!i) {
              return;
            }
            const nextCell = listCol[indexCell.col + Number(i)];

            if (!nextCell) {
              return;
            }

            mapCellsSizeByCellId.current[nextCell.cellId!] = {
              ...mapCellsSizeByCellId.current?.[nextCell.cellId!],
              width,
            };

            updateDOM(nextCell, width, CellSizeSetting.WIDTH);
          });

          return;
        }

        mapCellsSizeByCellId.current[cell.cellId!] = {
          ...mapCellsSizeByCellId.current?.[cell.cellId!],
          width,
        };

        updateDOM(cell, width, CellSizeSetting.WIDTH);
      });
    };

    resizeCell(allCellsInCurrentCol, "current");
    resizeCell(allCellsInNextCol, "next");

    const isResizeLinkedTable =
      component.type === TemplateComponentType.TableHeader &&
      isExistsLinkedTable;
    if (isResizeLinkedTable) {
      const linkedTables = components.filter(
        (com) => com.linkedHeaderId === component.componentId
      );
      linkedTables.forEach((linkedTable) => {
        const allCellsLinkedTableInCurrentCol = getCellsByColumnId({
          columnId: currentCellResized?.position?.idColumn as string,
          component: linkedTable,
        });
        const allCellsLinkedTableInNextCol = getCellsByColumnId({
          columnId: allCellsInNextCol?.[0]?.position?.idColumn as string,
          component: linkedTable,
        });

        resizeCell(allCellsLinkedTableInCurrentCol, "current");
        resizeCell(allCellsLinkedTableInNextCol, "next");
      });
    }
  };

  const resizeMouseMoveAllRow = useCallback(
    (delta: number) => {
      if (!currentCellResized) {
        return;
      }

      let sizeCellCanMove = 0;
      const numOfRow = getTableSizeIncludeSubTable(
        currentComponent.current
      ).row;

      const componentElement = document.getElementById(
        `resize-${currentComponent.current.componentId}`
      ) as HTMLDivElement;

      const componentIns = new Component({
        component: currentComponent.current,
        components,
        documentContainerSize,
        scale: zoomRatio,
      });

      const cellsByColumnId = getCellsByColumnId({
        columnId: currentCellResized.position?.idColumn || "",
        component: currentComponent.current,
      });

      const rowMaxHeigthInit = transformCellHeight(
        Math.max(
          ...cellsByColumnId.map(
            (c) => c?.height! / (c.subTable?.rows?.length || 1) || 0
          )
        )
      );

      const isDiffHeight = allCellHeight.current.some(
        (h) => allCellHeight.current[0] - h
      );

      const offsetHeight = cellsByColumnId?.reduce((acc, curr) => {
        const height = mapCellsSizeInitByCellId.current[curr.cellId].height;
        if (curr.subTable?.rows?.length) {
          const offset: number =
            curr.subTable.rows[0].cells?.reduce(
              (a, c) =>
                a + rowMaxHeigthInit - transformCellHeight(c.height || 0),
              0
            ) || 0;

          return acc + offset;
        }

        return acc + rowMaxHeigthInit - height;
      }, 0);

      const bottom =
        componentIns.availableSpace.bottom * zoomRatio - offsetHeight;

      if (delta * numOfRow >= bottom || isDiffHeight) {
        delta = bottom / numOfRow;
      }

      sizeCellCanMove = rowMaxHeigthInit + delta;

      if (
        sizeCellCanMove <=
        minCurrentCellSize.current.height *
          (currentCellResized.subTable?.rows?.length || 1)
      ) {
        sizeCellCanMove =
          minCurrentCellSize.current.height *
          (currentCellResized.subTable?.rows?.length || 1);
      }

      if (isDiffHeight) {
        allCellHeight.current = allCellHeight.current.map(
          () => sizeCellCanMove
        );
      }

      repeatCellHeight.current = sizeCellCanMove;
      currentComponent.current.detail?.rows?.forEach((row) => {
        const allCellsInCurrentRow = getCellsByRowId({
          rowId: row.cells![0].position?.idRow as string,
          component,
        });
        allCellsInCurrentRow.forEach((cell) => {
          let subTableRowCount = cell.subTable?.rows?.length;

          if (!!subTableRowCount && cell.subTable) {
            const subDeltaHeight = currentComponent.current.detail?.isRepeat
              ? sizeCellCanMove / subTableRowCount
              : sizeCellCanMove * (subTableRowCount || 1) - cell.height!;

            const subDelta = {
              width: 0,
              height: subDeltaHeight,
            };

            let allSubCellSize = getAllSubCellSizeAfterResize({
              parentCell: cell,
              subTable: cell.subTable,
              delta: subDelta,
            });

            if (currentComponent.current.detail?.isRepeat) {
              allSubCellSize = allSubCellSize.map((cellSize) =>
                cellSize.map(({ width }) => ({
                  width,
                  height: sizeCellCanMove / subTableRowCount!,
                }))
              );
            }

            updateSubTableDOM(cell.subTable, allSubCellSize, subDelta);
          }

          if (cell.isSubCell) {
            const parentCell = getParentCell(cell, component);
            subTableRowCount = parentCell.subTable?.rows?.length || 1;
          }

          updateDOM(
            cell,
            sizeCellCanMove / (subTableRowCount || 1),
            CellSizeSetting.HEIGHT
          );
        });
      });

      // only update blank cell if type linked image
      if (currentComponent.current.type === TemplateComponentType.LinkedImage) {
        currentComponent.current.detail?.rows?.forEach((row, indexRow) => {
          const isLinkedImageLine = !!row.cells?.find(
            (cell) => cell.cellProperty === CellProperty.LINKED_IMAGE_LINE
          )?.cellId;
          const isLinkedImageDefaultText = !!row.cells?.find(
            (cell) =>
              cell.cellProperty === CellProperty.LINKED_IMAGE_DEFAULT_TEXT
          )?.cellId;

          if (isLinkedImageLine || isLinkedImageDefaultText) {
            updateRowDOM(
              `${currentComponent.current.componentId}-tr-${indexRow}`,
              sizeCellCanMove
            );
          }
        });
      } else {
        currentComponent.current.detail?.rows?.forEach((row, indexRow) => {
          updateRowDOM(
            `${currentComponent.current.componentId}-tr-${indexRow}`,
            sizeCellCanMove *
              (Number(row.cells![0]?.subTable?.rows?.length) || 1)
          );
        });
      }

      // change size component
      const componentElementHeight = sizeCellCanMove * numOfRow;
      componentElement.style.height = `${componentElementHeight}px`;

      return { sizeCellCanMove, heightMoving: delta };
    },
    [
      component,
      currentCellResized,
      components,
      documentContainerSize,
      zoomRatio,
      transformCellHeight,
    ]
  );

  const resizeMouseMoveSingleRow = useCallback(
    (delta: number) => {
      if (!currentCellResized) {
        return;
      }

      const listRow = structuredClone(component?.detail?.rows) || [];
      const idxCurrentRow = listRow?.findIndex(
        (row) =>
          row?.cells?.[0].position?.idRow === currentCellResized.position?.idRow
      );
      let idxNextRow = -1;
      const offsetRowSpan = (currentCellResized.rowSpan ?? 1) - 1;

      const numOfRow = getTableSizeIncludeSubTable(
        currentComponent.current
      ).row;

      if (![-1, numOfRow].includes(idxCurrentRow)) {
        idxNextRow = idxCurrentRow + 1 + offsetRowSpan;
      }

      const heightMoving = delta;
      const sizeCellCanMove = currentCellSize.current.height + heightMoving;
      const remainRow = listRow.slice(
        idxCurrentRow,
        idxCurrentRow + 2 + offsetRowSpan
      );
      const maxMovingHeight =
        remainRow
          .map((e) => mapRowsHeightInitByRowId.current[e.idRow])
          .reduce((acc, curr) => acc + (curr || 0), 0) -
        CellSizeSetting.MIN_HEIGHT -
        TableDefaultStyle.DEFAULT_BORDER_SIZE;

      if (sizeCellCanMove > maxMovingHeight) {
        return;
      }

      const isHasCellExceedHeight = Object.keys(
        mapCellsSizeByCellId.current
      ).some((cellId) => {
        const cell = getCellByCellId({
          component: currentComponent.current,
          cellId,
        });
        const length = cell?.subTable?.rows?.length || 1;
        const height =
          mapCellsSizeByCellId?.current[cellId]?.height! +
          TableDefaultStyle.DEFAULT_BORDER_SIZE;

        return (
          heightMoving <= 0 &&
          Math.floor(height) < Math.floor(length * CellSizeSetting.MIN_HEIGHT)
        );
      });

      const minHeightCellResize =
        minCurrentCellSize.current.height * (currentCellResized.rowSpan || 1);
      if (isHasCellExceedHeight || sizeCellCanMove <= minHeightCellResize) {
        return;
      }

      const allCellsInCurrentRow = getCellsByRowId({
        rowId: currentCellResized.position?.idRow as string,
        component,
      });

      const getAllCellsInRowByIndexRow = (idxRow: number) => {
        return getCellsByRowId({
          rowId: listRow?.[idxRow]?.cells?.[0]?.position?.idRow as string,
          component,
        });
      };

      const resizeCell = (cells: CellType[], type: "current" | "next") => {
        cells.forEach((cell) => {
          let height =
            transformCellHeight(cell.height!) +
            (type === "current" ? heightMoving : heightMoving * -1);
          const subRowLength = cell?.subTable?.rows?.length || 1;
          const minHeight = subRowLength * CellSizeSetting.MIN_HEIGHT;

          if (cell.rowSpan! > 1 && currentCellResized.rowSpan! === 1) {
            return;
          }

          if (Math.floor(height) <= minHeight) {
            height = minHeight;
          }

          if (!!cell.subTable?.rows?.length && height !== cell.height) {
            const subDelta = {
              width: 0,
              height: height - transformCellHeight(cell.height!),
            };
            const allSubCellSize = getAllSubCellSizeAfterResize({
              parentCell: cell,
              subTable: cell.subTable,
              delta: subDelta,
            });
            updateSubTableDOM(cell.subTable, allSubCellSize, subDelta);
          }

          mapCellsSizeByCellId.current[cell.cellId!] = {
            ...mapCellsSizeByCellId.current?.[cell.cellId!],
            height,
          };

          updateDOM(cell, height, CellSizeSetting.HEIGHT);
        });
      };

      resizeCell(allCellsInCurrentRow, "current");
      let rowHeight = sizeCellCanMove;
      if (currentCellResized.rowSpan! > 1) {
        const rowHeightInit =
          mapRowsHeightInitByRowId?.current[currentCellResized.idRow || ""] ||
          0;
        rowHeight = rowHeightInit + heightMoving;
      }
      const minRowHeight = getMinHeightCurrentRow(
        currentCellResized,
        currentComponent.current
      );
      if (rowHeight <= minRowHeight) {
        return;
      }

      updateRowDOM(currentCellResized.idRow, rowHeight);

      const allCellsInNextRow = getAllCellsInRowByIndexRow(idxNextRow);

      const nextRow = listRow?.[idxNextRow];
      const rowHeightInit =
        mapRowsHeightInitByRowId?.current[nextRow.idRow || ""] || 0;
      rowHeight = rowHeightInit - heightMoving;

      resizeCell(allCellsInNextRow, "next");
      updateRowDOM(nextRow?.idRow, rowHeight);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cell,
      limit,
      trId,
      pageDirectionRatio,
      sizePageRatio,
      component,
      currentCellResized,
      components,
      zoomRatio,
    ]
  );

  const resizeMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!currentCellResized) {
        return;
      }

      isResizeCell.current = true;

      //Case resize column
      if (type === TableElementType.COL) {
        const deltaX = e.clientX - positionMouseDown.current.x;
        resizeMouseMoveSingleColumn(deltaX);

        return;
      }

      //Case resize row
      if (type === TableElementType.ROW) {
        const heightMoving = e.clientY - positionMouseDown.current.y;
        resizeMouseMoveSingleRow(heightMoving);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isTableSelected,
      pageDirectionRatio,
      sizePageRatio,
      limit,
      component,
      cell,
      cellSize,
      currentCellResized,
      components,
      documentContainerSize,
      type,
      zoomRatio,
      resizeMouseMoveSingleRow,
      resizeMouseMoveAllRow,
    ]
  );

  const handleResizeComponent = (
    type: string,
    size: { width: number; height: number },
    cellId: string,
    targetComponent?: TemplateComponent
  ) => {
    const newComponent = targetComponent || currentComponent.current;

    let listRow = [...(newComponent?.detail?.rows ?? [])];
    let sizeComponent = newComponent.size;

    // Temporarily hard code for hide resize all row or column func
    const _isTableSelected = false;

    if (type === TableElementType.COL || type === TableElementType.COL_LINKED) {
      const scale = 1 / zoomRatio / sizePageRatio / pageDirectionRatio;
      if (newComponent.size) {
        sizeComponent = {
          ...newComponent.size,
          width: _isTableSelected
            ? allCellWidth.current.reduce((sum, item) => sum + item, 0) * scale
            : newComponent.size.width,
        };
      }

      listRow = listRow.map((row) => {
        let listColumn = row?.cells ?? [];

        listColumn = listColumn.map((column, indexColumn) => {
          const mapWidth = mapCellsSizeByCellId.current?.[column.cellId]?.width;
          const newWidth = _isTableSelected
            ? allCellWidth.current[indexColumn] * scale
            : mapWidth
            ? mapWidth * scale
            : column.width!;

          return {
            ...column,
            width: newWidth,
            subTable:
              !!column.subTable?.rows?.length && newWidth !== column.width
                ? resizeSubTable(column, column.subTable, {
                    width: newWidth - column.width!,
                    height: 0,
                  })
                : column.subTable,
          };
        });

        return {
          ...row,
          cells: listColumn,
        };
      });
    } else if (type === TableElementType.ROW) {
      const scale = (zoomRatio * sizePageRatio) / pageDirectionRatio;
      const numOfRow = getTableSizeIncludeSubTable(newComponent).row;

      if (newComponent.size) {
        sizeComponent = {
          ...newComponent.size,
          height:
            _isTableSelected || newComponent.detail?.isRepeat
              ? (repeatCellHeight.current * numOfRow) / scale
              : newComponent.size.height,
        };
      }
      listRow =
        _isTableSelected || newComponent.detail?.isRepeat
          ? listRow.map((row) => {
              const cells = row.cells?.map((cell) => {
                const rowHeight = cell.height
                  ? newComponent.detail?.isRepeat
                    ? (repeatCellHeight.current * (cell.rowSpan || 1)) / scale
                    : (repeatCellHeight.current *
                        (cell.subTable?.rows?.length || cell.rowSpan || 1)) /
                      scale
                  : size.height;

                return {
                  ...cell,
                  height: rowHeight,
                  subTable:
                    cell.subTable?.rows?.length && rowHeight !== cell.height
                      ? resizeSubTable(
                          cell,
                          cell.subTable,
                          {
                            width: 0,
                            height:
                              rowHeight / (cell.subTable.rows.length || 1),
                          },
                          { isResizeAllRow: true }
                        )
                      : cell.subTable,
                };
              });

              return {
                ...row,
                cells,
              };
            })
          : listRow.map((row) => {
              const listColumn = row?.cells?.map((column) => {
                const newHeight =
                  mapCellsSizeByCellId.current[column.cellId || ""].height /
                  scale;

                return {
                  ...column,
                  height: newHeight,
                  subTable:
                    !!column.subTable?.rows?.length &&
                    newHeight !== column.height
                      ? resizeSubTable(column, column.subTable, {
                          width: 0,
                          height: newHeight - column.height!,
                        })
                      : column.subTable,
                };
              });

              return {
                ...row,
                cells: listColumn,
              };
            });
    }

    const newTable = {
      ...newComponent,
      size: {
        width: sizeComponent?.width,
        height: sizeComponent?.height,
      },
      realSize: {
        width: sizeComponent?.width * sizePageRatio * pageDirectionRatio,
        height: sizeComponent?.height * (sizePageRatio / pageDirectionRatio),
      },
      detail: {
        ...newComponent?.detail,
        rows: listRow,
      },
    } as TemplateComponent;

    if (
      type === TableElementType.ROW ||
      (!(
        isExistsLinkedTable &&
        newComponent.type === TemplateComponentType.TableHeader
      ) &&
        !targetComponent)
    ) {
      const selectedCellHasResize = getCellByCellId({
        cellId: selectedCells?.[0]?.cellId,
        component: newTable,
      });
      if (selectedCellHasResize?.cellId) {
        dispatch(setSelectedCell([selectedCellHasResize]));
      }

      onDispatchComponents({ newData: newTable, components });
    } else if (
      type === TableElementType.COL ||
      type === TableElementType.COL_LINKED
    ) {
      if (targetComponent) {
        return newTable;
      }

      const newListComponent = [...components];
      newListComponent.forEach((item, index) => {
        if (item.componentId === newComponent.componentId) {
          newListComponent[index] = newTable;
        } else if (!!item.linkedHeaderId) {
          const movingSize =
            (size.width * item.size.width) / newComponent.size.width;

          newListComponent[index] =
            handleResizeComponent(
              type,
              {
                ...size,
                width: movingSize,
              },
              `${item.componentId}-${cellId}`,
              item
            ) || item;
        }
      });

      dispatch(setComponents(newListComponent));
    }
  };

  const resizeMouseUp = () => {
    setCurrentCellResized(null);
    setType("");
    removeListeners();
    // change size component & column
    if (isResizeCell.current && currentCellResized?.cellId && type) {
      handleResizeComponent(
        type,
        sizeDifference.current,
        currentCellResized?.cellId
      );
    }
    isResizeCell.current = false;
  };

  const handleChangeName = useCallback((e: React.ChangeEvent<HTMLElement>) => {
    const { value } = e.target as any;
    setName(value);
  }, []);

  const handleBlurName = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (e.target.value === cell.value) {
      setShouldShowInput(false);

      return;
    }

    setShouldShowInput(false);
    handleBlur();
  };

  const handleShowEditInput = useCallback(() => {
    const isHeader = component?.type == TemplateComponentType.TableHeader;
    const isCellDefaultValue =
      cell?.cellLinkedData?.options?.dynamicFieldType ===
        LinkedDynamicFieldsType.TEXT ||
      cell?.cellLinkedData?.options?.dynamicFieldType ===
        LinkedDynamicFieldsType.NUMBER ||
      !!cell?.subTable;

    const isInvalid =
      !!cell?.subTable?.rows?.length ||
      !(
        isHeader ||
        cell.cellLinkedData?.type === CellProperty.TEXT ||
        cell.cellLinkedData?.type === CellProperty.DYNAMIC_FIELDS_FOR_ITEM ||
        cell.cellProperty === CellProperty.NO ||
        cell.cellProperty === CellProperty.TEXT ||
        cell.cellProperty === CellProperty.CHECK_BOX
      ) ||
      !!cell.isRepeatedTable ||
      !!cell.isRepeatedRow ||
      (!isHeader && isCellDefaultValue);

    if (isInvalid) {
      return;
    }

    if (inputRef.current) {
      inputRef.current?.focus();
    }

    setShouldShowInput(true);
  }, [cell, component]);

  const rootCellsLength = useMemo(
    () =>
      Math.round(
        Number(numOfColumns) / Number(component?.detail?.numOfRepeatTable)
      ),
    [component, numOfColumns]
  );

  const borderStyle = useMemo(() => {
    //Case select repeated table
    if (
      componentSelected.componentId === component.componentId &&
      cell?.isRepeatedTable &&
      // component.type &&
      selectedCells.length === 0
    ) {
      if (columnIndex === rootCellsLength) {
        if (rowIndex === 0) {
          return "dashed solid dashed solid";
        }

        if (isLastRow) {
          return "solid solid dashed dashed";
        }

        return "solid solid solid dashed";
      }

      if (isLastColumn) {
        if (rowIndex === 0) {
          return "dashed dashed dashed solid";
        }

        if (isLastRow) {
          return "solid dashed dashed solid";
        }

        return "dashed dashed solid solid";
      }

      if (rowIndex === 0) {
        return "dashed solid dashed solid";
      }

      if (isLastRow) {
        return "solid solid dashed solid";
      }

      return "dashed solid solid solid";
    }

    if (isSelected) {
      if (
        cell?.repeatedTableIndex ||
        cell?.isRepeatedRow ||
        cell.isRepeatedTable
      ) {
        return "dashed";
      }

      return "double";
    }

    if (borderColor) {
      return "solid";
    }

    return "initial";
  }, [
    componentSelected,
    component,
    cell,
    selectedCells,
    isSelected,
    borderColor,
    columnIndex,
    rootCellsLength,
    isLastColumn,
    rowIndex,
    isLastRow,
  ]);

  const dataStyle = cell?.style;

  const _borderColor = useMemo(() => {
    //Case select repeated table
    if (
      componentSelected.componentId === component.componentId &&
      cell?.isRepeatedTable &&
      selectedCells.length === 0
    ) {
      const borderColor = DEFAULT_BORDER_COLOR_ACTIVE;

      if (cell?.isRepeatedTable && columnIndex === rootCellsLength) {
        if (rowIndex === 0) {
          return `${borderColor} ${DEFAULT_BORDER_COLOR} ${borderColor} ${borderColor}`;
        }

        if (isLastRow) {
          return `${DEFAULT_BORDER_COLOR} ${DEFAULT_BORDER_COLOR} ${borderColor} ${borderColor}`;
        }

        return `${DEFAULT_BORDER_COLOR} ${DEFAULT_BORDER_COLOR} ${DEFAULT_BORDER_COLOR} ${borderColor}`;
      }

      if (cell?.isRepeatedTable && isLastColumn) {
        if (rowIndex === 0) {
          return `${borderColor} ${borderColor} ${borderColor} ${DEFAULT_BORDER_COLOR}`;
        }

        if (isLastRow) {
          return `${DEFAULT_BORDER_COLOR} ${borderColor} ${borderColor} ${DEFAULT_BORDER_COLOR}`;
        }

        return `${borderColor} ${borderColor} ${DEFAULT_BORDER_COLOR} ${DEFAULT_BORDER_COLOR}`;
      }

      if (rowIndex === 0) {
        return `${borderColor} ${DEFAULT_BORDER_COLOR}`;
      }

      if (isLastRow) {
        return `${DEFAULT_BORDER_COLOR} ${DEFAULT_BORDER_COLOR} ${borderColor} ${DEFAULT_BORDER_COLOR}`;
      }

      return `${DEFAULT_BORDER_COLOR}`;
    }

    if (dataStyle?.border === undefined) {
      return DEFAULT_BORDER_COLOR;
    }

    return `${borderColor}`;
  }, [
    componentSelected,
    component,
    cell,
    selectedCells,
    dataStyle,
    borderColor,
    columnIndex,
    rootCellsLength,
    isLastColumn,
    rowIndex,
    isLastRow,
  ]);

  const borderWidth = useMemo(() => {
    if (!dataStyle?.border) {
      return "0px";
    }

    //Case select repeated table
    if (
      componentSelected.componentId === component?.componentId &&
      cell?.isRepeatedTable &&
      selectedCells.length === 0
    ) {
      if (columnIndex === rootCellsLength) {
        if (rowIndex === 0) {
          return "2px 1px 2px 1.5px";
        }

        return "1px 1px 1px 1.5px";
      }

      if (isLastColumn) {
        if (rowIndex === 0) {
          return "2px 2px 2px 1px";
        }

        return "1px";
      }

      if (rowIndex === 0) {
        return "2px 1px 2px 1px";
      }

      return "1px";
    }

    if (
      cell?.isRepeatedTable &&
      // component?.type === TemplateComponentType.TableHeader &&
      selectedCells?.some((item) => item?.cellId === cell?.cellId)
    ) {
      return "1.5px";
    }

    if ((cell?.repeatedTableIndex || cell?.isRepeatedRow) && isSelected) {
      return "1.5px";
    }

    if (component?.size?.width < DEFAULT_WIDTH_HEADER_TABLE && isResizable) {
      return "0px 1px 1px 1px";
    }

    if (dataStyle?.border) {
      const top = dataStyle?.borderTop ? "1px" : "0px";
      const right = !dataStyle?.borderRight && !isSelected ? "0px" : "1px";

      const bottom = dataStyle?.borderBottom ? "1px" : "0px";
      const left =
        (!dataStyle?.borderLeft || prevCellEl?.subTable) && !isSelected
          ? "0px"
          : "1px";

      return `${top} ${right} ${bottom} ${left}`;
    }

    return "1px";
  }, [
    dataStyle,
    cell,
    componentSelected.componentId,
    component,
    selectedCells,
    isSelected,
    isResizable,
    columnIndex,
    rootCellsLength,
    isLastColumn,
    rowIndex,
    prevCellEl?.subTable,
  ]);

  const isShowCopiedLabel = useMemo(() => {
    return (
      isTableSelected &&
      rowIndex === 1 &&
      isTableSelected &&
      !!component.linkedHeaderId &&
      (isLastColumn || isSelectedAsCopy) &&
      (isCommissiontingTableTemplate(currentTemplate.documentType) ||
        isPhotoLedgerTemplate(currentTemplate.documentType)) &&
      component?.detail?.isRepeat
    );
  }, [
    component,
    currentTemplate.documentType,
    isLastColumn,
    isSelectedAsCopy,
    isTableSelected,
    rowIndex,
  ]);

  return {
    shouldShowInput,
    inputRef,
    name,
    isSelectedAsCopy,
    limit,
    borderStyle,
    borderWidth,
    _borderColor,
    isShowCopiedLabel,
    resizeMouseDown,
    handleShowEditInput,
    handleOnClick,
    handleChangeName,
    handleBlurName,
    targetLink,
  };
};

export default useTableTdComponentPreview;
