import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  FlexProps,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useBoolean,
  Wrap,
  Text,
  PopoverProps,
  PlacementWithLogical,
  Spinner,
} from "@chakra-ui/react";
import { PCTooltip } from "components/PCTooltip";
import { SvgIcon } from "components/SvgIcon";
import Tag from "components/ui/TagInput/Tag";
import { DEFAULT_HEIGHT_FOOTER_DOCUMENT_MODAL } from "constants/document";
import { Z_INDEX_HEADER } from "constants/header";
import useOnClickOutside from "hooks/useOnClickOutside";
import React, {
  JSXElementConstructor,
  MouseEvent,
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDeviceSelectors } from "react-device-detect";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { isEmpty, isArray } from "lodash-es";

export interface Option {
  name: string | ReactElement<any, string | JSXElementConstructor<any>>;
  value: string;
}

export interface DropdownTagsInputProps {
  name?: string;
  value?: string[];
  options?: Option[];
  styleProps?: FlexProps;
  fullWidth?: boolean;
  styleContent?: any;
  styleTag?: any;
  styleWrapTag?: any;
  placeHolder?: string;
  isShowIcon?: boolean;
  allOptions?: Option[];
  showOptionAll?: boolean;
  isShowSearch?: boolean;
  valueSearch?: string;
  onChange?: (tags?: string[]) => void;
  onCloseModal?: (value: string[]) => void;
  onTagRemove?: (tags?: string[]) => void;
  onMouseLeave?: (event: MouseEvent<HTMLDivElement>) => void;
  onSearch?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultName?: string;
  parentRef?: React.RefObject<HTMLDivElement>;
  isFilterDocument?: boolean;
  strategy?: "absolute" | "fixed";
  iconType?: "checkbox" | "eye";
  popoverProps?: PopoverProps;
  isShowOrder?: boolean;
  placement?: PlacementWithLogical;
  icon?: any;
  iconProps?: any;
  placeholderProps?: any;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const DropdownTagElement = ({
  name,
  value,
  options = [],
  placeHolder = "",
  styleProps,
  styleTag = {},
  styleContent = {},
  styleWrapTag = {},
  fullWidth,
  isShowIcon = true,
  allOptions,
  showOptionAll = false,
  isShowSearch = false,
  valueSearch,
  onMouseLeave,
  onChange,
  onCloseModal,
  onSearch,
  defaultName,
  onTagRemove,
  parentRef,
  isFilterDocument = false,
  strategy = "absolute",
  iconType = "checkbox",
  popoverProps,
  isShowOrder = false,
  icon,
  iconProps = {},
  placeholderProps = {},
  isDisabled,
  placement,
  isLoading,
}: DropdownTagsInputProps) => {
  const [tags, setTags] = useState<string[] | undefined>(value);
  const [isPlacementTop, setIsPlacementTop] = useState(false);
  const triggerRef = useRef<any>();
  const [isOpen, setIsOpen] = useBoolean();
  const tagInputRef = useRef() as MutableRefObject<any>;
  const containerRef = useRef() as MutableRefObject<any>;
  const menuRef = useRef() as MutableRefObject<any>;
  const [inputWidth, setInputWidth] = useState<number>();
  const { documentSubCategorySelected } = useSelector(
    (state: RootState) => state.document
  );
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  useEffect(() => {
    if (!tagInputRef.current) {
      return;
    }
    const boundingTagInput = tagInputRef.current.getBoundingClientRect();

    setInputWidth(boundingTagInput.width);
  }, [tagInputRef.current]);

  useEffect(() => {
    const mapOptions: any =
      allOptions?.reduce((prev: any, item) => {
        prev[item.value] = item;

        return prev;
      }, {}) ?? {};
    if (isArray(value)) {
      setTags(value?.filter((id) => !!mapOptions[id]));
    } else if (mapOptions[value as any]) {
      setTags(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(value), allOptions]);

  const handleDisplayDropdown = (
    containerRef: React.MutableRefObject<any>,
    menuRef: React.MutableRefObject<any>
  ) => {
    if (!containerRef?.current || !menuRef?.current) return;

    const menuHeight = menuRef.current.getBoundingClientRect().height;
    const yFooter = window.innerHeight - DEFAULT_HEIGHT_FOOTER_DOCUMENT_MODAL;
    const boundingContainer = containerRef.current.getBoundingClientRect();

    const spacingBottom =
      yFooter - boundingContainer.y - boundingContainer.height;

    if (spacingBottom >= menuHeight && spacingBottom >= 50) {
      setIsPlacementTop(false);
    } else {
      setIsPlacementTop(true);
    }
  };

  useEffect(() => {
    if (
      !parentRef?.current ||
      !containerRef.current ||
      !menuRef.current ||
      !isOpen
    )
      return;

    parentRef.current.addEventListener("scroll", () =>
      handleDisplayDropdown(containerRef, menuRef)
    );

    return () => {
      parentRef.current?.removeEventListener("scroll", () =>
        handleDisplayDropdown(containerRef, menuRef)
      );
    };
  });

  useEffect(() => {
    if (!containerRef.current || !menuRef.current || !isOpen) return;

    handleDisplayDropdown(containerRef, menuRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, containerRef.current, menuRef.current]);

  const handleTagRemove = (text: string) => {
    const newTags = tags?.filter((tag) => tag !== text);
    setTags(tags?.filter((tag) => tag !== text));
    onChange?.(newTags);

    if (onTagRemove) {
      onTagRemove(newTags);
    }
  };

  useEffect(() => {
    if (tagInputRef.current) {
      tagInputRef.current.scrollTop = tagInputRef.current.scrollHeight;
    }
  }, [tags]);

  const handleClick = () => {
    if (isDisabled) return;
    if (!isOpen) {
      setIsOpen.on();

      return;
    }

    setIsOpen.off();
  };

  useOnClickOutside(containerRef, () => {
    if (onCloseModal && isOpen) {
      onCloseModal(tags || []);
    }

    if (isOpen) {
      setIsOpen.off();
    }
  });

  const handleChangeListOfVisibleDocuments = (id: string) => () => {
    if (value?.includes(id)) {
      const newValue = value?.filter((item) => item !== id);
      onChange?.(newValue);

      return;
    }

    onChange?.([...(value || []), id]);
  };

  const handleCheckAll = () => {
    const isCheckAll = tags?.length === options.length;

    if (isCheckAll) {
      onChange?.([]);

      return;
    }

    onChange?.(options?.map((option) => option.value));
  };

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => {
        if (onCloseModal) {
          onCloseModal(tags || []);
        }
      }}
      closeOnBlur
      isLazy
      lazyBehavior="keepMounted"
      placement={placement ? placement : isPlacementTop ? "top" : "bottom"}
      offset={isFilterDocument ? [-15, 0] : [0, 0]}
      strategy={strategy}
      {...popoverProps}
    >
      <Box
        ref={containerRef}
        w="100%"
        sx={{ "& > .chakra-popover__popper": { zIndex: Z_INDEX_HEADER + 1 } }}
      >
        <PopoverTrigger>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            p={
              !options.length || value?.length === options.length ? 0 : "0.8rem"
            }
            pl="0.5rem"
            minH="4.2rem"
            border="1px solid var(--primary-border-color)"
            opacity={isDisabled || isLoading ? 0.4 : 1}
            cursor="pointer"
            borderRadius="4px"
            onMouseLeave={onMouseLeave}
            onClick={handleClick}
            ref={triggerRef}
            {...styleProps}
          >
            {isLoading ? (
              <Text color="#A3A3A3" {...placeholderProps}>
                {placeHolder}
              </Text>
            ) : (
              <Wrap
                // pl="2.4rem"
                flex="1"
                spacing="0.8rem"
                alignItems="center"
                display="flex"
                className="demo12"
                sx={
                  styleWrapTag?.sx || {
                    ul: {
                      maxWidth: styleWrapTag.maxW || "100%",
                      maxHeight: styleWrapTag.maxH || "10rem",
                      overflowY: "auto",
                      overflowX: "hidden",
                      margin: 0,
                      minHeight: "4.2rem",
                      alignItems: "center",
                    },
                  }
                }
                maxH="10rem"
                minH="38px"
                {...styleWrapTag}
                ref={tagInputRef}
              >
                {(tags || [])?.length > 0 &&
                tags?.length === options.length &&
                name ? (
                  name
                ) : Number(tags?.length) > 0 ? (
                  tags?.map((tag, index) => {
                    const order = options?.findIndex(
                      (item) => item?.value === tag
                    );

                    const numOfOrder = isShowOrder ? `No. ${order + 1} : ` : "";
                    const text = (allOptions || options)?.find(
                      (option) => option?.value === tag
                    )?.name;

                    const label = `${numOfOrder}${text}` || "";

                    const isElement = React.isValidElement(text);
                    const tagElement = (
                      <Tag
                        bg={"#CFCFCF"}
                        color="#000"
                        key={index}
                        lineHeight="1.6rem"
                        maxW={0.75 * Number(inputWidth)}
                        {...styleTag}
                        styleText={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        text={isElement ? text : label}
                        remove={() => {
                          if (isDisabled || isLoading) return;
                          handleTagRemove(tag);
                        }}
                      />
                    );

                    if (!label) {
                      return <React.Fragment key={index} />;
                    }

                    if (isElement) {
                      return tagElement;
                    }

                    return (
                      <PCTooltip key={index} label={label}>
                        <Box>{tagElement}</Box>
                      </PCTooltip>
                    );
                  })
                ) : !value?.length ? (
                  defaultName || (
                    <Text color="#A3A3A3" {...placeholderProps}>
                      {placeHolder}
                    </Text>
                  )
                ) : (
                  <Text color="#A3A3A3" {...placeholderProps}>
                    {placeHolder}
                  </Text>
                )}
              </Wrap>
            )}
            {isLoading ? (
              <Flex minW="20px" {...iconProps}>
                <Spinner />
              </Flex>
            ) : (
              isShowIcon &&
              (icon ? (
                icon
              ) : (
                <SvgIcon
                  fill={styleTag?.color || "#000"}
                  minW="20px"
                  src={"/img/icon-navigation-arrow_drop_down.svg"}
                  transition="0.4s"
                  transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"}
                  {...iconProps}
                />
              ))
            )}
          </Flex>
        </PopoverTrigger>
        <PopoverContent
          ref={menuRef}
          display={isOpen ? "block" : "none"}
          p="0"
          my=".5rem"
          width={fullWidth ? containerRef.current?.clientWidth : "100%"}
          zIndex={10}
          {...styleContent}
          sx={{
            "::-webkit-scrollbar": {
              display: isMobile ? "none" : "block",
            },
          }}
        >
          {isShowSearch && (
            <InputGroup
              position="sticky"
              top="0"
              bg="white"
              zIndex={1000}
              p="1rem 1rem"
              borderBottom="1px solid var(--primary-border-color)"
            >
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray.300" />}
                mt="1.4rem"
                ml="1rem"
                fontSize="1.4rem"
              />
              <Input
                bg="white"
                h="3.4rem"
                fontSize="1.4rem"
                value={valueSearch}
                onChange={onSearch}
              />
            </InputGroup>
          )}
          {showOptionAll &&
            (iconType === "checkbox" ? (
              <Checkbox
                p="0 1.5rem"
                mb=".5rem"
                isChecked={tags?.length === options.length}
                onChange={(e) => {
                  const value = e.target.checked;
                  if (isDisabled || isLoading) return;
                  if (value) {
                    onChange?.(options.map((option) => option.value));
                  } else {
                    onChange?.([]);
                  }
                }}
              >
                全て
              </Checkbox>
            ) : (
              <Flex
                pl="15px"
                cursor="pointer"
                pb="5px"
                pt="5px"
                borderBottom="1px solid var(--primary-border-color)"
                _hover={{
                  background: "#F0F9FF",
                }}
                onClick={handleCheckAll}
              >
                <Img
                  src={
                    tags?.length === options.length
                      ? "/img/eye.svg"
                      : "/img/eye-off.svg"
                  }
                  height="20px"
                  mr="10px"
                />
                <Text fontSize="14px">全て</Text>
              </Flex>
            ))}

          {iconType === "eye" ? (
            options?.map((option, index) => {
              const numOfOrder = isShowOrder
                ? `No. ${
                    !isEmpty(documentSubCategorySelected)
                      ? Number(documentSubCategorySelected?.displayOrder) + 1
                      : index + 1
                  } : `
                : "";

              const label = `${numOfOrder}${option?.name || "-"}`;

              return (
                <Flex
                  key={option.value}
                  pl="15px"
                  cursor="pointer"
                  pb="5px"
                  pt="5px"
                  borderBottom="1px solid var(--primary-border-color)"
                  _hover={{
                    background: "#F0F9FF",
                  }}
                  _last={{ borderBottom: "none" }}
                  maxWidth="100%"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  onClick={handleChangeListOfVisibleDocuments(option?.value)}
                >
                  <Img
                    src={
                      value?.includes(option?.value)
                        ? "/img/eye.svg"
                        : "/img/eye-off.svg"
                    }
                    height="20px"
                    mr="10px"
                  />
                  <PCTooltip label={label}>
                    <Text
                      fontSize="14px"
                      maxWidth="100%"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {label}
                    </Text>
                  </PCTooltip>
                </Flex>
              );
            })
          ) : (
            <CheckboxGroup
              value={value || []}
              onChange={(value) => {
                if (isDisabled || isLoading) return;
                onChange?.(value as string[]);
              }}
            >
              {options.map((d) => (
                <Checkbox
                  key={d.value}
                  value={d.value}
                  display={"flex"}
                  px="1rem"
                  mt="0px !important"
                  p="0rem 1.5rem"
                  borderBottom="1px solid var(--primary-border-color)"
                  _hover={{
                    background: "#F0F9FF",
                  }}
                  _last={{ borderBottom: "none" }}
                  sx={{
                    "& > .chakra-checkbox__label": {
                      maxWidth: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    },
                  }}
                >
                  {d.name || "-"}
                </Checkbox>
              ))}
            </CheckboxGroup>
          )}
        </PopoverContent>
      </Box>
    </Popover>
  );
};

export const DropdownTag = React.memo(
  DropdownTagElement,
  (prevProps: DropdownTagsInputProps, nextProps: DropdownTagsInputProps) => {
    if (
      JSON.stringify(prevProps.value) !== JSON.stringify(nextProps.value) ||
      prevProps.isShowOrder !== nextProps.isShowOrder ||
      prevProps.options !== nextProps.options ||
      prevProps.onChange !== nextProps.onChange ||
      prevProps.onCloseModal !== nextProps.onCloseModal ||
      prevProps.onTagRemove !== nextProps.onTagRemove ||
      prevProps.onMouseLeave !== nextProps.onMouseLeave
    ) {
      return false;
    }

    return true;
  }
);
