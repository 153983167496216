import { Button, Flex } from "@chakra-ui/react";
import IconZoomIn from "components/icon/IconZoomIn";
import IconZoomOut from "components/icon/IconZoomOut";
import { isArray } from "lodash-es";

interface Props {
  selected?: string | string[];
  isDisabled?: boolean;
  zoom: number;
  onSelect?(e: React.MouseEvent<HTMLButtonElement>): void;
  onZoom(zoom: number): void;
  onBack?(e: React.MouseEvent<HTMLButtonElement>): void;
}

const MAX_ZOOM = 6;
const MIN_ZOOM = 2;

const Footer: React.FC<Props> = (props: Props) => {
  const { zoom, selected, isDisabled, onZoom, onSelect } = props;

  return (
    <Flex height="5.6rem" w="100%" px="1rem" py="1rem" gap="2rem">
      <Flex alignItems="center" w="100%">
        <Flex gap="1rem" ml="1rem">
          <IconZoomOut
            cursor="pointer"
            onClick={() => zoom < MAX_ZOOM && onZoom(zoom + 1)}
          />
          <IconZoomIn
            cursor="pointer"
            onClick={() => zoom > MIN_ZOOM && onZoom(zoom - 1)}
          />
        </Flex>
        <Button
          ml="auto"
          variant="filled"
          px="2rem"
          w="9.6rem"
          height="4rem"
          fontWeight={"600"}
          fontSize={"1.4rem"}
          borderRadius="4px"
          isDisabled={
            isDisabled || (isArray(selected) ? !selected?.length : !selected)
          }
          onClick={onSelect}
        >
          変更
        </Button>
      </Flex>
    </Flex>
  );
};

export default Footer;
