import { debounce } from "lodash-es";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNextPreviewPage } from "redux/documentSlice";
import { RootState } from "redux/store";

const HEADER_HEIGHT = 130;

interface iProps {
  totalPage: number;
  scrollToPage(pageIndex: number): void;
  taskSheetsRef: React.MutableRefObject<(HTMLDivElement | null)[]>;
  taskSheetContainerRef: React.MutableRefObject<HTMLDivElement>;
  isScroll?: boolean;
}

const useScrollTaskSheetPage = ({
  totalPage,
  scrollToPage,
  taskSheetsRef,
  taskSheetContainerRef,
  isScroll = true,
}: iProps) => {
  const dispatch = useDispatch();
  const { nextPreviewPage } = useSelector((state: RootState) => state.document);

  const taskSheetContainer = useMemo(
    () => (isScroll ? taskSheetContainerRef.current : null),
    [taskSheetContainerRef.current, isScroll]
  );

  const handleScrollTaskSheetContainer = useCallback(
    (e: any) => {
      const top = taskSheetContainer!.getBoundingClientRect().top;
      for (let k = 0; k < taskSheetsRef.current.length; k++) {
        const element = taskSheetsRef?.current[k];
        if (element) {
          const elementRect = taskSheetsRef.current[k]?.getBoundingClientRect();
          const relativePos = {
            top: top - elementRect?.top! || 0,
          };
          const isElementInViewport =
            relativePos.top + HEADER_HEIGHT > 0 &&
            Math.abs(relativePos.top) + HEADER_HEIGHT < element.clientHeight;
          if (isElementInViewport) {
            dispatch(setNextPreviewPage(k));
            break;
          }
        }
      }
    },
    [taskSheetsRef, taskSheetContainer, dispatch]
  );

  useEffect(() => {
    if (taskSheetContainer) {
      const delay = debounce(handleScrollTaskSheetContainer, 1000);
      taskSheetContainer?.addEventListener("scroll", delay);

      return () => {
        taskSheetContainer?.removeEventListener("scroll", delay);
      };
    }
  }, [taskSheetContainer, handleScrollTaskSheetContainer]);

  const onPrevPage = useCallback(() => {
    const page = nextPreviewPage - 1;
    dispatch(setNextPreviewPage(page));
    scrollToPage(page);
  }, [dispatch, nextPreviewPage, scrollToPage]);

  const onNextPage = useCallback(() => {
    const page = nextPreviewPage + 1;
    dispatch(setNextPreviewPage(nextPreviewPage + 1));
    scrollToPage(page);
  }, [dispatch, nextPreviewPage, scrollToPage]);

  const onBlurPage = useCallback(() => {
    const page =
      !nextPreviewPage || nextPreviewPage < 0
        ? 0
        : Math.min(nextPreviewPage, totalPage - 1);

    const index = !nextPreviewPage || nextPreviewPage < 0 ? 0 : page;
    scrollToPage(index);
    dispatch(setNextPreviewPage(index));
  }, [dispatch, nextPreviewPage, scrollToPage, totalPage]);

  const onChangePage = useCallback(
    (valueAsString: string, valueAsNumber: number) => {
      dispatch(setNextPreviewPage(valueAsNumber - 1));
    },
    [dispatch]
  );

  return {
    currentPage: nextPreviewPage,
    onBlurPage,
    onPrevPage,
    onNextPage,
    onChangePage,
  };
};

export default useScrollTaskSheetPage;
