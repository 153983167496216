import { SystemModeType } from "constants/enum";
import { NormalDisplayItem } from ".";
import {
  getCombinePinStatusColor,
  getStatusColor,
  onLabelClick,
} from "./utils";
import { FONT_SIZE_DISPLAY_ORDER } from "utils/forge";

const MAX_ITEM_SAME_POSITIONS = 99;

const plusIconSvg =
  '<svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8.875 11.125H1.375C1.05625 11.125 0.789125 11.0171 0.573625 10.8014C0.357875 10.5856 0.25 10.3184 0.25 9.99963C0.25 9.68062 0.357875 9.4135 0.573625 9.19825C0.789125 8.98275 1.05625 8.875 1.375 8.875H8.875V1.375C8.875 1.05625 8.98288 0.789125 9.19863 0.573625C9.41438 0.357875 9.68163 0.25 10.0004 0.25C10.3194 0.25 10.5865 0.357875 10.8018 0.573625C11.0173 0.789125 11.125 1.05625 11.125 1.375V8.875H18.625C18.9438 8.875 19.2109 8.98288 19.4264 9.19863C19.6421 9.41438 19.75 9.68163 19.75 10.0004C19.75 10.3194 19.6421 10.5865 19.4264 10.8018C19.2109 11.0173 18.9438 11.125 18.625 11.125H11.125V18.625C11.125 18.9438 11.0171 19.2109 10.8014 19.4264C10.5856 19.6421 10.3184 19.75 9.99963 19.75C9.68062 19.75 9.4135 19.6421 9.19825 19.4264C8.98275 19.2109 8.875 18.9438 8.875 18.625V11.125Z" fill="white"/></svg>';

export const documentLabel = (
  item: NormalDisplayItem,
  options?: { isSelected?: boolean }
) => {
  const fontWeight = "500";
  const labelName = item.isAddPin
    ? plusIconSvg
    : item.title ?? `${Number(item.displayOrder) + 1}`;
  const fontSizeLabel =
    FONT_SIZE_DISPLAY_ORDER[
      labelName.length as keyof typeof FONT_SIZE_DISPLAY_ORDER
    ];

  let documentStatusColor = getStatusColor(
    item.status,
    SystemModeType.Document
  );
  let numberPinsBgColor = "";
  let numberPins = "";

  const itemsSamePosition = item?.itemSamePositions || [];
  const itemSamePositionLength = itemsSamePosition.length;

  if (item.isCombine && itemSamePositionLength) {
    documentStatusColor = getCombinePinStatusColor([
      item.status,
      ...itemsSamePosition.map((i) => i.status),
    ]);
    numberPinsBgColor = documentStatusColor;
  } else if (!item.isCombine && itemSamePositionLength) {
    numberPinsBgColor = getCombinePinStatusColor(
      itemsSamePosition.map((i) => i.status)
    );
  }

  if (itemSamePositionLength) {
    const itemCount =
      itemSamePositionLength > MAX_ITEM_SAME_POSITIONS
        ? `${MAX_ITEM_SAME_POSITIONS}+`
        : itemSamePositionLength;
    numberPins = `<span class='number-pins' style="font-size: ${
      typeof itemCount === "string" ? "1rem" : "1.1rem"
    }">${itemCount}</span>`;
  }

  const $label = $(`
    <label class="document-label markup update ${
      options?.isSelected ? "selected-label" : ""
    }" data-id="${item.id}" ${item.isCombine ? "data-combine='true'" : ""}>
        <div class="marker-pin">
          <div class='pin-on-map' style="--bg-number-pins-color: ${numberPinsBgColor}; --bg-color: ${documentStatusColor}">
            <div class='inner-pin-on-map'>
              <span class="marker-label  ${
                item.unVisible && "invisible"
              }" style="font-weight: ${fontWeight}; font-size: ${fontSizeLabel};color: white;}">${labelName}</span>
              ${numberPins}
            </div>
          </div>
        </div>
    </label>`);

  $label.on("click", () => {
    onLabelClick(item, SystemModeType.Document);
  });

  return $label;
};
