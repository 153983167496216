import { GenerateStatus } from ".";

import { message } from "components/base";

import { CURRENT_LEVEL_KEY, LEVEL_ALL } from "constants/forge";

import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setIsLoadingDocument } from "redux/documentSlice";
import {
  setIsGeneratingAllFamilyInstance,
  setIsLoadedExternalId,
  setLevelSelected,
} from "redux/forgeViewerSlice";
import { RootState } from "redux/store";

import { ___viewer3d } from "utils/forge/forge3d";
import { getInstanceTableName } from "utils/forge/s3Data";
import { getLocalStorage, setLocalStorage } from "utils/storage";
import GenAllFamilyInstance from "services/genAllFamilyInstance";
import { FORGE_DATA_FOLDER_PATH, FORGE_DATA_INSTANCE } from "constants/s3";
import { inspectionInstanceApi } from "apiClient/v2";
import { DataGenerate } from "apiClient/v2/bims/bimFileApi";
import { getNetworkStatus } from "utils/indexedDb";

interface Props {
  generatingStatusRef: React.MutableRefObject<GenerateStatus>;
  isSettingFloor?: boolean;
  dataGenerated?: DataGenerate;
  setTimeCheckGenerate: (val: number) => void;
}

export default function useGenerateAllFamilyInstance(props: Props) {
  const {
    generatingStatusRef,
    isSettingFloor,
    dataGenerated,
    setTimeCheckGenerate,
  } = props;

  const {
    isGeneratingSpaces,
    isGeneratingFamilyInstances,
    isLoadedViewerModelData,
    isGeneratingAllFamilyInstance,
    isLoadedViewer,
    levels,
    levelSelected,
    isLoadedLevels,
    isInitialized,
  } = useSelector((state: RootState) => state.forgeViewer);

  const dispatch = useDispatch();
  const { bimFileId, version } = useParams();
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );

  useEffect(() => {
    if (
      isSettingFloor ||
      !bimFileId ||
      !dataGenerated ||
      !version ||
      levelSelected?.guid === undefined ||
      !isInitialized ||
      !dataProjectDetail?.id ||
      generatingStatusRef.current.allInstance ||
      isGeneratingAllFamilyInstance
    ) {
      return;
    }
    (async () => {
      const isOnline = await getNetworkStatus();
      // because mapping category and all instance not necessary when offline mode => we can ignore generate if offline
      if (!isOnline) {
        return;
      }
      if (generatingStatusRef.current.allInstance) {
        return;
      }
      generatingStatusRef.current.allInstance = false;
      // load family instances data
      dispatch(setIsLoadingDocument(true));
      const hasData =
        dataGenerated?.isGeneratedFamilyInstance &&
        dataGenerated?.isGeneratedCategory;
      if (hasData) {
        dispatch(setIsGeneratingAllFamilyInstance(false));
        generatingStatusRef.current.allInstance = true;
        setTimeCheckGenerate(Date.now());
      } else {
        dispatch(setIsGeneratingAllFamilyInstance(true));
        if (levelSelected.guid) {
          const currentLevel = getLocalStorage(CURRENT_LEVEL_KEY) || {};
          currentLevel[String(bimFileId)] = "";
          dispatch(setIsLoadedExternalId(false));
          setLocalStorage(CURRENT_LEVEL_KEY, currentLevel);
          dispatch(setLevelSelected(LEVEL_ALL));
          currentLevel[String(bimFileId)] = "";
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bimFileId,
    version,
    dataGenerated,
    isSettingFloor,
    isInitialized,
    dataProjectDetail?.id,
  ]);

  useEffect(() => {
    if (isGeneratingSpaces || isGeneratingFamilyInstances) {
      return;
    }
    if (
      isGeneratingAllFamilyInstance &&
      bimFileId &&
      version &&
      isLoadedViewer &&
      isLoadedViewerModelData &&
      !!___viewer3d &&
      isLoadedLevels
    ) {
      generateAllFamilyInstanceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bimFileId,
    isGeneratingAllFamilyInstance,
    isGeneratingSpaces,
    isGeneratingFamilyInstances,
    isLoadedViewerModelData,
    isLoadedLevels,
    isLoadedViewer,
    version,
  ]);

  const generateAllFamilyInstanceData = useCallback(async () => {
    try {
      const genAllInstance = new GenAllFamilyInstance(
        bimFileId!,
        String(version)
      );
      // The data here is relatively large so we should separate the function to avoid memory overload while generating data.
      const infoGenerate = await genAllInstance.getInfoForGenAllInstance();
      if (!infoGenerate) {
        return message.error(["ファイルアップロードに失敗しました。"]);
      }
      console.time("Upload all family instance");
      // execute upload files mapCategory
      const encodeBimFile = encodeURIComponent(bimFileId!);
      const categoryFileName = `f-categories-props-${encodeBimFile}-v${version}.json`;
      // here data
      await genAllInstance.generateMapCategory(
        infoGenerate,
        categoryFileName,
        FORGE_DATA_FOLDER_PATH,
        [/^.*(FL|DWG)$/, /^<.*>$/]
      );
      const subPath = getInstanceTableName({
        bimFileId: bimFileId!,
        version: version!,
      });
      const filePath = `${FORGE_DATA_INSTANCE}/${subPath}`;
      const fileName = "instance.json.gz";
      await genAllInstance.generateAllFamilyInstance(
        infoGenerate,
        fileName,
        filePath
      );

      console.timeEnd("Upload all family instance");
      await inspectionInstanceApi.createInstanceTable(
        encodeURIComponent(`${bimFileId}?version=${version}`)
      );
      dispatch(setIsGeneratingAllFamilyInstance(false));
      generatingStatusRef.current.allInstance = true;
    } catch (err) {
      message.error(["ファイルアップロードに失敗しました。"]);

      return;
    }
  }, [dispatch, bimFileId, version, levels, generatingStatusRef]);
}
