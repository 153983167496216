import { Attribute, Node } from "@tiptap/core";
import { DEFAULT_DOCUMENT_PAGE_SIZE } from "constants/document";
import { NodeType } from "../type";

export interface PageContainerNodeProps {
  width: number;
  height: number;
}

export const PageContainerNode = Node.create({
  name: NodeType.PAGE_CONTAINER,
  group: "block",
  content: `(${NodeType.FRAME_ORIGIN} | ${NodeType.PIN_LAYOUT} | ${NodeType.PAGE_COMPONENT_FRAME})*`,
  selectable: false,
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  isolating: true,

  addAttributes() {
    const attrs: { [key in keyof PageContainerNodeProps]: Attribute } = {
      width: { default: DEFAULT_DOCUMENT_PAGE_SIZE.width },
      height: { default: DEFAULT_DOCUMENT_PAGE_SIZE.height },
    };

    return attrs;
  },

  parseHTML() {
    return [
      {
        tag: "div",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { height, width } = HTMLAttributes as PageContainerNodeProps;

    const style =
      `position: relative; z-index:9999; width: ${width}px; height: ${height}px; ` +
      "background-color: white; box-shadow: 0px 2px 3px 0px #0000004D, 0px 6px 10px 4px #00000026, 0px 0px 5px 0px #0000000F";

    return ["div", { style, class: "page-container-node" }, 0];
  },
});
