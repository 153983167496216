/* eslint-disable no-loop-func */
import { SetTaskLogTypeComment } from "constants/task";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { TaskComment, TaskLogDTO } from "interfaces/models/task";
import { User } from "interfaces/models/user";
import { isEqual } from "lodash-es";
import { Fragment, memo, useMemo } from "react";
import RecordComment from "./RecordComment";
import RecordCommentCombine from "./RecordCommentCombine";
import RecordCommentDefault from "./RecordCommentDefault";

interface Props {
  comments: TaskComment[];
  listAssignedUserById: { [key: string]: UserDTO | null };
  listAllUserById: { [key: string]: UserDTO | null };
  loadingImage: boolean;
  status: any;
  isOnline: boolean;
  taskCreatedBy: string | undefined;
  taskCreatedAt: string | undefined;
  mapTaskType: Map<string, string>;
  mapPartnerCompany: { [key: string]: string };
  currentUser: User | null;
  onUpdateImageComment: (
    e: any,
    comment: TaskComment,
    srcImage: string
  ) => Promise<void>;
  onDeleteImage: (comment: TaskComment, srcImage: string) => Promise<void>;
  onPreviewImage: (src: string) => void;
  onRevertDataByLog: (log: TaskLogDTO) => void;
}

const TIME_SPLIT_GROUP = 1_000 * 10 * 60; // milliseconds;

const RecordCommentList = ({
  comments,
  listAssignedUserById,
  listAllUserById,
  loadingImage,
  status,
  isOnline,
  taskCreatedBy,
  mapTaskType,
  mapPartnerCompany,
  taskCreatedAt,
  currentUser,
  onUpdateImageComment,
  onDeleteImage,
  onPreviewImage,
  onRevertDataByLog,
}: Props) => {
  const isTypeComment = (type: number | undefined) => {
    return SetTaskLogTypeComment.has(type || -1);
  };

  const listComments = useMemo(() => {
    const listCommentCombine: TaskComment[][] = [];
    let childArIndex = -1;

    comments.forEach((comment) => {
      const isCommentCombine = !!comment?.value?.isCombine;
      const isChatComment = isTypeComment(comment.type);
      const previousGroup = listCommentCombine[childArIndex];
      const previousComment = previousGroup ? previousGroup[0] : null;
      const isPrevCommentCombine = !!previousComment?.value?.isCombine;
      const isValidTimeRange =
        previousComment?.createdAt &&
        comment?.createdAt &&
        new Date(previousComment.createdAt).getTime() -
          new Date(comment.createdAt).getTime() >
          TIME_SPLIT_GROUP;

      const shouldStartNewGroup =
        isValidTimeRange ||
        isCommentCombine ||
        isPrevCommentCombine ||
        isChatComment ||
        !previousComment ||
        comment.createdBy !== previousComment.createdBy ||
        isTypeComment(previousComment.type);

      if (shouldStartNewGroup) {
        listCommentCombine.push([]);
        childArIndex++;
      }

      const currentGroup = listCommentCombine[childArIndex];

      const isCommentAlreadyAdded = listCommentCombine.some((group) =>
        group.some((item) => item.id === comment.id)
      );

      if (!isCommentAlreadyAdded) {
        currentGroup.push(comment);
      }
    });

    return listCommentCombine;
  }, [comments]);

  return (
    <>
      {(listComments || []).map((commentsHead: TaskComment[], index) => {
        const comment = commentsHead.at?.(0) || ({} as TaskComment);
        const key = commentsHead.at?.(-1)?.id ?? index;

        if (!commentsHead.length) {
          return <Fragment key={key}></Fragment>;
        }

        return commentsHead.length === 1 ? (
          <RecordComment
            key={key}
            comment={comment}
            isOnline={isOnline}
            status={status}
            loadingImage={loadingImage}
            listAssignedUserById={listAssignedUserById}
            mapTaskType={mapTaskType}
            mapPartnerCompany={mapPartnerCompany}
            userCreatedLog={listAllUserById?.[comment.createdBy || ""]}
            currentUser={currentUser}
            onUpdateImageComment={onUpdateImageComment}
            onDeleteImage={onDeleteImage}
            onPreviewImage={onPreviewImage}
            onRevertDataByLog={onRevertDataByLog}
          />
        ) : (
          <RecordCommentCombine
            key={key}
            comments={commentsHead}
            avatar={listAllUserById[comment?.createdBy || ""]?.avatarBase64}
            listAssignedUserById={listAssignedUserById}
            listAllUserById={listAllUserById}
            mapTaskType={mapTaskType}
            mapPartnerCompany={mapPartnerCompany}
            currentUser={currentUser}
            onRevertDataByLog={onRevertDataByLog}
          />
        );
      })}

      <RecordCommentDefault
        userCreatedTask={listAllUserById?.[taskCreatedBy || ""]}
        taskCreatedAt={taskCreatedAt}
      />
    </>
  );
};

export default memo(
  RecordCommentList,
  (prevProps: any, nextProps: any): boolean => {
    return isEqual(prevProps, nextProps);
  }
);
