import {
  API_GET_CACHE,
  API_HOST_PREFIX,
  DATA_STORE,
  Operation,
  PATH_API,
  StoreName,
  UpdateToOnlineStatus,
} from "constants/serviceWorker";
import { ApiResponse } from "interfaces/models/api";
import {
  CreatePinList,
  GetPinReq,
  Pin,
  PinContent,
  PinDetail,
  UpdatePinGroup,
  UpdatePinGroupAction,
} from "interfaces/models/pin";
import { iCachedItem } from "interfaces/models/serviceWorker";
import { isUndefined } from "lodash-es";
import { arrayToObject } from "utils/object";
import RequestServiceWorker from "../RequestServiceWorker";

enum KeyPush {
  DETAIL = "detail",
  LIST_BY_TYPE = "list-by-type",
  LIST_BY_GROUP = "list-by-group",
}

const NUMBER_REQUEST_GET_LIST = 2; //get pin by inspection types, get pin by group
const NUMBER_REQUEST_GET_DETAIL = 1; // get detail
const TOTAL_NUMBER_REQUEST_GET =
  NUMBER_REQUEST_GET_LIST + NUMBER_REQUEST_GET_DETAIL;

/**
 * Flow sync data:
 * 1. Check is sync data by key pushed
 * 2. Merge data
 * 3. Update Key pushed
 * 4. Delete cached data if status cached success and number key pushed >= number request relative
 */

class RequestGetInspectionPin extends RequestServiceWorker {
  canDeleteCache(
    cachedItem: iCachedItem,
    numberRequestRelative = TOTAL_NUMBER_REQUEST_GET
  ) {
    // numberRequestRelative is the number of requests that need to sync data
    // If it is exceeded, the cache will be cleared.
    const keyPushes = Object.keys(cachedItem.isPushed || {});

    return (
      cachedItem.status === UpdateToOnlineStatus.Success &&
      keyPushes.length >= numberRequestRelative
    );
  }

  async updateKeyPushed(args: { key: string; cachedItem: iCachedItem }) {
    const { key, cachedItem } = args;
    const indexedDb = this.indexedDb!;
    cachedItem.isPushed = { ...cachedItem.isPushed, [key]: true };
    await indexedDb?.put(cachedItem.id, cachedItem);

    return cachedItem;
  }

  async getCachedItemByPins() {
    const params: GetPinReq = this.params as any;
    const indexedDb = this.indexedDb!;
    const [cachedContentItems, _cachedItems, cachedGroupItems] =
      await Promise.all([
        indexedDb?.getAll(DATA_STORE, StoreName.INSPECTION_PIN_CONTENT),
        indexedDb?.getAll(DATA_STORE, StoreName.INSPECTION_PIN),
        indexedDb?.getAll(DATA_STORE, StoreName.INSPECTION_PIN_GROUP),
      ]);
    const cachedCreateItems: iCachedItem<CreatePinList>[] = [];
    const cachedItems: iCachedItem<any>[] = [];
    _cachedItems.forEach((item) => {
      if (item.operation === Operation.Post) {
        const _item = item as iCachedItem<CreatePinList>;
        // We must filter correct request for prevent key pushed to cached data not correct
        if (_item.data.inspectionTypeId === params?.inspectionTypeId) {
          cachedCreateItems.push(item);
        } else if (_item.data.pinGroupId === params?.inspectionGroupId) {
          cachedCreateItems.push(item);
        }
      } else {
        cachedItems.push(item);
      }
    });

    return {
      cachedContentItems,
      cachedItems,
      cachedGroupItems,
      cachedCreateItems,
    };
  }

  /**
   *
   *   --- Flow sync data for getPinList  ---
   *  1. when add new pin
   *  - add pin into pinList
   *  2. when delete , update pin
   *  - remove pin in pinList and pin added, pinContent in IndexedDb
   *  - when edit in right sidebar
   *  3 when add, remove, sort pin group
   *  - update pinGroups of pin in pinList
   */
  async getDataResponseInspectionPins(args: { apiResponse: ApiResponse }) {
    const { apiResponse } = args;
    let data: Pin[] = apiResponse.data;
    const deleteIdSet: Set<string> = new Set();
    const params: GetPinReq = this.params as any;
    const indexedDb = this.indexedDb!;
    const isQueryByInspectionType = params?.inspectionTypeId;
    const keyPush = isQueryByInspectionType
      ? KeyPush.LIST_BY_TYPE
      : KeyPush.LIST_BY_GROUP;

    const {
      cachedContentItems,
      cachedItems,
      cachedGroupItems,
      cachedCreateItems,
    } = await this.getCachedItemByPins();

    const cachedDeleteItems: iCachedItem[] = [];
    cachedItems.forEach((item) => {
      if (item.operation === Operation.Delete) {
        cachedDeleteItems.push(item);
      }
    });
    const isLastPage =
      !apiResponse?.pagination && params?.["paging"] === "cursor";
    const deleteItemIdsSet = new Set(
      cachedDeleteItems.map((item) => item.data) as string[]
    );

    const handleOnKeyPushed = (
      cachedItem: iCachedItem,
      numberRequestRelative?: number
    ) => {
      if (this.canDeleteCache(cachedItem, numberRequestRelative)) {
        deleteIdSet.add(cachedItem.id);
      }
    };

    const handleUpdateKeyPushed = async (
      keyPush: string,
      cachedItem: iCachedItem,
      numberRequestRelative?: number
    ) => {
      await this.updateKeyPushed({
        key: keyPush,
        cachedItem,
      });
      handleOnKeyPushed(cachedItem, numberRequestRelative);
    };
    for await (const _cachedItem of cachedCreateItems) {
      const cachedItem = _cachedItem;
      const isPushed = cachedItem?.isPushed[keyPush];
      const pins = cachedItem.data.pins as Pin[];
      const validPins = pins.filter((p) => !deleteItemIdsSet.has(p.id));
      const numberRequestRelative =
        validPins.length * NUMBER_REQUEST_GET_DETAIL + NUMBER_REQUEST_GET_LIST;
      if (isPushed) {
        handleOnKeyPushed(cachedItem, numberRequestRelative);
        continue;
      }
      let isSyncData = false;
      for (const pin of pins) {
        const editIndex = data.findIndex((item) => item.id === pin.id);
        const isEditData = editIndex !== -1;
        const isAddNewData = !isEditData && isLastPage && !this.swOnlineStatus;
        if (isEditData) {
          data[editIndex] = this.overrideCachedData({
            cachedItem: pin as any,
            currentItem: data[editIndex] as any,
            status: cachedItem.status,
          });
        } else if (isAddNewData) {
          data.push(pin);
        }
        isSyncData = editIndex !== -1 || isAddNewData;
      }
      if (isLastPage && cachedItem.status === UpdateToOnlineStatus.Success) {
        isSyncData = true;
      }
      if (isSyncData) {
        await handleUpdateKeyPushed(keyPush, cachedItem, numberRequestRelative);
      }
    }

    const mapData = arrayToObject(data, "id");
    for await (const cachedItem of cachedItems) {
      const isPushed = cachedItem?.isPushed?.[keyPush];
      if (cachedItem.operation === Operation.Patch) {
        if (deleteItemIdsSet.has(cachedItem.data.id)) {
          deleteIdSet.add(cachedItem.id);
        } else {
          // case edit pin
          const { data } = cachedItem;
          if (!mapData?.[data?.id] || isPushed) {
            handleOnKeyPushed(cachedItem, TOTAL_NUMBER_REQUEST_GET);
            continue;
          }
          mapData[data.id] = this.overrideCachedData({
            cachedItem: cachedItem.data,
            currentItem: mapData[data.id],
            status: cachedItem.status,
          });
          await handleUpdateKeyPushed(keyPush, cachedItem);
        }
      }

      // case delete pin
      if (cachedItem.operation === Operation.Delete) {
        if (isPushed) {
          handleOnKeyPushed(cachedItem, NUMBER_REQUEST_GET_LIST);
          continue;
        }
        const existed = !!mapData?.[cachedItem.data];
        // remove pin
        if (existed || isLastPage) {
          await handleUpdateKeyPushed(
            keyPush,
            cachedItem,
            NUMBER_REQUEST_GET_LIST
          );
          if (!this.swOnlineStatus) {
            Array.from(deleteItemIdsSet).forEach((id) => {
              delete mapData?.[id];
            });
          }
        }
      }
    }

    // remove cache pin content if pinId is deleted
    cachedContentItems.forEach((item) => {
      if (deleteItemIdsSet.has(item.data.pinId)) {
        deleteIdSet.add(item.id);
      }
    });

    /**
     *  --- Important ----
     *
     * Because the pins get from the inspection group will not be complete,
     * Then we can't apply actions relative pin group when get call request get pin by inspection group
     * => So we need to update the cache inspection group right after calling the api relative pin group. updateCacheAfterUpdatePinGroup
     */
    // case edit pin group
    if (cachedGroupItems.length) {
      for await (const cachedItem of cachedGroupItems) {
        const isPushed = cachedItem?.isPushed[keyPush];
        const numberRequestRelative = NUMBER_REQUEST_GET_LIST;
        if (isPushed) {
          handleOnKeyPushed(cachedItem, NUMBER_REQUEST_GET_LIST);
          continue;
        }
        const { data, operation } = cachedItem as iCachedItem<UpdatePinGroup>;

        data?.pinIds?.forEach((pinId) => {
          if (!mapData?.[pinId] || operation !== Operation.Patch) {
            return;
          }
          let pinGroups = mapData[pinId].pinGroups;
          if (!pinGroups) {
            mapData[pinId].pinGroups = [];
            pinGroups = [];
          }
          switch (data.action) {
            case UpdatePinGroupAction.REMOVE: {
              mapData[pinId].pinGroups = pinGroups?.filter(
                (i) => data.pinGroupId !== i.pinGroupId
              );
              break;
            }
            case UpdatePinGroupAction.ADD: {
              const existed = mapData[pinId].pinGroups?.some(
                (p) => p.pinGroupId === data.pinGroupId
              );
              if (!existed) {
                mapData[pinId].pinGroups?.push({
                  order: Date.now(),
                  pinGroupId: data.pinGroupId,
                });
              }
              break;
            }
            case UpdatePinGroupAction.CHANGE_ORDER: {
              const indexGroup = pinGroups?.findIndex(
                (item) => item.pinGroupId === data.pinGroupId
              );
              if (indexGroup === -1 || isUndefined(data.order)) {
                break;
              }
              mapData[pinId].pinGroups![indexGroup].order = data.order;
              break;
            }
          }
        });
        await handleUpdateKeyPushed(keyPush, cachedItem, numberRequestRelative);
      }
    }
    data = Object.values(mapData);
    if (deleteIdSet.size) {
      await indexedDb?.deleteList(Array.from(deleteIdSet));
    }

    return data;
  }

  async getCachedItemByPin() {
    const indexedDb = this.indexedDb!;
    const [cachePinItems, cachedContentItems] = await Promise.all([
      indexedDb?.getAll(DATA_STORE, StoreName.INSPECTION_PIN),
      indexedDb?.getAll(DATA_STORE, StoreName.INSPECTION_PIN_CONTENT),
    ]);
    const cachedEditItems: iCachedItem<any>[] = [];
    const cachedCreateItems: iCachedItem<any>[] = [];
    cachePinItems?.forEach((item) => {
      if (item.operation === Operation.Patch) {
        cachedEditItems.push(item);
      } else if (item.operation === Operation.Post) {
        cachedCreateItems.push(item);
      }
    });

    return { cachedEditItems, cachedContentItems, cachedCreateItems };
  }

  /**
   *
   *   --- Flow sync data for getPinDetail  ---
   *  1. when edit pin content in right sidebar
   *  - update data from cached
   *  2. when add new pin
   *  - add new data from pin added
   *  3. when edit pinContent
   *  - update pinContents of pin
   *  4. when delete pin
   *  - pinContent will deleted when sync data for getPinList
   */
  async getDataResponseInspectionPin(args: {
    pinId: string;
    apiResponse: ApiResponse;
  }) {
    const { pinId, apiResponse } = args;
    const deleteIds: string[] = [];
    let data: PinDetail = Array.isArray(apiResponse.data)
      ? {}
      : apiResponse.data;
    const indexedDb = this.indexedDb!;

    if (!data?.id) {
      return;
    }

    if (!data?.pinContents) {
      data.pinContents = [];
    }
    const handleOnKeyPushed = (
      cachedItem: iCachedItem,
      numberRequestRelative?: number
    ) => {
      if (this.canDeleteCache(cachedItem, numberRequestRelative)) {
        deleteIds.push(cachedItem.id);
      }
    };
    const { cachedEditItems, cachedContentItems, cachedCreateItems } =
      await this.getCachedItemByPin();

    // case sync get pin detail from add new pin
    for await (const cachedItem of cachedCreateItems) {
      const keyPush = `${KeyPush.DETAIL}/${pinId}`;
      const pins = cachedItem.data.pins as Pin[];
      const numberRequestRelative =
        pins.length * NUMBER_REQUEST_GET_DETAIL + NUMBER_REQUEST_GET_LIST;
      if (cachedItem?.isPushed[keyPush]) {
        handleOnKeyPushed(cachedItem, numberRequestRelative);
        continue;
      }
      let isSyncData = false;

      // eslint-disable-next-line
      pins.forEach((pin) => {
        if (pin.id === pinId) {
          isSyncData = true;
          data = this.overrideCachedData({
            cachedItem: pin as any,
            currentItem: data as any,
            status: cachedItem.status,
          });

          return;
        }
      });

      if (isSyncData) {
        await this.updateKeyPushed({
          key: keyPush,
          cachedItem,
        });
        handleOnKeyPushed(cachedItem, numberRequestRelative);
      }
    }

    // case sync get pin detail from edit pin
    for await (const cachedItem of cachedEditItems) {
      if (cachedItem?.isPushed[KeyPush.DETAIL]) {
        handleOnKeyPushed(cachedItem, TOTAL_NUMBER_REQUEST_GET);
        continue;
      }
      if (cachedItem.data.id === data.id) {
        data = this.overrideCachedData({
          cachedItem: cachedItem.data,
          currentItem: data,
          status: cachedItem.status,
        });
        await this.updateKeyPushed({
          key: KeyPush.DETAIL,
          cachedItem,
        });
        handleOnKeyPushed(cachedItem);
      }
    }

    // case sync get pin contents of pin detail from edit pin
    cachedContentItems.forEach((cached) => {
      const item = cached.data as PinContent;
      const { itemResultId, pinId } = item;
      if (pinId !== data.id) return cached;
      const index = (data?.pinContents || [])?.findIndex(
        (pinContent: PinContent) => pinContent.itemResultId === itemResultId
      );
      if (index === -1) {
        data.pinContents!.push(item);
      } else {
        data.pinContents![index] = item;
      }
      if (cached.status === UpdateToOnlineStatus.Success) {
        deleteIds.push(cached.id);
      }
    });
    if (deleteIds.length) {
      await indexedDb?.deleteList(deleteIds);
    }

    return data;
  }

  async changeResponseData(args: {
    apiResponse: ApiResponse;
    response: Response | undefined;
  }): Promise<Response> {
    const isArray = !!this.params?.paging;
    const response = args.response;
    const apiResponse = structuredClone(args.apiResponse);
    if (isArray) {
      // Change response data for get list
      apiResponse.data = await this.getDataResponseInspectionPins({
        apiResponse,
      });
    } else {
      // Change response data for get detail
      const objectUrl = new URL(this.event.request.clone().url);
      const pinId = objectUrl.pathname.split("/").pop() || "";
      apiResponse.data = await this.getDataResponseInspectionPin({
        pinId,
        apiResponse,
      });
    }

    return await this.response({
      data: apiResponse,
      response,
    });
  }
}

// Handle update cache after update pin group
export const updateCacheAfterUpdatePinGroup = async (
  params: {
    pin?: Pin;
  } & UpdatePinGroup
) => {
  const { pinGroupId, action, pin } = params;
  const url = `/${API_HOST_PREFIX}/v1/${PATH_API.INSPECTION_PIN}`;
  const cache = await caches.open(API_GET_CACHE);
  const cacheKeys = await cache.keys();
  const requests = cacheKeys.filter(
    (req) =>
      req.url.includes(url) &&
      req.url.includes(`inspectionGroupId=${pinGroupId}`)
  );

  await Promise.all(
    requests.map(async (req) => {
      const res = await cache.match(req);
      if (!res) {
        return;
      }
      const pinsRes: ApiResponse<Pin[]> = await res.json();
      switch (action) {
        case UpdatePinGroupAction.ADD: {
          if (!pin || pinsRes.pagination) {
            break;
          }
          const newPin = structuredClone(pin);
          newPin.pinGroups = newPin?.pinGroups ?? [];
          const existed = newPin.pinGroups?.some(
            (p) => p.pinGroupId === pinGroupId
          );
          if (!existed) {
            newPin.pinGroups.push({ pinGroupId, order: Date.now() });
          }
          if (!pinsRes.data.some((itm) => itm.id === newPin.id)) {
            pinsRes.data.push(newPin);
          }
          break;
        }
      }
      const newResponse = new Response(JSON.stringify(pinsRes), {
        headers: res?.headers,
        status: res?.status || 202,
        statusText: res?.statusText,
      });
      await cache.put(req, newResponse.clone());
    })
  );
};

export default RequestGetInspectionPin;
