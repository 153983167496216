import { Flex, Text } from "@chakra-ui/react";
import Gallery from "components/containers/inspectionDetail/content/inspectionDocument/containers/gallery";
import { ItemData } from "components/containers/inspectionDetail/content/inspectionDocument/containers/gallery/Item";
import IconRevertData from "components/icon/IconRevertData";
import { useMemo } from "react";
import { DATE_TIME_SLASH_FORMAT, formatDate } from "utils/date";

export interface FileHistory {
  src: string;
  createdAt: number;
  userUpload: string;
}

interface SelectFromGalleryProps {
  isOpen: boolean;
  value?: string;
  history?: FileHistory[];
  onChange(history: FileHistory): void;
  onDeleteImage: (item: ItemData) => Promise<boolean | undefined>;
  onClose(): void;
}

const SelectFromGallery: React.FC<SelectFromGalleryProps> = ({
  isOpen,
  value,
  history,
  onDeleteImage,
  onChange,
  onClose,
}) => {
  const { items, mapItem } = useMemo(() => {
    const mapItem = new Map();
    const items = history?.map(({ src, createdAt, userUpload }) => {
      mapItem.set(src, { src, createdAt, userUpload });

      return {
        id: src,
        src,
        title: formatDate(createdAt, DATE_TIME_SLASH_FORMAT),
      };
    });

    return { items, mapItem };
  }, [history]);

  return (
    <Gallery
      isOpen={isOpen}
      selected={value}
      items={items}
      title={
        <Flex fill="white" flexDir="row" alignItems="center">
          <IconRevertData w="2.4rem" h="2.4rem" mr="0.5rem" />
          <Text fontWeight="700" fontSize="1.8rem">
            撮影履歴
          </Text>
        </Flex>
      }
      onSelect={(src: any) => {
        const item = mapItem.get(src);
        if (!item) return;
        onChange(item);
      }}
      onDeleteImage={onDeleteImage}
      onBack={onClose}
    />
  );
};

export default SelectFromGallery;
