import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { memo, useCallback } from "react";

export interface CommonModalProps {
  isOpen: boolean;
  title?: string | React.ReactNode;
  content?: React.ReactElement | string;
  size?: `${number}xl` | string;
  cancelLabel?: string;
  okeLabel?: string;
  okeButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  modalContentProps?: ModalContentProps;
  modalHeaderProps?: ModalHeaderProps;
  modalFooterProps?: ModalFooterProps;
  modalBodyProps?: ModalBodyProps;
  modalCloseButtonProps?: ButtonProps;
  hideOverLay?: boolean;
  hideCloseButton?: boolean;
  hideFooter?: boolean;
  hideCancelButton?: boolean;
  hideOkeButton?: boolean;
  closeOnOverlayClick?: boolean;
  onCancel?: () => void;
  onClose: () => void;
  onOke?: () => void;
}

const CommonModal = (props: CommonModalProps) => {
  const {
    isOpen,
    content,
    title,
    size = "2xl",
    cancelLabel = "キャンセル",
    okeLabel = "確認",
    okeButtonProps,
    cancelButtonProps,
    modalContentProps,
    modalHeaderProps,
    modalFooterProps,
    modalBodyProps,
    modalCloseButtonProps,
    hideOverLay = false,
    hideCloseButton = false,
    hideFooter = false,
    hideCancelButton = false,
    hideOkeButton = false,
    closeOnOverlayClick = false,
    onClose,
    onOke,
    onCancel,
    ...rest
  } = props;

  const handleClose = useCallback(() => {
    if (modalCloseButtonProps?.isDisabled) return;
    onClose?.();
  }, [onClose, modalCloseButtonProps?.isDisabled]);

  const handleCancel = useCallback(() => {
    if (cancelButtonProps?.isDisabled) return;
    if (onCancel) {
      return onCancel();
    }
    onClose?.();
  }, [onClose, onCancel, cancelButtonProps?.isDisabled]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      size={size}
      closeOnOverlayClick={closeOnOverlayClick}
      {...rest}
    >
      {hideOverLay || <ModalOverlay />}
      <ModalContent p="1.5rem 2rem" {...modalContentProps}>
        <ModalHeader
          color="#0284C7"
          fontWeight={700}
          fontSize="1.7rem"
          {...modalHeaderProps}
        >
          {title}
        </ModalHeader>

        {hideCloseButton || (
          <ModalCloseButton
            fontSize="12px"
            _focus={{
              border: "none",
            }}
            _focusVisible={{}}
            {...modalCloseButtonProps}
          />
        )}

        <ModalBody
          p="2rem 1.2rem"
          fontSize="1.4rem"
          fontWeight={400}
          color="#171717"
          textAlign="left"
          {...modalBodyProps}
        >
          {typeof content === "string" ? <Text>{content}</Text> : content}
        </ModalBody>

        {hideFooter || (
          <ModalFooter {...modalFooterProps}>
            {hideCancelButton || (
              <Button mr={3} onClick={handleCancel} {...cancelButtonProps}>
                <Text> {cancelLabel}</Text>
              </Button>
            )}
            {hideOkeButton || (
              <Button variant="filled" onClick={onOke} {...okeButtonProps}>
                <Text> {okeLabel}</Text>
              </Button>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default memo(CommonModal);
