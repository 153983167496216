import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { IconBase, message } from "components/base";
import FieldPreviewWrapper from "../../../components/FieldPreviewWrapper";
import { FieldPreviewProps } from "../../types";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import CameraModal from "./CameraModal";
import { FileModel } from "interfaces/models";
import DrawToolModalContainer from "./DrawToolModalContainer";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import ConfirmModal from "components/modal/ConfirmDeleteModal";
import { SOURCE } from "components/containers/InspectionFormat/Content/SettingPinLayoutFrame/context/pinLayoutFrameReducer";
import Spinner from "components/ui/Spinner";
import { isEqual, pick } from "lodash-es";
import { DATE_TIME_SLASH_FORMAT, formatDate } from "utils/date";
import SelectFromGallery, { FileHistory } from "./SelectFromGallery";
import { DroppableBox } from "components/containers/InspectionFormat/containers/LayoutFrameBuilder";
import CameraPlusIcon from "components/icon/CameraPlusIcon";
import ImageIcon from "components/icon/ImageIcon";
import Peripheral from "libs/peripheral/Peripheral";
import { logDev } from "utils/logs";
import { ItemData } from "components/containers/inspectionDetail/content/inspectionDocument/containers/gallery/Item";

const info = [
  "originSrc",
  "src",
  "userUpload",
  "uploadTime",
  "lastModifiedDateFile",
  "name",
];

const ImagePresigned = withPresignedUrl(Image);

export interface ImageValue extends FileModel {
  histories?: FileHistory[];
}

interface ImagePreviewerProps extends FieldPreviewProps<ImageValue> {}

enum ModalType {
  CAMERA = "CAMERA",
  CONFIRM_DELETE = "CONFIRM_DELETE",
  DRAW_IMAGE = "DRAW_IMAGE",
  GALLERY = "GALLERY",
}

const ImagePreviewer: React.FC<ImagePreviewerProps> = ({
  item,
  value,
  isLoading,
  isReadOnly,
  isDraggable,
  onChange,
  isDisabled,
}) => {
  const [modal, setModal] = useState<ModalType | null>(null);
  const [image, setImage] = useState<ImageValue | undefined>(value);
  const previousModeRef = useRef<ModalType | null>(null);
  const imageCaptured = useRef<File>();
  const inputFileRef = useRef<any>();
  const loading = isLoading;
  const handleCaptureCamera = useCallback(async (file?: File) => {
    imageCaptured.current = file;
    if (previousModeRef.current) {
      setModal(previousModeRef.current);
    } else {
      setModal(ModalType.DRAW_IMAGE);
    }
  }, []);
  const disabled = isLoading || isReadOnly || isDisabled;

  const onCamera = useCallback(async () => {
    if (!Peripheral.isCameraConnected()) {
      return setModal(ModalType.CAMERA);
    }

    try {
      const { file } = await Peripheral.camera();
      file && handleCaptureCamera(file);
    } catch (error) {
      logDev(error);
      /** Ignore */
    }
  }, [handleCaptureCamera]);

  const onSelectFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];

      if (!file) return;

      if (!file.type.startsWith("image/")) {
        message.warning(["画像を選択してください"]);

        return;
      }

      handleCaptureCamera(file);
      inputFileRef.current.value = "";
    },
    [handleCaptureCamera]
  );

  const captureGroup = useMemo(() => {
    if (!!image?.src) {
      return (
        <Box>
          <Box bg="#fff" p="0.75rem">
            <Stack
              flexDirection="row"
              width="100%"
              className="capture-group"
              position={"relative"}
            >
              <ImagePresigned
                width="100%"
                height={"100%"}
                src={image?.src}
                objectFit="contain"
                boxLoadingWidth={"100%"}
                boxLoadingHeight={"100%"}
              />
              {isLoading && (
                <Flex
                  position={"absolute"}
                  top={0}
                  left={0}
                  w="100%"
                  h="100%"
                  alignItems={"center"}
                  justifyContent={"center"}
                  bg="rgba(0,0,0,.2)"
                  backdropFilter={"blur(5px)"}
                >
                  <Spinner />
                </Flex>
              )}
            </Stack>
            <Text
              color="#808080"
              mt="0.75rem"
              display={"block"}
              textAlign={"right"}
              fontSize={"1rem"}
            >
              {formatDate(image.uploadTime, DATE_TIME_SLASH_FORMAT)}
            </Text>
          </Box>
          <Flex py="1rem" gap={"1rem"} w="100%">
            <Button
              gap="0.25rem"
              minW={"10rem"}
              borderColor={"#fff"}
              bg="transparent"
              isDisabled={disabled}
              fontWeight={600}
              onClick={onCamera}
            >
              <IconBase icon="/img/camera.svg" />
              再撮影
            </Button>
            <Button
              gap="0.25rem"
              minW={"10rem"}
              borderColor={"#fff"}
              bg="transparent"
              as="label"
              cursor={"pointer"}
              isDisabled={disabled}
              fontWeight={600}
            >
              <input
                type="file"
                style={{ display: "none" }}
                disabled={disabled}
                accept="image/*"
                onClick={(e) => {
                  if (disabled) return;
                  const target = e.target as HTMLInputElement;
                  target.value = "";
                }}
                onChange={(e) => handleCaptureCamera(e.target.files?.[0])}
              />
              <IconBase icon="/img/icon-add-photo.svg" />
              再選択
            </Button>
            <Button
              ml="auto"
              color="#808080"
              gap="0.25rem"
              variant={"text"}
              px="0"
              isDisabled={disabled}
              fontWeight={600}
              onClick={() => setModal(ModalType.GALLERY)}
            >
              <IconBase icon="/img/icon-revert-data.svg" />
              撮影履歴
            </Button>
          </Flex>
        </Box>
      );
    }

    return (
      <Stack
        flexDirection="row"
        width="100%"
        border="1px solid #c1c0c0"
        className="capture-group draggable-area"
        backgroundColor={"#fff"}
        borderRadius={"0.5rem"}
        divider={
          <Center width="2px">
            <Box
              width="1px"
              height="100%"
              maxHeight="80%"
              style={{
                backgroundImage: `linear-gradient(to bottom, #c1c0c0 50%, transparent 50%)`,
                backgroundSize: "5px 10px",
                backgroundRepeat: "repeat",
              }}
            />
          </Center>
        }
      >
        <Center
          gap="0.25rem"
          flexDirection="column"
          flexGrow={1}
          flexBasis="50%"
          as="button"
          cursor={"pointer"}
          width={"50%"}
          py="2rem"
          onClick={() => {
            if (disabled) return;
            onCamera();
          }}
        >
          <CameraPlusIcon width="3rem" height="3rem" />
          <Text textAlign="center" fontSize={"1rem"}>
            撮る
          </Text>
        </Center>
        <Center
          gap="0.25rem"
          width={"50%"}
          flexDirection="column"
          flexGrow={1}
          flex="1"
          flexBasis="50%"
          py="2rem"
          as="label"
          cursor={"pointer"}
        >
          <input
            type="file"
            style={{ display: "none" }}
            disabled={loading || isReadOnly || disabled}
            ref={inputFileRef}
            accept="image/*"
            onClick={(e) => {
              if (disabled) return;
              const target = e.target as HTMLInputElement;
              target.value = "";
            }}
            onChange={onSelectFile}
          />
          <IconBase
            icon="/img/icon-add-photo.svg"
            width="3rem"
            height="3rem"
            backgroundColor="#5F6368"
          />
          <Text textAlign="center" fontSize={"1rem"}>
            選択
          </Text>
        </Center>
      </Stack>
    );
  }, [
    image?.src,
    image?.uploadTime,
    loading,
    isReadOnly,
    disabled,
    onSelectFile,
    isLoading,
    onCamera,
    handleCaptureCamera,
  ]);

  const handleDeleteImage = () => {
    setImage(undefined);
    setModal(null);
    const histories = value?.histories ? [...value?.histories] : [];
    onChange?.({ histories });
  };

  const onDraw = useCallback(
    (file: FileModel) => {
      setImage(file);
      const histories = value?.histories ? [...value?.histories] : [];
      const data = structuredClone(pick(file, info));
      data.src &&
        histories.unshift({
          src: data.src,
          createdAt: Date.now(),
          userUpload: value?.userUpload!,
        });
      onChange?.({ ...data, histories });
    },
    [onChange, value]
  );

  const onSelectFromGallery = useCallback(
    (history: FileHistory) => {
      if (!value) return;
      const uploadTime = history?.createdAt
        ? new Date(history?.createdAt)
        : undefined;
      setImage((prev) => ({
        ...prev,
        src: history?.src,
        uploadTime,
      }));
      onChange?.({
        ...value,
        src: history?.src,
        uploadTime,
      });
      setModal(null);
    },
    [onChange, value]
  );

  const onDeleteImageHistory = useCallback(
    async (item: ItemData) => {
      const result = await onChange?.({
        ...value,
        histories: value?.histories?.filter(
          (history) => history.src !== item.src
        ),
      });
      if (result) {
        setImage((prev) => ({
          ...prev,
          histories: prev?.histories?.filter(
            (history) => history.src !== item.src
          ),
        }));
      }

      return result;
    },
    [value, onChange]
  );

  useEffect(() => {
    setImage(value ?? {});
  }, [value]);

  if (isDraggable) {
    return (
      <FieldPreviewWrapper title={item.name} data-type={item.dataType}>
        <DroppableBox
          flex={1}
          name={item.name}
          icon={<ImageIcon />}
          id={item.id}
          source={SOURCE.INSPECTION_FORM}
        >
          {captureGroup}
        </DroppableBox>
      </FieldPreviewWrapper>
    );
  }

  return (
    <FieldPreviewWrapper
      title={item.name}
      data-type={item.dataType}
      data-value={image?.src}
    >
      {captureGroup}
      {modal === ModalType.GALLERY && (
        <SelectFromGallery
          isOpen={true}
          value={image?.src}
          history={image?.histories}
          onDeleteImage={onDeleteImageHistory}
          onChange={onSelectFromGallery}
          onClose={() => setModal(null)}
        />
      )}
      <ConfirmModal
        title="削除の確認"
        content="削除してよろしいですか。"
        isDelete
        buttonConfirm="削除する"
        isOpen={modal === ModalType.CONFIRM_DELETE}
        onClose={() => setModal(null)}
        onProcessing={handleDeleteImage}
      />
      {modal === ModalType.CAMERA && (
        <CameraModal
          isOpen={true}
          onClose={() => setModal(null)}
          onCapture={handleCaptureCamera}
        />
      )}
      <DrawToolModalContainer
        isOpen={modal === ModalType.DRAW_IMAGE}
        image={image}
        setImage={onDraw}
        handleOpenCamera={() => {
          previousModeRef.current = ModalType.DRAW_IMAGE;
          setModal(ModalType.CAMERA);
        }}
        onClose={() => setModal(null)}
        imageCaptured={imageCaptured}
      />
    </FieldPreviewWrapper>
  );
};

export default memo(ImagePreviewer, (prev, next) => isEqual(prev, next));
