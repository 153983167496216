import { DEFAULT_BLACKBOARD_CONTENT_SIZE } from "constants/blackBoardTemplate";
import { DEFAULT_WIDTH_HEADER_TABLE } from "constants/document";
import { DEFAULT_GRID_SIZE } from "constants/documentTemplate";
import {
  Axis,
  CellProperty,
  CellSizeSetting,
  PaperDirectionType,
  TableDefaultStyle,
  TableSizeSetting,
  TemplateComponentType,
} from "constants/enum";
import { CellType, TemplateComponent } from "interfaces/models/component";
import { isEqual, cloneDeep } from "lodash-es";
import {
  checkHasOverlap,
  checkIsOutOfLimit,
  onDispatchComponents,
} from "models/document";
import { Direction } from "re-resizable/lib/resizer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Position, ResizableDelta, Rnd } from "react-rnd";
import {
  setComponents,
  setIsComponentDragging,
  setSelectedCell,
} from "redux/documentSlice";
import { RootState } from "redux/store";
import {
  getAllSubCellSizeAfterResize,
  getIndexCell,
  getIndexFromId,
  getOriginalSize,
  getParentCell,
  getTableSize,
  resizeSubTable,
  sortNearestComponentPosition,
  updateBorderHeaderTable,
  updateColDOM,
  updateDOM,
  updateRowDOM,
  updateSubTableDOM,
} from "utils/document";
import { getCellPositionByCellId } from "utils/tableCell";
import { iUseRnd } from "..";

const useRnd = (props: iUseRnd) => {
  const {
    page,
    isOnlyView,
    isComponentDragging,
    scale,
    components,
    component,
    isBlackboardTemplate = false,
    displayComponent,
    minSize,
    selectedCells,
  } = props;
  const [isResizable, setIsResizable] = useState(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isResizing, setIsResizing] = useState(false);

  const { pageSelected } = useSelector((state: RootState) => state.document);

  const dispatch = useDispatch();

  const lastDataRef = useRef<{
    position: { x: number; y: number };
    size: { width: number; height: number };
  }>({
    position: component.position,
    size: component.size,
  });
  const isCollisionLinkedTableRef = useRef({} as TemplateComponent);
  const allCellSize = useRef<{ width: number; height: number }[][]>([]);
  const overlapRef = useRef({
    overlapVertical: {} as TemplateComponent,
    overlapHorizontal: {} as TemplateComponent,
  });
  const componentRef = useRef<Rnd | null>();
  const isComponentDraggingRef = useRef(isComponentDragging);
  const componentsRef = useRef<TemplateComponent[]>([]);

  useEffect(() => {
    isComponentDraggingRef.current = isComponentDragging;
  }, [isComponentDragging]);

  useEffect(() => {
    lastDataRef.current.size = component.size;
    const listRow = component.detail?.rows;
    if (listRow) {
      allCellSize.current = listRow?.map((row) => {
        return row.cells!.map((cell) => ({
          width:
            Number(cell.colSpan) > 1
              ? getOriginalSize(listRow, cell).width
              : cell.width!,
          height:
            Number(cell.rowSpan) > 1
              ? getOriginalSize(listRow, cell).height
              : cell.height!,
        }));
      });
    }
  }, [component]);

  const getDistance = (
    c1: TemplateComponent,
    c2: TemplateComponent,
    axis: Axis.VERTICAL | Axis.HORIZONTAL
  ) => {
    if (axis === Axis.VERTICAL)
      return c1.position.y > c2.position.y
        ? c1.position.y - (c2.position.y + c2.size.height)
        : c2.position.y - (c1.position.y + c1.size.height);

    return c1.position.x > c2.position.x
      ? c1.position.x - (c2.position.x + c2.size.width)
      : c2.position.x - (c1.position.x + c1.size.width);
  };

  const getNearestDistance = useCallback(
    (
      target: TemplateComponent,
      newPosition: Position,
      axis: Axis.VERTICAL | Axis.HORIZONTAL
    ) => {
      newPosition = {
        x: Math.round(newPosition.x),
        y: Math.round(newPosition.y),
      };

      let list = [...components] as TemplateComponent[];

      const newComponent = {
        ...target,
        position: newPosition,
      };

      list = list.sort((c1, c2) =>
        sortNearestComponentPosition(component, c1, c2, newPosition, axis)
      );

      return list.find((component) => {
        if (
          component.page !== page ||
          component.componentId === target.componentId
        ) {
          return false;
        }

        const distanceY = getDistance(newComponent, component, axis);
        const distanceX = getDistance(newComponent, component, axis);

        if (axis === Axis.VERTICAL)
          return (
            distanceY >= 0 &&
            distanceY <= TableSizeSetting.MIN_DISTANCE &&
            ((component.position.x <= newPosition!.x &&
              newPosition!.x < component.position.x + component.size.width) ||
              (newPosition!.x <= component.position.x &&
                component.position.x < newPosition!.x + target.size.width))
          );

        return (
          distanceX >= 0 &&
          distanceX <= TableSizeSetting.MIN_DISTANCE &&
          ((component.position.y <= newPosition!.y &&
            newPosition!.y < component.position.y + component.size.height) ||
            (newPosition!.y <= component.position.y &&
              component.position.y < newPosition!.y + target.size.height))
        );
      });
    },
    [component, components, page]
  );

  const overlapComponent = useCallback(
    (
      target: TemplateComponent,
      axis?: Axis.VERTICAL | Axis.HORIZONTAL,
      newPosition?: Position,
      newSize?: { width: number; height: number }
    ) => {
      if (!newPosition) {
        newPosition = target.position;
      }
      if (!newSize) {
        newSize = target.size;
      }

      newPosition = {
        x: newPosition.x,
        y: newPosition.y,
      };

      newSize = {
        width: newSize.width,
        height: newSize.height,
      };

      let list = [...components];
      if (axis) {
        list = list.sort((c1, c2) =>
          sortNearestComponentPosition(
            target,
            c1,
            c2,
            newPosition as Position,
            axis
          )
        );
      }

      return list.find((component) => {
        let isHorizontal, isVertical;
        if (
          component.page !== page ||
          component.componentId === target.componentId
        ) {
          return false;
        }
        //Check position
        const isLeft =
          Math.round(component.position.x) >= Math.round(newPosition!.x);
        const isRight =
          Math.round(component.position.x) <= Math.round(newPosition!.x);
        const isTop =
          Math.round(component.position.y) >= Math.round(newPosition!.y);
        const isBottom =
          Math.round(component.position.y) <= Math.round(newPosition!.y);

        //Check position overlap
        const isOverLapRight =
          Math.round(component.position.x + component.size.width) >
          Math.round(newPosition!.x);
        const isOverlapLeft =
          Math.round(component.position.x) <
          Math.round(newPosition!.x + newSize!.width);
        const isOverlapTop =
          Math.round(component.position.y) <
          Math.round(newPosition!.y + newSize!.height);
        const isOverlapBottom =
          Math.round(component.position.y + component.size.height) >
          Math.round(newPosition!.y);

        //Check Last data valid
        const isLastXBigger =
          Math.round(component.position.x + component.size.width) <=
          Math.round(lastDataRef.current.position.x);
        const isLastXSmaller =
          Math.round(component.position.x) >=
          Math.round(
            lastDataRef.current.position.x + lastDataRef.current.size.width
          );
        const isLastYBigger =
          Math.round(component.position.y + component.size.height) <=
          Math.round(lastDataRef.current.position.y);
        const isLastYSmaller =
          Math.round(component.position.y) >=
          Math.round(
            lastDataRef.current.position.y + lastDataRef.current.size.height
          );

        // Check axis overlap
        const isHorizontalCollision =
          (isRight && isOverLapRight) || (isLeft && isOverlapLeft);
        const isVerticalCollision =
          (isBottom && isOverlapBottom) || (isTop && isOverlapTop);

        if (isComponentDragging) {
          const isOverlap = isHorizontalCollision && isVerticalCollision;

          isHorizontal = isOverlap && (isLastXBigger || isLastXSmaller);
          isVertical = isOverlap && (isLastYBigger || isLastYSmaller);
        } else {
          isHorizontal =
            isVerticalCollision &&
            ((isOverLapRight && isLastXBigger) ||
              (isOverlapLeft && isLastXSmaller));

          isVertical =
            isHorizontalCollision &&
            ((isOverlapBottom && isLastYBigger) ||
              (isOverlapTop && isLastYSmaller));
        }

        if (axis === Axis.HORIZONTAL) return isHorizontal;
        if (axis === Axis.VERTICAL) return isVertical;

        return isHorizontal || isVertical;
      });
    },
    [components, isComponentDragging, page]
  );

  const handleDrag = useCallback(
    (_, data) => {
      if (!isComponentDraggingRef.current) {
        isComponentDraggingRef.current = true;
        dispatch(setIsComponentDragging(true));
      }

      const newPosition = {
        x: data.x * scale.x,
        y: data.y * scale.y,
      };

      const isOverlap = overlapComponent(component, undefined, newPosition);

      const nearestComponent =
        components.length > 1
          ? getNearestDistance(component, newPosition, Axis.VERTICAL) ||
            getNearestDistance(component, newPosition, Axis.HORIZONTAL)
          : undefined;

      if (isOverlap) {
        let position = { ...lastDataRef.current.position };

        const overlapHorizontal = overlapComponent(component, Axis.HORIZONTAL, {
          x: newPosition.x,
          y: lastDataRef.current.position.y,
        });

        if (overlapHorizontal) {
          position = {
            ...position,
            x:
              newPosition.x + component.size.width / 2 >
              overlapHorizontal.position.x + overlapHorizontal.size.width / 2
                ? overlapHorizontal.position.x + overlapHorizontal.size.width
                : overlapHorizontal.position.x - component.size.width,
          };
        }

        const overlapVertical = overlapComponent(component, Axis.VERTICAL, {
          x: lastDataRef.current.position.x,
          y: newPosition.y,
        });

        if (overlapVertical) {
          position = {
            ...position,
            y:
              newPosition.y + component.size.height / 2 >
              overlapVertical.position.y + overlapVertical.size.height / 2
                ? overlapVertical.position.y + overlapVertical.size.height
                : overlapVertical.position.y - component.size.height,
          };
        }

        if (
          checkIsOutOfLimit({
            position,
            size: component.size,
            isBlackboardTemplate,
          }) ||
          overlapComponent(component, undefined, position)
        ) {
          position = lastDataRef.current.position;
        }

        lastDataRef.current.position = position;
      } else if (nearestComponent) {
        const position = getNearestDistance(
          component,
          newPosition,
          Axis.VERTICAL
        )
          ? {
              ...lastDataRef.current.position,
              x: newPosition.x,
              y:
                newPosition.y > nearestComponent.position.y
                  ? nearestComponent.position.y + nearestComponent.size.height
                  : nearestComponent.position.y - component.size.height,
            }
          : {
              ...lastDataRef.current.position,
              x:
                newPosition.x > nearestComponent.position.x
                  ? nearestComponent.position.x + nearestComponent.size.width
                  : nearestComponent.position.x - component.size.width,
              y: newPosition.y,
            };

        if (!overlapComponent(component, undefined, position))
          lastDataRef.current.position = position;
      } else {
        lastDataRef.current.position = newPosition;
      }
    },
    [
      component,
      components,
      dispatch,
      getNearestDistance,
      overlapComponent,
      scale,
      isBlackboardTemplate,
    ]
  ) as Rnd["onDrag"];

  const handleDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDragStop = useCallback(
    (_, data) => {
      const pageDirectionRatio = pageSelected?.pageDirectionRatio || 1;
      const sizePageRatio = pageSelected?.sizePageRatio || 1;

      setIsDragging(false);
      if (!isComponentDragging) return;

      let position = {
        x: data.x * scale.x,
        y: data.y * scale.y,
      };

      const nearestComponent =
        components.length > 1
          ? getNearestDistance(component, position, Axis.VERTICAL) ||
            getNearestDistance(component, position, Axis.HORIZONTAL)
          : undefined;

      if (
        overlapComponent(component, undefined, position) ||
        nearestComponent
      ) {
        position = {
          x: lastDataRef.current.position.x,
          y: lastDataRef.current.position.y,
        };
      }

      const newComponent = {
        ...component,
        position,
        realPosition: {
          x: position.x * pageDirectionRatio * sizePageRatio,
          y: position?.y / (pageDirectionRatio / sizePageRatio),
        },
      } as TemplateComponent;

      onDispatchComponents({ newData: newComponent, components });
    },
    [
      components,
      pageSelected?.pageDirectionRatio,
      pageSelected?.sizePageRatio,
      isComponentDragging,
      scale.x,
      scale.y,
      getNearestDistance,
      component,
      overlapComponent,
    ]
  ) as Rnd["onDragStop"];

  const getMergedCellSize = (cell: CellType, component: TemplateComponent) => {
    const indexCell = getIndexCell(cell);
    const width = component?.detail?.rows?.[indexCell.row]?.cells
      ?.filter((cell) => {
        const indexOfColumn = getCellPositionByCellId({
          cellId: cell.cellId,
          component,
        })?.index?.col;

        return (
          indexCell.col <= indexOfColumn &&
          indexOfColumn < indexCell.col + Number(cell.colSpan)
        );
      })
      ?.reduce((totalWidth, item) => {
        const indexItem = getIndexCell(item);

        return (
          totalWidth +
          allCellSize.current?.[indexItem.row]?.[indexItem.col]?.width
        );
      }, 0);

    const height = component?.detail?.rows
      ?.filter(
        (row) =>
          indexCell.row <= getIndexFromId(row.idRow) &&
          getIndexFromId(row.idRow) < indexCell.row + Number(cell.rowSpan)
      )
      ?.reduce((totalHeight, item) => {
        const cell = item.cells![indexCell.col];
        let height = 0;
        if (!cell) {
          height = 0;
        } else {
          const indexItem = getIndexCell(item.cells![indexCell.col]);
          height =
            allCellSize.current?.[indexItem.row]?.[indexItem.col]?.height;
        }

        return totalHeight + height;
      }, 0);

    return {
      width: width as number,
      height: height as number,
    };
  };

  const handleUIResizing = useCallback(
    (delta: ResizableDelta, size: { height: number; width: number }) => {
      const listRow = [...(component?.detail?.rows ?? [])];
      const tableSize = getTableSize(component);
      const minWidthTable = minSize.minWidth;
      const minHeightTable = minSize.minHeight;

      allCellSize.current = listRow.map((row) => {
        return row.cells!.map((cell) => ({
          width:
            Number(cell.colSpan) > 1
              ? getOriginalSize(listRow, cell).width
              : cell.width!,
          height:
            Number(cell.rowSpan) > 1
              ? getOriginalSize(listRow, cell).height
              : cell.height!,
        }));
      });
      const newDelta = {
        ...delta,
        width: delta.width * scale.x,
        height: delta.height * scale.y,
      };

      if (newDelta.width !== 0) {
        if (newDelta.width > 0) {
          const widthUp = newDelta.width / tableSize.column;

          allCellSize.current = listRow.map((row, indexRow) => {
            return row.cells!.map((_, indexCell) => {
              const currentCell = allCellSize.current[indexRow][indexCell];

              return {
                ...currentCell,
                width: currentCell.width! + widthUp,
              };
            });
          });

          listRow.forEach((row, indexRow) => {
            row.cells?.forEach((cell, indexCell) => {
              const currentCell = allCellSize.current[indexRow][indexCell];
              const width =
                Number(cell.colSpan) > 1
                  ? getMergedCellSize(cell, component).width
                  : currentCell.width;

              updateDOM(cell, width * scale.displayX, CellSizeSetting.WIDTH);
              if (indexRow === 0) {
                updateColDOM(
                  `${cell.position?.idColumn}${component.componentId}`,
                  currentCell.width * scale.displayX
                );
              }
            });
          });
        } else {
          if (newDelta.width + (component.size.width - minWidthTable) <= 0) {
            allCellSize.current = listRow.map((row, indexRow) => {
              return row.cells!.map((_, indexCell) => {
                return {
                  ...allCellSize.current[indexRow][indexCell],
                  width: minSize.minColumnWidth[indexCell],
                };
              });
            });

            listRow.forEach((row, indexRow) => {
              row.cells?.forEach((cell, indexCell) => {
                const currentCell = allCellSize.current[indexRow][indexCell];
                const width =
                  Number(cell.colSpan) > 1
                    ? getMergedCellSize(cell, component).width
                    : currentCell.width;

                updateDOM(cell, width * scale.displayX, CellSizeSetting.WIDTH);

                if (indexRow === 0) {
                  updateColDOM(
                    `${cell.position?.idColumn}${component.componentId}`,
                    currentCell.width * scale.displayX
                  );
                }
              });
            });
          } else {
            const widthTableCanResize = component.size.width - minWidthTable;
            const avgDown = ((-1 * newDelta.width) / widthTableCanResize) * 100;
            allCellSize.current = listRow.map((row, indexRow) => {
              return row.cells!.map((_, indexCell) => {
                const currentCell = allCellSize.current[indexRow][indexCell];
                const width =
                  currentCell.width! -
                  ((currentCell.width! - minSize.minColumnWidth[indexCell]) *
                    avgDown) /
                    100;

                return {
                  ...currentCell,
                  width: width,
                };
              });
            });

            listRow.forEach((row, indexRow) => {
              row.cells?.forEach((cell, indexCell) => {
                const currentCell = allCellSize.current[indexRow][indexCell];
                const width =
                  Number(cell.colSpan) > 1
                    ? getMergedCellSize(cell, component).width
                    : currentCell.width;

                updateDOM(cell, width * scale.displayX, CellSizeSetting.WIDTH);

                if (indexRow === 0) {
                  updateColDOM(
                    `${cell.position?.idColumn}${component.componentId}`,
                    currentCell.width * scale.displayX
                  );
                }
              });
            });
          }
        }

        if (
          component.size.width + newDelta.width < DEFAULT_WIDTH_HEADER_TABLE &&
          isResizable
        ) {
          updateBorderHeaderTable(component, "1px", "0px 1px 1px 1px");
        } else {
          updateBorderHeaderTable(component, "1px 1px 0px 1px", "1px");
        }
      }

      const isLinkedTable = !!component?.linkedHeaderId;
      const tableLinkedList = components.filter((com) => {
        if (isLinkedTable) {
          return (
            (com.linkedHeaderId === component.linkedHeaderId ||
              com.componentId === component.linkedHeaderId) &&
            com.componentId !== component.componentId
          );
        }

        return com?.linkedHeaderId === component.componentId;
      });

      if (tableLinkedList?.length) {
        tableLinkedList.forEach((item) => {
          const componentElement = document.getElementById(
            `resize-${item.componentId}`
          ) as HTMLDivElement;
          componentElement.style.width = `${size.width}px`;

          item.detail?.rows?.forEach((row, indexRow) => {
            row.cells?.forEach((cell, indexCell) => {
              const width = allCellSize.current[0][indexCell].width;

              updateDOM(cell, width * scale.displayX, CellSizeSetting.WIDTH);

              if (indexRow === 0) {
                updateColDOM(
                  `${cell.position?.idColumn}${component.componentId}`,
                  allCellSize.current[0][indexCell].width * scale.displayX
                );
              }
            });
          });
        });
      }

      if (newDelta.height !== 0) {
        if (newDelta.height > 0) {
          const heightUp = newDelta.height / tableSize.row;

          allCellSize.current = listRow.map((row, indexRow) => {
            return row.cells!.map((_, indexCell) => {
              const currentCell = allCellSize.current[indexRow][indexCell];
              const height = currentCell.height! + heightUp;

              return {
                ...currentCell,
                height: height,
              };
            });
          });

          listRow.forEach((row, indexRow) => {
            updateRowDOM(
              row.idRow,
              allCellSize.current[indexRow][0].height * scale.displayY
            );

            row.cells?.forEach((cell, indexCell) => {
              let subTableRowCount = cell.subTable?.rows?.length;
              let height =
                Number(cell.rowSpan) > 1
                  ? getMergedCellSize(cell, component).height
                  : allCellSize.current[indexRow][indexCell].height;
              height = height * scale.displayY;

              if (!!subTableRowCount && cell.subTable) {
                const subDeltaHeight = component.detail?.isRepeat
                  ? height / subTableRowCount
                  : height * (subTableRowCount || 1) - cell.height!;
                const subDelta = {
                  width: 0,
                  height: subDeltaHeight,
                };

                let allSubCellSize = getAllSubCellSizeAfterResize({
                  parentCell: cell,
                  subTable: cell.subTable,
                  delta: subDelta,
                });
                allSubCellSize = allSubCellSize.map((cellSize) =>
                  cellSize.map(({ width }) => ({
                    width,
                    height: height / subTableRowCount!,
                  }))
                );

                updateSubTableDOM(cell.subTable, allSubCellSize, subDelta);
              }

              if (cell.isSubCell) {
                const parentCell = getParentCell(cell, component);
                subTableRowCount = parentCell.subTable?.rows?.length || 1;
              }

              updateDOM(
                cell,
                height / (subTableRowCount || 1),
                CellSizeSetting.HEIGHT
              );
            });
          });
        } else {
          if (newDelta.height + (component.size.height - minHeightTable) <= 0) {
            allCellSize.current = listRow.map((row, indexRow) => {
              return row.cells!.map((_, indexCell) => {
                return {
                  ...allCellSize.current[indexRow][indexCell],
                  height: minSize.minRowHeight[indexRow],
                };
              });
            });

            listRow.forEach((row, indexRow) => {
              updateRowDOM(
                row.idRow,
                allCellSize.current[indexRow][0].height * scale.displayY
              );

              row.cells?.forEach((cell, indexCell) => {
                const height =
                  Number(cell.rowSpan) > 1
                    ? getMergedCellSize(cell, component).height
                    : allCellSize.current[indexRow][indexCell].height;

                if (!!cell.subTable?.rows?.length && height !== cell.height) {
                  const subDelta = {
                    width: 0,
                    height: height - cell.height!,
                  };
                  const allSubCellSize = getAllSubCellSizeAfterResize({
                    parentCell: cell,
                    subTable: cell.subTable,
                    delta: subDelta,
                  });
                  updateSubTableDOM(cell.subTable, allSubCellSize, subDelta);
                }

                updateDOM(
                  cell,
                  height * scale.displayY,
                  CellSizeSetting.HEIGHT
                );
              });
            });
          } else {
            const heightTableCanResize = component.size.height - minHeightTable;
            const avgDown =
              ((-1 * newDelta.height) / heightTableCanResize) * 100;

            allCellSize.current = listRow.map((row, indexRow) => {
              return row.cells!.map((_, indexCell) => {
                const currentCell = allCellSize.current[indexRow][indexCell];

                const height =
                  currentCell.height! -
                  ((currentCell.height! - minSize.minRowHeight[indexRow]) *
                    avgDown) /
                    100;

                return {
                  ...currentCell,
                  height,
                };
              });
            });

            listRow.forEach((row, indexRow) => {
              updateRowDOM(
                row.idRow,
                allCellSize.current[indexRow][0].height * scale.displayY
              );

              row.cells?.forEach((cell, indexCell) => {
                let subTableRowCount = cell.subTable?.rows?.length;

                let height =
                  Number(cell.rowSpan) > 1
                    ? getMergedCellSize(cell, component).height
                    : allCellSize.current[indexRow][indexCell].height;
                height = height * scale.displayY;

                if (!!subTableRowCount && cell.subTable) {
                  const subDeltaHeight = component.detail?.isRepeat
                    ? height / subTableRowCount
                    : height * (subTableRowCount || 1) - cell.height!;
                  const subDelta = {
                    width: 0,
                    height: subDeltaHeight,
                  };
                  let allSubCellSize = getAllSubCellSizeAfterResize({
                    parentCell: cell,
                    subTable: cell.subTable,
                    delta: subDelta,
                  });
                  allSubCellSize = allSubCellSize.map((cellSize) =>
                    cellSize.map(({ width }) => ({
                      width,
                      height: height / subTableRowCount!,
                    }))
                  );
                  updateSubTableDOM(cell.subTable, allSubCellSize, subDelta);
                }

                if (cell.isSubCell) {
                  const parentCell = getParentCell(cell, component);
                  subTableRowCount = parentCell.subTable?.rows?.length || 1;
                }

                updateDOM(
                  cell,
                  height / (subTableRowCount || 1),
                  CellSizeSetting.HEIGHT
                );
              });
            });
          }
        }
      }
    },
    [component, components, isResizable, minSize, scale]
  );

  const handleResize = useCallback(
    (
      e: MouseEvent | TouchEvent,
      dir: Direction,
      refToElement: HTMLElement,
      delta: ResizableDelta,
      position: Position,
      otherComponent?: TemplateComponent
    ) => {
      let isCollisionLinkedTable = false,
        collisionTable,
        isOverlapTable,
        isOutLimitTable;

      const currentComponent = otherComponent || component;
      const isResizeLeft = dir.toLocaleLowerCase().includes("left");
      const isResizeTop = dir.toLocaleLowerCase().includes("top");
      const isLinkedImage =
        currentComponent.type === TemplateComponentType.LinkedImage;

      const newPosition = {
        x: position.x,
        y: position.y,
      };

      const newSize = {
        width: displayComponent.size.width + delta.width,
        height: displayComponent.size.height + delta.height,
      };

      if (isResizeLeft) {
        newPosition.x = displayComponent.position.x - delta.width;
      }
      if (isResizeTop) {
        newPosition.y = displayComponent.position.y - delta.height;
      }

      setIsResizing(true);
      const isTableHeader =
        component.type === TemplateComponentType.TableHeader;
      const isLinkedTable = !!component.linkedHeaderId;

      const tableLinkedList = components.filter((com) => {
        if (!com?.linkedHeaderId) {
          return false;
        }

        if (isLinkedTable) {
          return (
            com.linkedHeaderId === component.linkedHeaderId &&
            com.componentId !== component.componentId
          );
        }

        return com?.linkedHeaderId === component.componentId;
      });

      const tableHeader = components.find(
        (com) =>
          com.componentId === component.linkedHeaderId &&
          com.type === TemplateComponentType.TableHeader
      );
      if (isLinkedTable && tableHeader) {
        tableLinkedList.push(tableHeader);
      }

      if ((isTableHeader || isLinkedTable) && tableLinkedList?.length) {
        tableLinkedList.sort((c1, c2) => {
          if (isResizeLeft) {
            return c1.position.x - c2.position.x;
          } else {
            return c2.position.x - c1.position.x;
          }
        });

        if (isCollisionLinkedTableRef.current?.componentId) {
          const index = tableLinkedList.findIndex(
            (c) =>
              c.componentId === isCollisionLinkedTableRef.current?.componentId
          );
          tableLinkedList.splice(index, 1);
          tableLinkedList.unshift(isCollisionLinkedTableRef.current);
        }

        for (const tableLinked of tableLinkedList) {
          const position = {
            ...tableLinked.position,
            ...(isResizeLeft
              ? { x: tableLinked.position.x - delta.width * scale.x }
              : {}),
          };

          const size = {
            ...tableLinked.size,
            width: newSize.width,
          };

          isOverlapTable = checkHasOverlap({
            target: tableLinked,
            position,
            size,
            axis: Axis.HORIZONTAL,
            components,
          });

          position.y = tableLinked.realPosition.y;
          if (pageSelected.pageDirection === PaperDirectionType.HORIZONTAL) {
            position.y = tableLinked.realPosition.y;
            size.height =
              tableLinked.size.height * Math.floor(scale.displayY) +
              Math.floor(delta.height);

            [position.x, position.y] = [position.y, position.x];
            [size.width, size.height] = [size.height, size.width];
          }

          isOutLimitTable = checkIsOutOfLimit({
            position,
            size,
            isBlackboardTemplate,
          });

          if (isOutLimitTable || !!isOverlapTable) {
            isCollisionLinkedTable = true;
            collisionTable = tableLinked;
            break;
          }
        }
      }

      const isOverlap = overlapComponent(
        currentComponent,
        undefined,
        {
          x: newPosition.x * scale.x,
          y: newPosition.y * scale.y,
        },
        {
          width: newSize.width * scale.x,
          height: newSize.height * scale.y,
        }
      );

      if (!isOverlap && !isCollisionLinkedTable) {
        // size and position with scale
        lastDataRef.current = {
          position: {
            x: newPosition.x * scale.x,
            y: newPosition.y * scale.y,
          },
          size: {
            width: newSize.width * scale.x,
            height: newSize.height * scale.y,
          },
        };

        const componentElement = document.getElementById(
          `resize-${component.componentId}`
        );

        if (componentElement) {
          refToElement.style.transform = `translate(${newPosition.x}px, ${newPosition.y}px)`;
          refToElement.style.height = `${newSize.height}px`;
          refToElement.style.width = `${newSize.width}px`;
        }

        if (
          (isLinkedTable || isTableHeader) &&
          isResizeLeft &&
          tableLinkedList.length
        ) {
          tableLinkedList.forEach((tableLinked) => {
            const table = document.getElementById(
              `resize-${tableLinked?.componentId}`
            );
            if (table) {
              table.style.transform = `translate(${
                tableLinked.position.x * scale.displayX - delta.width
              }px, ${tableLinked.position.y * scale.displayY}px)`;
            }
          });
        }

        // handle resize when component type is Linked image with other logic
        if (isLinkedImage) {
          const tableSize = getTableSize(component);
          const listRow = cloneDeep(component?.detail?.rows ?? []);
          const cellPropertyExclude = [
            CellProperty.DOCUMENT_DATA,
            CellProperty.LINKED_IMAGE_DEFAULT_TEXT,
            CellProperty.LINKED_IMAGE_LINE,
          ];

          const cellDefaultTextAndLineLength = (
            component?.detail?.rows || []
          )?.filter((row) => {
            const cells = row?.cells || [];
            const cellProperties = cells.map((cell) => cell.cellProperty);

            return cellProperties.some((property) =>
              cellPropertyExclude.includes(property || ("" as any))
            );
          })?.length;

          listRow.forEach((row, rowIndex) => {
            row.cells?.forEach((cell, cellIndex) => {
              if (!cell.cellProperty && delta.width !== 0) {
                return;
              }

              if (cellPropertyExclude.includes(cell.cellProperty as any)) {
                return;
              }

              if (delta.height !== 0) {
                const height =
                  (newSize.height -
                    cellDefaultTextAndLineLength *
                      CellSizeSetting.MIN_HEIGHT *
                      scale.displayY) /
                  (tableSize.row - cellDefaultTextAndLineLength);

                allCellSize.current[rowIndex][cellIndex].height =
                  height / scale.displayY;

                updateDOM(
                  cell,
                  height - TableDefaultStyle.DEFAULT_BORDER_SIZE,
                  CellSizeSetting.HEIGHT
                );
                updateRowDOM(
                  cell.idRow,
                  height - TableDefaultStyle.DEFAULT_BORDER_SIZE
                );
              }

              if (delta.width !== 0) {
                const cellBlankLineLength =
                  row.cells?.filter((c) => !c.cellProperty).length || 0;
                const cellImageLength =
                  (row?.cells?.length || 1) - cellBlankLineLength;

                const width =
                  cell?.colSpan! > 1
                    ? newSize.width
                    : (newSize.width -
                        cellBlankLineLength *
                          CellSizeSetting.MIN_WIDTH *
                          scale.displayY) /
                      cellImageLength;

                allCellSize.current[rowIndex][cellIndex].width =
                  width / scale.displayY;

                updateDOM(cell, width, CellSizeSetting.WIDTH);
              }
            });
          });
        } else {
          handleUIResizing(delta, newSize);
        }

        overlapRef.current = {
          overlapVertical: {} as TemplateComponent,
          overlapHorizontal: {} as TemplateComponent,
        };
      } else {
        let size = { ...lastDataRef.current.size };
        let position = { ...lastDataRef.current.position };

        if (isOverlap && !isCollisionLinkedTableRef.current?.componentId) {
          const isPrevOverlapVertical =
            !!overlapRef.current.overlapVertical?.componentId;
          const isPrevOverlapHorizontal =
            !!overlapRef.current.overlapHorizontal?.componentId;

          const overlapVertical = overlapComponent(
            currentComponent,
            Axis.VERTICAL,
            isPrevOverlapVertical
              ? lastDataRef.current.position
              : {
                  x: newPosition.x * scale.x,
                  y: newPosition.y * scale.y,
                },
            isPrevOverlapVertical
              ? lastDataRef.current.size
              : {
                  width: newSize.width * scale.x,
                  height: newSize.height * scale.y,
                }
          );
          const overlapHorizontal = overlapComponent(
            currentComponent,
            Axis.HORIZONTAL,
            isPrevOverlapHorizontal
              ? lastDataRef.current.position
              : {
                  x: newPosition.x * scale.x,
                  y: newPosition.y * scale.y,
                },
            isPrevOverlapHorizontal
              ? lastDataRef.current.size
              : {
                  width: newSize.width * scale.x,
                  height: newSize.height * scale.y,
                }
          );

          if (overlapVertical) {
            size = {
              ...size,
              height: isResizeTop
                ? currentComponent.position.y +
                  currentComponent.size.height -
                  (overlapVertical.position.y! + overlapVertical.size.height)
                : overlapVertical.position.y - currentComponent.position.y,
            };
            position = {
              ...position,
              y: isResizeTop
                ? overlapVertical.position.y + overlapVertical.size.height
                : currentComponent.position.y,
            };

            overlapRef.current.overlapVertical = overlapVertical;
          }

          if (overlapHorizontal) {
            size = {
              ...size,
              width: isResizeLeft
                ? currentComponent.position.x +
                  currentComponent.size.width -
                  (overlapHorizontal.position.x + overlapHorizontal.size.width)
                : overlapHorizontal.position.x - currentComponent.position.x,
            };
            position = {
              ...position,
              x: isResizeLeft
                ? overlapHorizontal?.position?.x! +
                  overlapHorizontal?.size?.width!
                : currentComponent.position.x,
            };

            overlapRef.current.overlapHorizontal = overlapHorizontal;
          }
        } else {
          if (collisionTable) {
            if (isOutLimitTable) {
              if (isResizeLeft) {
                position = {
                  ...position,
                  x: component.position.x - collisionTable.position.x,
                };
                size = {
                  ...size,
                  width: collisionTable.position.x + component.size.width,
                };
              } else {
                size = {
                  ...size,
                  width: isBlackboardTemplate
                    ? DEFAULT_BLACKBOARD_CONTENT_SIZE.width -
                      collisionTable.position.x
                    : DEFAULT_GRID_SIZE.width - collisionTable.position.x,
                };
              }
            }

            if (isOverlapTable) {
              if (isResizeLeft) {
                position = {
                  ...position,
                  x:
                    isOverlapTable.position.x +
                    isOverlapTable.size.width +
                    component.position.x -
                    collisionTable.position.x,
                };
                size = {
                  ...size,
                  width:
                    collisionTable.position.x +
                    component.size.width -
                    isOverlapTable.position.x -
                    isOverlapTable.size.width,
                };
              } else {
                size = {
                  ...size,
                  width: isOverlapTable.position.x - collisionTable.position.x,
                };
              }
            }

            isCollisionLinkedTableRef.current = collisionTable;
          }
        }

        if (tableLinkedList?.length && (isLinkedTable || isTableHeader)) {
          tableLinkedList.forEach((tableLinked) => {
            const table = document.getElementById(
              `resize-${tableLinked?.componentId}`
            );

            if (table) {
              table.style.width = `${size.width * scale.displayX}px`;

              if (isResizeLeft) {
                table.style.transform = `translate(${
                  (position.x - component.position.x + tableLinked.position.x) *
                  scale.displayX
                }px, ${tableLinked.position.y * scale.displayY}px)`;
              }
            }
          });
        }

        componentRef.current?.updatePosition({
          x: position.x * scale.displayX,
          y: position.y * scale.displayY,
        });
        componentRef.current?.updateSize({
          width: size.width * scale.displayX,
          height: size.height * scale.displayY,
        });

        lastDataRef.current = { size, position };
      }
    },
    [
      pageSelected.pageDirection,
      component,
      components,
      displayComponent,
      isBlackboardTemplate,
      scale,
      handleUIResizing,
      overlapComponent,
    ]
  ) as Rnd["onResize"];

  const handleResizeStop = useCallback(
    (
      e: MouseEvent | TouchEvent,
      dir: Direction,
      refToElement: HTMLElement,
      delta: ResizableDelta,
      position: Position,
      otherComponent?: TemplateComponent
    ) => {
      const currentComponent = otherComponent || component;
      let listRow = cloneDeep(component?.detail?.rows ?? []);

      const newSize = lastDataRef.current.size;
      const newPosition = lastDataRef.current.position;
      const pageDirectionRatio = pageSelected?.pageDirectionRatio || 1;
      const sizePageRatio = pageSelected?.sizePageRatio || 1;

      const newSelectedCells = [...selectedCells];
      setIsResizing(false);

      listRow = listRow?.map((row, indexRow) => {
        return {
          ...row,
          cells: row?.cells?.map((cell, indexCell) => {
            const mergedCellSize = getMergedCellSize(cell, component);
            const allCurrentCellSize =
              allCellSize.current?.[indexRow]?.[indexCell];

            if (!allCellSize.current.length) {
              return cell;
            }

            const newWidth =
              Number(cell.colSpan) > 1
                ? mergedCellSize?.width
                : allCurrentCellSize?.width;
            const newHeight =
              Number(cell.rowSpan) > 1
                ? mergedCellSize?.height
                : allCurrentCellSize?.height;

            const newCell = {
              ...cell,
              width: newWidth,
              height: newHeight,
              subTable:
                !!cell.subTable?.rows?.length &&
                (newHeight !== cell.height || newWidth !== cell.width)
                  ? resizeSubTable(cell, cell.subTable, {
                      width: newWidth - cell.width!,
                      height: newHeight - cell.height!,
                    })
                  : cell.subTable,
            } as CellType;

            //update selectedCells
            const indexCellSelected = selectedCells.findIndex(
              (cell) => cell?.cellId === newCell?.cellId
            );
            if (indexCellSelected !== -1) {
              newSelectedCells[indexCellSelected] = newCell;
            }

            return newCell;
          }),
        };
      });

      const newComponent = {
        ...currentComponent,
        position: newPosition,
        size: newSize,
        realSize: {
          width: newSize?.width * pageDirectionRatio * sizePageRatio,
          height: newSize?.height / (pageDirectionRatio / sizePageRatio),
        },
        realPosition: {
          x: newPosition?.x * pageDirectionRatio * sizePageRatio,
          y: newPosition?.y / (pageDirectionRatio / sizePageRatio),
        },
        detail: {
          ...currentComponent.detail,
          rows: listRow,
        },
      } as TemplateComponent;

      if (
        otherComponent &&
        otherComponent.type !== TemplateComponentType.TableHeader
      ) {
        return newComponent;
      }

      let newComponents = [...components];

      newComponents = newComponents.map((item) => {
        return item.componentId === newComponent.componentId
          ? newComponent
          : item;
      });

      // update for component linked
      if (
        currentComponent.type !== TemplateComponentType.TableHeader &&
        !currentComponent.linkedHeaderId
      ) {
        onDispatchComponents({ newData: newComponent, components });
      } else {
        if (otherComponent) {
          return newComponent;
        }
        const newListComponent = newComponents.map((item) => {
          if (item.componentId === currentComponent.componentId) {
            return newComponent;
          } else if (
            item?.linkedHeaderId === component.componentId ||
            item?.componentId === component.linkedHeaderId
          ) {
            const newRows = item.detail?.rows?.map((row) => {
              const newCells = row.cells?.map((cell, index) => {
                const newWidth =
                  newComponent.detail?.rows![0].cells![index].width || 0;

                return {
                  ...cell,
                  width: newWidth,
                  subTable:
                    !!cell.subTable?.rows?.length && newWidth !== cell.width
                      ? resizeSubTable(cell, cell.subTable, {
                          width: newWidth - cell.width!,
                          height: 0,
                        })
                      : cell.subTable,
                };
              });

              return {
                ...row,
                cells: newCells,
              };
            });

            return {
              ...item,
              size: {
                ...item.size,
                width: newComponent.size.width,
              },
              position: {
                ...item.position,
                ...(dir.toLocaleLowerCase().includes("left")
                  ? { x: item.position.x - delta.width * scale.x }
                  : {}),
              },
              detail: {
                ...item.detail,
                rows: newRows,
              },
            };
          }

          return item;
        });
        if (!isEqual(componentsRef.current, newListComponent)) {
          dispatch(setComponents(newListComponent));
        }
      }

      dispatch(setSelectedCell(newSelectedCells));

      overlapRef.current = {
        overlapVertical: {} as TemplateComponent,
        overlapHorizontal: {} as TemplateComponent,
      };
      isCollisionLinkedTableRef.current = {} as TemplateComponent;
    },
    [
      pageSelected.pageDirectionRatio,
      pageSelected.sizePageRatio,
      component,
      components,
      dispatch,
      scale,
      selectedCells,
    ]
  ) as Rnd["onResizeStop"];

  const rndProps: Partial<Rnd> = useMemo((): Partial<Rnd> => {
    if (isOnlyView) {
      return {} as any;
    }

    const other: any = {
      onMouseEnter: () => setIsResizable(true),
      onMouseLeave: () => setIsResizable(false),
    };

    return {
      onDrag: handleDrag,
      onDragStart: handleDragStart,
      onDragStop: handleDragStop,
      onResize: handleResize,
      onResizeStop: handleResizeStop,
      ...other,
    };
  }, [
    isOnlyView,
    handleDrag,
    handleDragStart,
    handleDragStop,
    handleResize,
    handleResizeStop,
  ]);

  return {
    rndProps,
    isResizing,
    isDragging,
    isResizable,
  };
};

export default useRnd;
