import { Icon, IconProps } from "@chakra-ui/icons";

const MinusThinIcon = (props: IconProps) => {
  return (
    <Icon width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <mask
        id="mask0_2169_6649"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="currentcolor" />
      </mask>
      <g mask="url(#mask0_2169_6649)">
        <path
          d="M5.25 12.75C5.0375 12.75 4.85942 12.6781 4.71575 12.5342C4.57192 12.3904 4.5 12.2122 4.5 11.9997C4.5 11.7871 4.57192 11.609 4.71575 11.4655C4.85942 11.3218 5.0375 11.25 5.25 11.25H18.75C18.9625 11.25 19.1406 11.3219 19.2843 11.4658C19.4281 11.6096 19.5 11.7878 19.5 12.0003C19.5 12.2129 19.4281 12.391 19.2843 12.5345C19.1406 12.6782 18.9625 12.75 18.75 12.75H5.25Z"
          fill="currentcolor"
        />
      </g>
    </Icon>
  );
};

export default MinusThinIcon;
