import { DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FlexProps,
  IconButton,
  IconButtonProps,
} from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import { HEIGHT_DEFAULT_AUDIO_WAVEFORM } from "constants/audio";
import { useEffect, useRef, useState } from "react";
import { downloadFile, isValidUrlS3 } from "utils/file";
import WaveSurfer from "wavesurfer.js";

interface Props extends FlexProps {
  src: string;
  isPreview?: boolean;
  playIconProps?: Partial<IconButtonProps>;
  readonly?: boolean;

  onDelete?: () => void;
}

function AudioPlayer({
  src,
  onDelete,
  height = HEIGHT_DEFAULT_AUDIO_WAVEFORM,
  isPreview,
  playIconProps,
  readonly = false,
  ...props
}: Props) {
  const [playing, setPlaying] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const waveformRef = useRef<any>();
  const waveformEleRef = useRef<any>();

  useEffect(() => {
    (async () => {
      const isValidFileS3 = await isValidUrlS3(src);
      if (isValidFileS3 && waveformRef.current && src) {
        waveformEleRef.current = WaveSurfer.create({
          container: waveformRef.current,
          backend: "MediaElement",
          cursorWidth: 0,
          height: height ? Number(height) : HEIGHT_DEFAULT_AUDIO_WAVEFORM,
        });
        waveformEleRef.current.load(src);
        setPlaying(waveformEleRef.current?.isPlaying());
      }

      return () => {
        if (waveformEleRef.current) {
          waveformEleRef.current?.destroy();
        }
      };
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  const handlePlayPause = () => {
    waveformEleRef.current?.playPause();
    setPlaying(waveformEleRef.current?.isPlaying());
  };

  const handleDownloadAudio = async () => {
    if (loadingDownload || isPreview) return;
    setLoadingDownload(true);
    await downloadFile(src);
    setLoadingDownload(false);
  };

  return (
    <Flex
      position="relative"
      border="1px solid #c6d395"
      borderRadius="5px"
      pl={!isPreview ? "8px" : undefined}
    >
      <IconButton
        h="26px"
        m="auto"
        aria-label="play-pause"
        background="#1795FE"
        borderRadius="50%"
        _hover={{ background: undefined }}
        p="8px"
        icon={
          <SvgIcon
            fill="#fff"
            w="1rem"
            h="1rem"
            src={playing ? "/img/pause.svg" : "/img/play.svg"}
          />
        }
        onClick={handlePlayPause}
        {...playIconProps}
      />
      <Box
        h={`${height}px`}
        w={{ base: "300px", lg: "350px" }}
        cursor="pointer"
        ref={waveformRef}
      />
      {onDelete && (
        <Box position="absolute" right="12px" bottom="0px" cursor="pointer">
          {!isPreview && (
            <IconButton
              variant="text"
              onClick={handleDownloadAudio}
              aria-label="download"
              padding="0.4rem"
              color="icon.default"
              icon={<DownloadIcon />}
            />
          )}
          {!readonly && (
            <IconButton
              variant="dangerText"
              onClick={onDelete}
              aria-label="delete"
              padding="0.4rem"
              icon={<DeleteIcon />}
            />
          )}
        </Box>
      )}
    </Flex>
  );
}

export default AudioPlayer;
