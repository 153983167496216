import {
  Badge,
  Box,
  Flex,
  FlexProps,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { documentCategoryApi } from "apiClient/v2";
import { SvgIcon } from "components/SvgIcon";
import OfflineLabel from "components/ui/OfflineLabel";
import DocumentCategoryProgress from "components/widgets/DocumentCategoryProgress";
import { ModalType } from "constants/enum";
import { documentIconPath } from "constants/file";
import { useAuthorization } from "hooks/usePermission";
import { useVisibleTooltip } from "hooks/useVisibleTooltip";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { iDocumentDataProps } from "interfaces/models/document";
import { DocumentCategory } from "interfaces/models/documentCategory";
import {
  isSelfInspectionTemplate,
  updateOrderDocumentItems,
} from "models/documentCategory";
import { WSMessage } from "interfaces/models/websocket";
import { isEqual } from "lodash-es";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  setDocumentCategorySelected,
  updateDocumentCategory,
} from "redux/documentSlice";
import { setModalType } from "redux/forgeViewerSlice";
import { clearLabelAndForgeView } from "utils/forge/extensions/custom-label";
import { AddPin } from "./AddPin";
import DocumentItemDrag from "./DocumentItemDrag";
import { useFocus } from "./hooks/useFocus";
import DocumentCategoryMenu from "./menu/DocumentCategoryMenu";
import { MessageType } from "constants/websocket";
import store, { RootState } from "redux/store";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";

interface Props extends FlexProps {
  currentUserId: string | undefined;
  isOnline: boolean;
  documentCategory: DocumentCategoryDTO;
  parentRef: React.MutableRefObject<HTMLElement | undefined>;
  isCreateDocumentItem: boolean;
  isTakasagoGroup?: boolean;
  highlightColor?: string;
  isFromDocumentGroup?: boolean;
  isSelect?: boolean;
  selectChildId?: string;
  isBlockDocumentCategory: boolean;
  setDocumentItemBlockIds: Set<string>;
  onToggleVisibleDocumentItem: (
    event: any,
    documentItem: DocumentItemDTO
  ) => Promise<void>;
  onDeletedDocumentItem: (
    documentItem: DocumentItemDTO,
    onOk?: (documentCategory?: DocumentCategoryDTO) => void
  ) => Promise<void>;
  onSelectUpdate(document: DocumentCategory): void;
  onSelectDelete(document: DocumentCategory): void;
  handleClickDocumentCategory: (documentCategory: DocumentCategoryDTO) => void;
  handleClickDocumentItem: (data: iDocumentDataProps) => void;
  onClickAddDocumentItem: (
    e: React.MouseEvent<HTMLDivElement>,
    documentCategory: DocumentCategoryDTO
  ) => void;
  onOpenOfflineModeTutorialModal: () => void;
}

function DocumentCategoryDropDown({
  currentUserId,
  isOnline,
  documentCategory,
  parentRef,
  isFromDocumentGroup,
  isCreateDocumentItem,
  isTakasagoGroup = false,
  highlightColor,
  isSelect,
  selectChildId,
  isBlockDocumentCategory,
  setDocumentItemBlockIds,
  onToggleVisibleDocumentItem,
  onDeletedDocumentItem,
  onClickAddDocumentItem,
  handleClickDocumentCategory,
  handleClickDocumentItem,
  onSelectUpdate,
  onSelectDelete,
  onOpenOfflineModeTutorialModal,
  ...rest
}: Props) {
  const { isLoadedExternalId, isLoadedFamilyInstances } = useSelector(
    (state: RootState) => state.forgeViewer
  );

  const viewportRef = useRef(null);
  const documentCategoryTitleContainerRef = useRef<HTMLDivElement>(null);
  const boxRenderListContainerRef = useRef<HTMLDivElement>(null);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const rules = useAuthorization();
  const { socket } = useForgeViewerContext();
  const onClickToggle = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      onToggle();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onToggle]
  );
  const { isShowTooltip, onChangeTextRef } = useVisibleTooltip({
    wrapperRef: documentCategoryTitleContainerRef,
  });

  const isSelected = !!isSelect || !!selectChildId;
  const isOnlySelectedCategory = !!isSelect && !selectChildId;
  const documentCategoryRef = useRef<HTMLDivElement>(null);

  const { onChangeRef } = useFocus(isOnlySelectedCategory, parentRef);

  useEffect(() => {
    if (isBlockDocumentCategory && isSelect) {
      clearLabelAndForgeView();
      dispatch(setDocumentCategorySelected());
      dispatch(setModalType(ModalType.NONE));

      onClose();
    }

    if (!isOpen && isSelected && !isBlockDocumentCategory) {
      onToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, selectChildId, isBlockDocumentCategory]);

  const handleSelectDocumentCategory = useCallback(() => {
    if (isOpen && isOnlySelectedCategory) {
      onToggle();
    }
    handleClickDocumentCategory(documentCategory);
  }, [
    isOpen,
    isOnlySelectedCategory,
    documentCategory,
    handleClickDocumentCategory,
    onToggle,
  ]);

  const handleClickAddPin = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      onClickAddDocumentItem(e, documentCategory);
    },
    [documentCategory, onClickAddDocumentItem]
  );

  const handleDragEndItem = useCallback(
    (result: DropResult) => {
      if (!rules.canEditDocumentItem) return;
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      const category = store
        .getState()
        .document.documentCategories.find(
          (node) => node.id === documentCategory.id
        );

      if (!category || !documentCategory.documentItems) return;

      const itemId = result.draggableId;
      // const sourceIndex = result.source.index;
      const desIndex = result.destination.index;

      const cloneDocumentCategory = structuredClone(category);

      if (!cloneDocumentCategory.documentItems) return;

      const realSourceIndex = cloneDocumentCategory.documentItems.findIndex(
        (documentItem) => documentItem.id === itemId
      );

      let realDescIndex = 0;
      if (desIndex !== 0) {
        const previousItem = documentCategory.documentItems[desIndex];
        realDescIndex = cloneDocumentCategory.itemIds!.findIndex(
          (id) => id === previousItem.id
        );
      }

      if (typeof realSourceIndex === "undefined" || realSourceIndex === -1) {
        return;
      }

      if (cloneDocumentCategory.itemIds?.length) {
        cloneDocumentCategory.itemIds.splice(realSourceIndex, 1);
        cloneDocumentCategory.itemIds.splice(realDescIndex, 0, itemId);
      }
      updateOrderDocumentItems(cloneDocumentCategory);
      const newMapIndexById = new Map();
      cloneDocumentCategory.itemIds?.forEach((id, index) => {
        newMapIndexById.set(id, index);
      });

      cloneDocumentCategory.documentItems = cloneDocumentCategory?.documentItems
        ?.map((item) => ({
          ...item,
          displayOrder: newMapIndexById.get(item.id),
        }))
        .sort((a, b) => (a?.displayOrder || 0) - (b?.displayOrder || 0));
      cloneDocumentCategory.updatedAt = new Date();
      documentCategoryApi.updateCategory({
        id: cloneDocumentCategory.id,
        itemIds: cloneDocumentCategory.itemIds,
        updatedAt: cloneDocumentCategory.updatedAt,
      });
      // send websocket message update document category
      socket.changeDocCategory(cloneDocumentCategory, {
        itemIds: cloneDocumentCategory.itemIds,
      });

      dispatch(updateDocumentCategory(cloneDocumentCategory));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentCategory, rules, dispatch, socket]
  );

  const children = useMemo(() => {
    if (!isOpen) {
      return <></>;
    }
    const documentItems = documentCategory?.documentItems || [];
    const isDragDisabled =
      documentItems?.length <= 1 ||
      isSelfInspectionTemplate(documentCategory?.documentType) ||
      !isOnline ||
      !rules.canEditDocumentItem;
    const isSelfInspection = isSelfInspectionTemplate(
      documentCategory?.documentType
    );
    const setExternalIds = new Set(documentCategory?.selectedExternalIds ?? []);

    return (
      <div ref={viewportRef}>
        <DragDropContext onDragEnd={handleDragEndItem}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                id={documentCategory.id}
              >
                {documentItems?.map((item, index) => {
                  const isShowMenuEditItem = !isSelfInspectionTemplate(
                    documentCategory.documentType
                  );
                  const isShowDragAction = !isSelfInspectionTemplate(
                    documentCategory.documentType
                  );
                  const isBlockCurrentDocumentItem =
                    setDocumentItemBlockIds?.has(item.id) && !item.offlineId;

                  return (
                    <Draggable
                      isDragDisabled={
                        isDragDisabled || isBlockCurrentDocumentItem
                      }
                      key={item.id}
                      index={index}
                      draggableId={item.id}
                    >
                      {(provided, snapshot) => {
                        //@ts-ignore
                        return (
                          <Box
                            onMouseDown={(e) => {
                              e.currentTarget.focus();
                            }}
                            userSelect="none"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...(rules.canEditDocumentItem
                              ? provided.dragHandleProps
                              : {})}
                            style={{
                              background: snapshot.isDragging
                                ? "#BDE7FD"
                                : "initial",
                              ...provided.draggableProps.style,
                              opacity:
                                !snapshot.isDragging || snapshot.draggingOver
                                  ? 1
                                  : 0.5,
                            }}
                          >
                            <DocumentItemDrag
                              index={index + 1}
                              isOnline={isOnline}
                              documentItem={item}
                              isSelect={selectChildId === item.id}
                              isVisible={
                                isSelfInspection || setExternalIds.has(item.id)
                              }
                              parentRef={documentCategoryRef}
                              wrapperListRef={parentRef}
                              ml="7rem"
                              width="calc(100% - 7rem)"
                              highlightColor={
                                highlightColor ? "#BDE7FD" : "#E0F2FE"
                              }
                              isFromDocumentGroup={isFromDocumentGroup}
                              isShowMenuEditItem={isShowMenuEditItem}
                              isShowDragAction={isShowDragAction}
                              isDisabledDrag={isDragDisabled}
                              isBlockDocumentItem={!!isBlockCurrentDocumentItem}
                              documentCategoryTitle={documentCategory?.title}
                              handleClickDocumentCategory={
                                handleClickDocumentCategory
                              }
                              handleClickDocumentItem={handleClickDocumentItem}
                              onToggleVisibleDocumentItem={
                                onToggleVisibleDocumentItem
                              }
                              onDeletedDocumentItem={onDeletedDocumentItem}
                            />
                          </Box>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder as any}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
        {rules.canAddDocumentItem &&
          !isSelfInspectionTemplate(documentCategory?.documentType) && (
            <Box ml="7rem">
              <AddPin
                handleClickAddPin={handleClickAddPin}
                isDisabled={!isLoadedExternalId || !isLoadedFamilyInstances}
                isCreateDocumentItem={isCreateDocumentItem && !!isSelect}
              />
            </Box>
          )}
      </div>
    );
  }, [
    isOpen,
    rules.canAddDocumentItem,
    rules.canEditDocumentItem,
    isOnline,
    documentCategory?.title,
    documentCategory?.documentItems,
    documentCategory?.documentType,
    documentCategory?.selectedExternalIds,
    documentCategory.id,
    isCreateDocumentItem,
    isSelect,
    selectChildId,
    parentRef,
    isLoadedExternalId,
    isLoadedFamilyInstances,
    highlightColor,
    isFromDocumentGroup,
    setDocumentItemBlockIds,
    onToggleVisibleDocumentItem,
    onDeletedDocumentItem,
    handleDragEndItem,
    handleClickAddPin,
    handleClickDocumentCategory,
    handleClickDocumentItem,
  ]);

  return (
    <Box cursor={isBlockDocumentCategory ? "not-allowed" : "initial"}>
      <Box
        bgColor={
          isBlockDocumentCategory
            ? "#E0E0E0"
            : isSelected
            ? highlightColor ?? "#F0F9FF"
            : "transparent"
        }
        id={`doc-category-${documentCategory.id}`}
        cursor="pointer"
        ref={documentCategoryRef}
        width="100%"
        position="relative"
        {...rest}
        pointerEvents={isBlockDocumentCategory ? "none" : "initial"}
      >
        <Flex
          ref={onChangeRef}
          cursor="pointer"
          alignItems="flex-start"
          width="100%"
          sx={
            isOnlySelectedCategory
              ? {
                  "&::before": {
                    content: '""',
                    zIndex: 2,
                    position: "absolute",
                    height: documentCategory.blockedBy ? "11.7rem" : "8.2rem",
                    borderLeft: "4px solid #009BE0",
                    left: rest.pl,
                  },
                }
              : {}
          }
          onClick={handleSelectDocumentCategory}
        >
          <Flex
            w="4rem"
            h="4rem"
            pt="2px"
            alignItems="center"
            justifyContent="center"
          >
            <SvgIcon
              src="/img/icon-expand-item-left-bar.svg"
              w="2rem"
              transition="0.1s"
              transform={isOpen ? "rotate(90deg)" : "rotate(0deg)"}
              className="button"
              onClick={onClickToggle as any}
            />
          </Flex>

          <Flex
            width="100%"
            borderTop={isFromDocumentGroup ? "1px solid #D4D4D4" : undefined}
            flexDirection="row"
            maxWidth={"calc(100% - 4rem)"}
          >
            <Flex
              alignItems="flex-start"
              gap="0.6rem"
              flexGrow={2}
              p=".8rem 1rem .8rem 0"
              w="70%"
            >
              <SvgIcon
                flexShrink={0}
                width="2.4rem"
                height="2.4rem"
                src={documentIconPath}
                sx={{
                  path: {
                    fill: isOnlySelectedCategory ? "#171717" : "#737373",
                  },
                }}
              />
              <Box flexShrink={0} maxWidth="calc(100% - 3rem)" width="100%">
                {documentCategory.blockedBy && (
                  <Box mb={"1rem"}>
                    <OfflineLabel blockedBy={documentCategory?.blockedBy} />
                  </Box>
                )}
                <Flex gap="0.6rem">
                  <Flex
                    flexShrink={0}
                    height="2.4rem"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Badge
                      color="#fff"
                      bgColor={isOnlySelectedCategory ? "#171717" : "#737373"}
                      fontSize="1.4rem"
                      fontWeight={500}
                      minWidth="4rem"
                      padding="0px 0.5rem"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="2rem"
                    >
                      {documentCategory.level}
                    </Badge>
                  </Flex>

                  <Flex
                    ref={documentCategoryTitleContainerRef}
                    flex={1}
                    pt="2px"
                    flexDir="column"
                    gap=".8rem"
                  >
                    <Tooltip
                      placement="top-start"
                      isDisabled={!isShowTooltip}
                      label={documentCategory.title}
                    >
                      <Text
                        ref={onChangeTextRef}
                        width="fit-content"
                        noOfLines={1}
                        marginTop="-3px"
                        height="2.4rem"
                        lineHeight="2.4rem"
                        fontSize="1.6rem"
                        color={isOnlySelectedCategory ? "#171717" : "#737373"}
                        fontWeight={isOnlySelectedCategory ? 700 : 500}
                      >
                        {documentCategory.title}
                      </Text>
                    </Tooltip>
                    <DocumentCategoryProgress
                      documentCategorySelected={documentCategory}
                    />
                  </Flex>

                  <Flex flexShrink={0}>
                    <DocumentCategoryMenu
                      currentUserId={currentUserId}
                      isDisabled={!isOnline}
                      isBlockDocumentCategory={isBlockDocumentCategory}
                      documentCategory={documentCategory}
                      onSelect={() => onSelectUpdate(documentCategory)}
                      onDelete={() => onSelectDelete(documentCategory)}
                      onOpenOfflineModeTutorialModal={
                        onOpenOfflineModeTutorialModal
                      }
                    />
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <Box position="relative" ref={boxRenderListContainerRef}>
          {isOpen && children}
        </Box>
      </Box>
    </Box>
  );
}

const areEquals = (prevProps: any, nextProps: any) => {
  return isEqual(prevProps, nextProps);
};

export default React.memo(DocumentCategoryDropDown, areEquals);
