import { Flex, Spinner } from "@chakra-ui/react";
import ImageIcon from "components/icon/ImageIcon";
import {
  ComponentType,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMapPresignedUrl } from "redux/documentSlice";
import { RootState } from "redux/store";
import {
  base64toBlob,
  getPreSignUrl,
  imageUrlToBase64,
  isBase64,
} from "utils/file";

export enum PREFIX_CACHE_PATH {
  DOCS = "DOCS",
}
type ImageInfo = {
  [url: string]: string;
};

export const mapImageFetched: { [path: string]: ImageInfo } = {};
export const clearImageCached = (path: PREFIX_CACHE_PATH) => {
  delete mapImageFetched[path];
};

export function withPresignedUrl<T = any>(Component: ComponentType<T>) {
  return memo(
    (
      props: T & {
        src?: string;
        isOnline?: boolean;
        isConvertToBase64?: boolean;
        isCheckLoadingPresignedUrl?: boolean;
        boxLoadingWidth?: any;
        boxLoadingHeight?: any;
        boxLoadingBackgroundColor?: string;
        isPreviewImageOnNewTab?: boolean;
        isSkipPresigned?: boolean;
        fallbackSrc?: string;
        cachePath?: PREFIX_CACHE_PATH;
        onLoadedPresignedUrl?: (url: string) => void;
      }
    ) => {
      const {
        src = "",
        isConvertToBase64 = false,
        isCheckLoadingPresignedUrl = true,
        boxLoadingWidth = 0,
        boxLoadingHeight = 0,
        isOnline = true,
        isPreviewImageOnNewTab = false,
        isSkipPresigned = false,
        boxLoadingBackgroundColor,
        cachePath = "",
        fallbackSrc = "",
        onLoadedPresignedUrl,
        ...rest
      } = props;
      const [isLoadingPresignedUrl, setIsLoadingPresignedUrl] = useState(true);
      const [presignedUrl, setPresignedUrl] = useState<string>("");
      const { mapPresignedUrl } = useSelector(
        (state: RootState) => state.document
      );
      const srcPreviewRef = useRef<any>("");
      const dispatch = useDispatch();

      const getPresignedUrl = useCallback(async () => {
        try {
          if (!src && !fallbackSrc) {
            setIsLoadingPresignedUrl(false);

            return;
          }
          const cacheUrl = mapImageFetched[cachePath]?.[src];
          let img = cacheUrl || src || fallbackSrc || "";
          if (isBase64(img) || isSkipPresigned || !src) {
            setIsLoadingPresignedUrl(false);
            setPresignedUrl(img);
            onLoadedPresignedUrl?.(img);

            return;
          }

          setIsLoadingPresignedUrl(true);
          if (src && !src.includes("X-Amz-SignedHeaders=host")) {
            if (mapPresignedUrl[`${src}`]) {
              img = mapPresignedUrl[`${src}`];
            } else {
              img = await getPreSignUrl(src, "");
              dispatch(addMapPresignedUrl({ url: src, presigned: img }));
            }
          }

          if (isConvertToBase64 && !img?.includes(".mp4")) {
            img = (await imageUrlToBase64(img)) as string;
            if (cachePath) {
              mapImageFetched[cachePath] ??= {};
              mapImageFetched[cachePath][src] = img;
            }
          }

          if (isPreviewImageOnNewTab) {
            srcPreviewRef.current = base64toBlob(
              (await imageUrlToBase64(img)) as string
            );
          }

          setPresignedUrl(img);
          onLoadedPresignedUrl?.(img);
          setIsLoadingPresignedUrl(false);
        } catch (err) {
          setIsLoadingPresignedUrl(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        src,
        fallbackSrc,
        isOnline,
        isSkipPresigned,
        isPreviewImageOnNewTab,
        cachePath,
      ]);

      useEffect(() => {
        getPresignedUrl();
      }, [getPresignedUrl]);

      const loadingElm = useMemo(() => {
        return (
          <Flex
            position="relative"
            zIndex="overlay"
            backgroundColor={`${boxLoadingBackgroundColor ?? "initial"}`}
            alignItems="center"
            justifyContent="center"
            width={boxLoadingWidth}
            height={boxLoadingHeight}
          >
            <Spinner
              display={
                !!boxLoadingWidth && !!boxLoadingHeight ? "initial" : "none"
              }
              color="blue.500"
            />
          </Flex>
        );
      }, [boxLoadingBackgroundColor, boxLoadingHeight, boxLoadingWidth]);

      if (isLoadingPresignedUrl && isCheckLoadingPresignedUrl) {
        return loadingElm;
      }
      if (!presignedUrl) {
        return <></>;
      }

      const onPreviewImageOnNewTab = () => {
        window.open(URL.createObjectURL(srcPreviewRef.current), "_blank");
        URL.revokeObjectURL(srcPreviewRef.current);
      };

      return (
        <>
          <Component
            {...(rest as any)}
            src={presignedUrl}
            crossOrigin="anonymous"
            {...(isPreviewImageOnNewTab && {
              onClick: onPreviewImageOnNewTab,
            })}
          />
        </>
      );
    }
  );
}
