import { Box, BoxProps, Flex, FlexProps } from "@chakra-ui/react";
import { message } from "components/base";
import useScissorsTitleViewerPropertyPanel from "hooks/useScissorsTitleViewerPropertyPanel";
import { isEqual } from "lodash-es";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsInitialized,
  setIsLoadedExternalId,
  setIsLoadedSheetTransformRatio,
  setIsLoadedViewer,
  setIsLoadedViewerModelData,
  setTimeReInit,
} from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { debounce, sleep } from "utils/common";

import {
  getForgeToken,
  overwriteHandleKeyDownFunction,
  setCameraToFrontTopRight,
  setCameraToTop,
} from "utils/forge";

import { getAreaExtension } from "utils/forge/extensions/area-extension";
import { getLabelExtension } from "utils/forge/extensions/custom-label";
import { getThemingColor } from "utils/forge/extensions/custom-label/utils";
import { setSheetTransformMatrix, setViewer2d } from "utils/forge/forge2d";
import { setViewer3d } from "utils/forge/forge3d";
import { logDev, logError } from "utils/logs";

import {
  CustomEventType,
  FORGE_BACKGROUND_COLOR,
  VIEW_ROLE,
} from "constants/forge";
import { transformDbIdForTasks } from "redux/taskSlice";
import {
  handleSetMapDbIdAndExternalId,
  setMapExternalId,
} from "utils/forge/viewerData";

export interface Props extends BoxProps {
  urn: string;
  guid?: string;
  isMasterView?: boolean;
  height?: string | number;
  isLoadByModel?: boolean;
  extensions?: any;
  forceHighLight?: boolean;
  isLoadBySvf2?: boolean;
  updatingBoundingTime?: number;
  isLoad2dView?: boolean;
  isHideCube?: boolean;
  isZoomOnLoad2d?: boolean;
  isResetSheetTransformRatio?: boolean;
  currentViewName?: string;
  loadedViewNameProps?: FlexProps;
  // If the role is different, it will reload forge viewer.
  role?: VIEW_ROLE;
}

function ForgeViewer({
  urn,
  guid,
  height,
  isMasterView,
  isLoadByModel,
  extensions,
  forceHighLight = false,
  isLoadBySvf2 = false,
  isLoad2dView = false,
  isZoomOnLoad2d = true,
  isResetSheetTransformRatio = true,
  currentViewName = "",
  updatingBoundingTime,
  isHideCube,
  loadedViewNameProps,
  role,
  ...boxProps
}: Props) {
  const {
    isLoadedViewerModelData,
    levelSelected,
    isDownloadPdfOnMobile,
    isCreateTask,
    isCreateDocumentItem,
    isMoveTaskLabel,
    isCreateSelfInspectionTask,
    isGeneratingFamilyInstances,
    isGeneratingAllFamilyInstance,
    isGeneratingSpaces,
    isInitialized,
    timeReInit,
  } = useSelector((state: RootState) => state.forgeViewer);

  const viewerRef = useRef<Autodesk.Viewing.GuiViewer3D>();
  const [viewerDocument, setviewerDocument] =
    useState<Autodesk.Viewing.Document | null>(null);
  const [isLoadedGeom, setIsLoadedGeom] = useState<boolean>();
  const [isLoadedObjectTree, setIsLoadedObjectTree] = useState<boolean>();
  const viewerDomRef = useRef<HTMLDivElement>(null);
  const isDownloadPdfOnMobileBeforeRef = useRef(false);
  const objectHoveredRef = useRef<any>(null);
  const dispatch = useDispatch();

  const [loadedViewName, setLoadedViewName] = useState("");

  useEffect(() => {
    if (isLoadedViewerModelData) {
      setLoadedViewName(currentViewName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedViewerModelData]);

  useScissorsTitleViewerPropertyPanel();

  const onGeometryLoadedEvent = useCallback(async () => {
    setIsLoadedGeom(true);
    viewerRef.current?.setBackgroundColor(...FORGE_BACKGROUND_COLOR);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCameraEvent = useCallback(async () => {
    if (isLoadByModel) {
      viewerRef.current?.navigation?.setReverseZoomDirection(true);
    }
  }, [isLoadByModel]);

  const onObjectTreeCreatedEvent = useCallback(() => {
    setIsLoadedObjectTree(true);
  }, []);

  useEffect(() => {
    if (
      !isLoadedViewerModelData ||
      isGeneratingFamilyInstances ||
      isGeneratingAllFamilyInstance ||
      isGeneratingSpaces
    ) {
      dispatch(setIsLoadedExternalId(false));

      return setMapExternalId({});
    }
    (async () => {
      const result = await handleSetMapDbIdAndExternalId();
      if (result) {
        dispatch(setIsLoadedExternalId(true));
        dispatch(transformDbIdForTasks());
        logDev("load external id finish");
      } else {
        message.error("ExternalIdを取得できないです。");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadedViewerModelData,
    isGeneratingFamilyInstances,
    isGeneratingAllFamilyInstance,
    isGeneratingSpaces,
  ]);

  const transformExtension = useCallback(() => {
    const extensionsToLoad = extensions;
    const extensionsWithConfig = [];
    const extensionsWithoutConfig = ["Autodesk.AEC.LevelsExtension"];

    for (const key in extensionsToLoad) {
      if (extensionsToLoad[key].register) {
        extensionsToLoad[key].register();
      }

      const config = extensionsToLoad[key].options || {};
      if (Object.keys(config).length === 0) {
        extensionsWithoutConfig.push(key);
      } else {
        extensionsWithConfig.push(key);
      }
    }

    return {
      extensionsWithoutConfig,
      extensionsWithConfig,
      extensionsToLoad,
    };
  }, [extensions]);

  const initializeViewer = useCallback(async () => {
    dispatch(setIsInitialized(false));

    if (!viewerDomRef.current) {
      return;
    }

    const loadOptions = isLoadBySvf2
      ? {
          env: "AutodeskProduction2",
          api: "streamingV2",
        }
      : {
          env: "AutodeskProduction",
          api: "derivativeV2",
        };

    const options = {
      language: "ja",
      ...loadOptions,
      // automatically renew token
      getAccessToken: async function (onTokenReady: any) {
        const tokenEp = await getForgeToken().catch(() => undefined);
        if (tokenEp) {
          onTokenReady(tokenEp.accessToken, tokenEp.expiresIn);
        } else {
          throw new Error("Get forge's token failed");
        }
      },
    };
    Autodesk.Viewing.Initializer(options, async function () {
      const {
        extensionsToLoad,
        extensionsWithoutConfig,
        extensionsWithConfig,
      } = transformExtension();

      const viewerConfig: Autodesk.Viewing.Viewer3DConfig = {
        extensions: extensionsWithoutConfig,
        disabledExtensions: {
          bimwalk: true,
          hyperlink: true,
          scalarisSimulation: true,
          measure: true,
          explode: true,
          layermanage: true,
          fusionOrbit: true,
        },
      };

      if (isMobile) {
        viewerConfig.loaderExtensions = { svf: "Autodesk.MemoryLimited" };
        viewerConfig.memory = {
          limit: 1024, //mb, It's only works for 3D view,
        };
      }

      const viewer = new Autodesk.Viewing.GuiViewer3D(
        viewerDomRef.current!,
        viewerConfig
      );
      if (!viewer) {
        return;
      }
      try {
        viewer?.start();
      } catch (err) {
        await sleep(2000);
        dispatch(setTimeReInit(Date.now()));

        return;
      }

      viewer.setProgressiveRendering(true);
      viewer.setQualityLevel(true, false);
      viewer.setGroundShadow(false);
      viewer.prefs?.set("ghosting", false);
      viewer.prefs?.set("optimizeNavigation", true);
      extensionsWithConfig.forEach((ext) => {
        viewer.loadExtension(ext, extensionsToLoad[ext].options);
      });
      (viewer.navigation as any).FIT_TO_VIEW_VERTICAL_MARGIN = 0;
      (viewer.navigation as any).FIT_TO_VIEW_HORIZONTAL_MARGIN = 0;
      viewerRef.current = viewer;
      dispatch(setIsInitialized(true));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformExtension, dispatch, isLoadBySvf2, role]);

  useEffect(() => {
    const value = Boolean(isLoadedGeom && isLoadedObjectTree);
    if (isLoadedViewerModelData !== value) {
      dispatch(setIsLoadedViewerModelData(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedGeom, isLoadedObjectTree]);

  // load document
  useEffect(() => {
    if (!isInitialized || !urn) {
      return;
    }
    Autodesk.Viewing.Document.load(
      `urn:${urn.replace("/", "_")}`,
      (viewerDocument) => {
        setviewerDocument(viewerDocument);
      },
      () => {
        logDev("Failed fetching Forge manifest");
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized]);

  const cleanUp = useCallback(() => {
    const viewer = viewerRef.current;

    getAreaExtension()?.clear(false);
    getLabelExtension()?.clear();
    try {
      if (viewer) {
        viewer.removeEventListener(
          Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
          onGeometryLoadedEvent
        );
        viewer.removeEventListener(
          Autodesk.Viewing.CAMERA_CHANGE_EVENT,
          onChangeCameraEvent
        );

        viewer.removeEventListener(
          Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
          onObjectTreeCreatedEvent
        );
        if (viewer?.impl?.selector) {
          viewer?.tearDown();
          viewer?.finish();
        }
        const models = viewer.getAllModels();
        models?.forEach((m) => viewer.unloadModel(m));
      }
    } catch {}

    setSheetTransformMatrix(undefined);
    if (isResetSheetTransformRatio) {
      dispatch(setIsLoadedSheetTransformRatio(false));
    }
    setViewer2d(undefined);
    setViewer3d(undefined);
    Autodesk.Viewing.shutdown();
    dispatch(setIsLoadedViewer(false));
    dispatch(setIsInitialized(false));
    setviewerDocument(null);
    setIsLoadedGeom(false);
    setIsLoadedObjectTree(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isResetSheetTransformRatio,
    onGeometryLoadedEvent,
    onObjectTreeCreatedEvent,
  ]);

  const loadModel = useCallback(async () => {
    const viewer = viewerRef.current;
    dispatch(setIsLoadedViewer(false));
    if (!viewerDocument || !viewer) {
      return;
    }

    viewer.addEventListener(
      Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
      onGeometryLoadedEvent
    );

    viewer.addEventListener(
      Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
      onObjectTreeCreatedEvent
    );
    viewer.addEventListener(
      Autodesk.Viewing.CAMERA_CHANGE_EVENT,
      onChangeCameraEvent
    );
    try {
      viewer.prefs?.set("viewCube", false);
      viewer.prefs?.set("viewCubeCompass", false);
      viewer?.setActiveNavigationTool("pan");
      viewer?.showModelStructurePanel(false);
    } catch (err) {
      logDev(err);
      dispatch(setTimeReInit(Date.now()));

      return;
    }
    const bubbleNode = viewerDocument.getRoot() as any;
    let viewableNode: any;
    if (guid) {
      viewableNode = bubbleNode.findByGuid(guid);
    }
    if (!!isMasterView) {
      const masterViews: any[] = bubbleNode.getMasterViews();
      viewableNode = masterViews.reduce((prev, cur) => {
        return !prev || (cur && cur.data.size > prev.data.size) ? cur : prev;
      }, undefined);
    }
    if (!viewableNode) {
      const models: any[] = bubbleNode.search(
        Autodesk.Viewing.BubbleNode.MODEL_NODE
      );

      viewableNode = models.find((model) => {
        const name = model.data.name;

        return (
          !(name.toUpperCase().includes("F") || name.includes("階")) &&
          (name.includes("全体") || name.toUpperCase().includes("ALL"))
        );
      });
      if (!viewableNode) {
        let maxSize = 0;
        viewableNode = models[0];
        models.forEach((model) => {
          if (model.data.size > maxSize && !!model.data.ViewSets) {
            maxSize = model.data.size;
            viewableNode = model;
          }
        });
      }
    }

    if (
      viewableNode &&
      isLoadByModel &&
      viewableNode.data.type !== "geometry"
    ) {
      while (viewableNode?.parent) {
        if (viewableNode.data.type === "geometry") {
          break;
        }
        viewableNode = viewableNode.parent;
      }
    }
    if (!viewableNode) {
      message.error("モデルを読み込めない");

      return;
    }

    const options: any = {
      //skipPropertyDb: !isMasterView,
      globalOffset: { x: 0, y: 0, z: 0 },
      // NOTE: If this option is set to false, unload will be executed implicitly (if set to true, models loaded later will be merged)
      keepCurrentModels: false,
    };
    if (viewableNode.data.role === "2d") {
      options.applyScaling = "mm";
    }

    const loadView = async () => {
      try {
        await sleep(100);
        logDev("begin load model");
        if (isLoadByModel && viewableNode.data.role !== VIEW_ROLE.ROLE_2D) {
          const url = viewerDocument.getViewablePath(viewableNode);
          //@ts-ignore
          options.acmSessionId = viewerDocument.getAcmSessionId(url);
          await viewer.loadModel(url, options);
        } else {
          await viewer.loadDocumentNode(viewerDocument, viewableNode, options);
        }
        // await viewer.waitForLoadDone();
        logDev("end load model");
        viewer.unloadExtension("Autodesk.PDF");
        viewer.unloadExtension("Autodesk.PDFLoader");
        viewer.unloadExtension("Autodesk.PDF2D");
        const models = viewer.getAllModels();
        if (models.length > 1) {
          cleanUp();
          initializeViewer();

          return;
        }

        if (viewableNode.data.role === "3d") {
          setCameraToFrontTopRight(viewer);
          setViewer3d(viewer);
          viewer.setActiveNavigationTool("orbit");
          viewer.prefs?.set("viewCube", !isHideCube);
          viewer.prefs?.set("viewCubeCompass", !isHideCube);
        } else {
          setCameraToTop(viewer, isZoomOnLoad2d);
          setViewer2d(viewer);
        }
        logDev(viewableNode.data.role === "3d", "loaded viewer");
        overwriteHandleKeyDownFunction(viewer);
        dispatch(setIsLoadedViewer(true));
      } catch (err) {
        logError(err);
        cleanUp();
        setTimeout(() => {
          initializeViewer();
        });
      }
    };

    loadView();
  }, [
    dispatch,
    onGeometryLoadedEvent,
    onObjectTreeCreatedEvent,
    onChangeCameraEvent,
    viewerDocument,
    guid,
    isMasterView,
    isLoadByModel,
    isZoomOnLoad2d,
    cleanUp,
    initializeViewer,
    isHideCube,
  ]);

  const handleUpdateBounding = useCallback(() => {
    if (!viewerRef.current || !viewerDomRef.current) {
      return;
    }
    //@ts-ignore
    if (!viewerRef.current.impl?.boundingClientRect) {
      return;
    }
    const bounding = viewerDomRef.current.getBoundingClientRect();
    //@ts-ignore
    viewerRef.current.impl.boundingClientRect = bounding;
  }, []);

  useEffect(() => {
    if (!viewerDomRef.current) {
      return;
    }
    document.addEventListener(
      CustomEventType.UpdateBoundingViewer,
      handleUpdateBounding
    );

    return () => {
      document.removeEventListener(
        CustomEventType.UpdateBoundingViewer,
        handleUpdateBounding
      );
    };
  }, [handleUpdateBounding]);

  useEffect(() => {
    handleUpdateBounding();
  }, [updatingBoundingTime, handleUpdateBounding]);

  const lastInitViewerRef = useRef<{
    urn?: string;
    isLoadBySvf2?: boolean;
    role?: VIEW_ROLE;
  }>({
    urn,
    isLoadBySvf2,
    role,
  });

  // load model
  useEffect(() => {
    if (!levelSelected || !viewerDocument) {
      return;
    }

    const paramLoadViewer = { urn, isLoadBySvf2, role };
    if (!isEqual(lastInitViewerRef.current, paramLoadViewer)) {
      return;
    }
    try {
      loadModel();
    } catch (err) {
      logError(err);
    }

    return () => {
      clearModel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    guid,
    urn,
    isLoadBySvf2,
    isLoadByModel,
    role,
    viewerDocument,
    levelSelected?.label,
  ]);

  // clear model
  const clearModel = useCallback(() => {
    const viewer = viewerRef.current;

    getAreaExtension()?.clear(false);
    getLabelExtension()?.clear();
    try {
      if (viewer) {
        viewer.removeEventListener(
          Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
          onGeometryLoadedEvent
        );

        viewer.removeEventListener(
          Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
          onObjectTreeCreatedEvent
        );
        viewer.removeEventListener(
          Autodesk.Viewing.CAMERA_CHANGE_EVENT,
          onChangeCameraEvent
        );

        const models = viewer.getAllModels();
        models?.forEach((m) => viewer.unloadModel(m));
      }
    } catch {}
    setViewer2d(undefined);
    setViewer3d(undefined);
    setIsLoadedGeom(false);
    setIsLoadedObjectTree(false);
    dispatch(setIsLoadedViewer(false));
    setSheetTransformMatrix(undefined);
    if (isResetSheetTransformRatio) {
      dispatch(setIsLoadedSheetTransformRatio(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetSheetTransformRatio]);

  // initialize viewer
  useEffect(() => {
    if (!urn || !viewerDomRef.current) {
      return;
    }
    const paramLoadViewer = { urn, isLoadBySvf2, role };
    cleanUp();
    setTimeout(initializeViewer);
    lastInitViewerRef.current = paramLoadViewer;

    return () => {
      cleanUp();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urn, isLoadBySvf2, role]);

  useEffect(() => {
    if (!urn || !viewerDomRef.current || !timeReInit) {
      return;
    }

    cleanUp();
    setTimeout(initializeViewer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeReInit]);

  // clean up
  useEffect(() => {
    return () => {
      cleanUp();
      //There are cases where the initEventlistener event cannot update the local state, so we need to use global state.
      dispatch(setTimeReInit(0));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // unload modal when download pdf on mobile
  useEffect(() => {
    if (isDownloadPdfOnMobile && isLoadedViewerModelData) {
      isDownloadPdfOnMobileBeforeRef.current = true;
      clearModel();
    }

    if (
      !isDownloadPdfOnMobile &&
      !isLoadedViewerModelData &&
      isDownloadPdfOnMobileBeforeRef.current
    ) {
      isDownloadPdfOnMobileBeforeRef.current = false;
      loadModel();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadPdfOnMobile, isLoadedViewerModelData]);

  // resize viewer
  useEffect(() => {
    const viewer = viewerRef.current;

    if (!viewer || !isInitialized || !viewer.canvasWrap) {
      return;
    }
    const onResize = debounce(() => {
      if (viewer?.container) {
        viewer?.resize();
        viewer?.impl?.invalidate(true);
        handleUpdateBounding();
      }
    }, 100);

    const observer = new ResizeObserver(onResize);
    observer.observe(viewer.canvasWrap);

    return () => {
      if (!viewer) {
        return;
      }
      observer.disconnect();
    };
  }, [isInitialized, handleUpdateBounding]);

  const resetObjectWithHovered = ({
    dbId,
    originalColor,
    action,
    viewer,
  }: {
    viewer: Autodesk.Viewing.GuiViewer3D;
    dbId: number;
    originalColor: THREE.Vector4;
    action: "move" | "create";
  }) => {
    try {
      if (action === "move") {
        viewer?.clearThemingColors?.(viewer.model);
      } else {
        viewer?.setThemingColor?.(dbId, originalColor);
      }
    } catch (err) {}
  };

  useEffect(() => {
    const viewer = viewerRef.current;

    if (!viewer) {
      return;
    }
    let timeoutId = null as any;

    const handleObjectChange = (event: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
      if (
        !isCreateDocumentItem &&
        !isCreateTask &&
        !isMoveTaskLabel &&
        !isCreateSelfInspectionTask &&
        !forceHighLight
      ) {
        return;
      }
      timeoutId = setTimeout(() => {
        const { dbId: dbIdHovered, originalColor: originalColorHovered } =
          objectHoveredRef.current || {};

        if (event.dbId !== -1 && event.dbId) {
          // If the hovered object is the same as the previous one, do nothing

          if (event.dbId === dbIdHovered) {
            return;
          }

          // If the hovered object is different from the previous one, reset the color of the previous object
          if (objectHoveredRef.current) {
            viewer.setThemingColor(dbIdHovered, originalColorHovered);
          }
          objectHoveredRef.current = {
            dbId: event.dbId,
            originalColor: getThemingColor(viewer, event.dbId),
            action: isMoveTaskLabel ? "move" : "create",
          };

          const hoverColor = new THREE.Vector4(
            220 / 255,
            202 / 255,
            38 / 255,
            1
          ); // Set the color to #DCCA26 when hovering
          viewer.setThemingColor(event.dbId, hoverColor);
        } else if (objectHoveredRef.current) {
          // If the hovered object is null, save the color of the previous object and reset the color of the previous object
          viewer.setThemingColor(dbIdHovered, originalColorHovered);
          objectHoveredRef.current = null;
        }
      }, 200);
    };

    viewer.addEventListener(
      Autodesk.Viewing.OBJECT_UNDER_MOUSE_CHANGED,
      handleObjectChange
    );

    // reset color when unmount
    if (objectHoveredRef.current) {
      resetObjectWithHovered({ ...objectHoveredRef.current, viewer });
    }

    return () => {
      // reset color when unmount
      if (objectHoveredRef.current) {
        resetObjectWithHovered({ ...objectHoveredRef.current, viewer });
      }
      objectHoveredRef.current = null;
      viewer.removeEventListener(
        Autodesk.Viewing.OBJECT_UNDER_MOUSE_CHANGED,
        handleObjectChange
      );
    };
  }, [
    isCreateDocumentItem,
    viewerDocument,
    isCreateTask,
    isMoveTaskLabel,
    isCreateSelfInspectionTask,
    forceHighLight,
  ]);

  return (
    <Box
      id="forge-viewer"
      className="forge-viewer"
      ref={viewerDomRef}
      h={height ?? "calc(var(--app-height) - var(--header-height))"}
      position="relative"
      display="block"
      w="100%"
      sx={{
        ".adsk-viewing-viewer": {
          "#guiviewer3d-toolbar": {
            display: "none",
          },
        },

        ".canvas-wrap": {
          height: "100%",
        },
        ".canvas-wrap > canvas": {
          minWidth: "100%",
        },
      }}
      {...boxProps}
    >
      <Flex
        position="absolute"
        bottom="1rem"
        left="1rem"
        zIndex={99}
        maxW="42rem"
        fontSize="9px"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        display={loadedViewName ? "inherit" : "none"}
        padding="0.5rem"
        background="#dadada"
        alignItems="center"
        {...loadedViewNameProps}
      >
        {loadedViewName}
      </Flex>
    </Box>
  );
}

export default memo(ForgeViewer);
