import { useCallback, useRef } from "react";
import { setFieldAtrributes } from "../context/FormStateContext/actions";
import {
  FormStateActions,
  FormStateState,
} from "../context/FormStateContext/types";
import { updatePinContent } from "apiClient/v2/inspection/pinApi";
import { setFieldValue } from "../context/FormStateContext/actions";

interface Option {
  dispatch: React.Dispatch<FormStateActions>;
  state: FormStateState;
  inspectionTypeId: string;
}

const useFormHandler = ({ state, dispatch, inspectionTypeId }: Option) => {
  const formValueRef = useRef(state.formValue);
  formValueRef.current = state.formValue;

  const saveFieldValue = useCallback(
    async (itemResultId: string, value: any) => {
      const prevValue = formValueRef.current?.[itemResultId] ?? "";
      if (!state.pinId || prevValue === value) return;
      dispatch(setFieldAtrributes({ itemResultId, isLoading: true }));
      const result = await updatePinContent({
        itemResultId,
        value: { data: value ?? "" },
        pinId: state.pinId,
        inspectionTypeId,
      });
      dispatch(setFieldAtrributes({ itemResultId, isLoading: false }));
      if (result?.data) {
        dispatch(setFieldValue({ itemResultId, value }));

        return true;
      }

      return false;
    },
    [dispatch, inspectionTypeId, state.pinId]
  );

  return {
    saveFieldValue,
  };
};

export default useFormHandler;
