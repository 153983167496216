import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import { DEFAULT_WIDTH_HEADER_TABLE } from "constants/document";
import { TemplateComponentProps, TemplateComponentType } from "constants/enum";
import { TemplateComponent } from "interfaces/models/component";
import { isEqual } from "lodash-es";
import { getHeaderComponentElementId } from "models/document";
import { DEFAULT_BORDER_COLOR_ACTIVE } from "pages/document/template-page/hooks";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setComponentSelected,
  setIsComponentDragging,
  setSelectedCell,
} from "redux/documentSlice";
import { RootState } from "redux/store";
import useComponent from "./hooks/useComponent";

interface Props extends FlexProps {
  component: TemplateComponent;
  components: TemplateComponent[];
  isSelectComponentOnly?: boolean;
}

export default function HeaderCommon({
  component,
  components,
  isSelectComponentOnly,
  ...props
}: Props) {
  const dispatch = useDispatch();
  const { isComponentDragging, componentSelected, selectedCells } = useSelector(
    (state: RootState) => state.document
  );

  const { deleteComponent } = useComponent();
  const isComponentDraggingRef = useRef(isComponentDragging);
  const componentSelectedRef = useRef<TemplateComponent>();

  useEffect(() => {
    isComponentDraggingRef.current = isComponentDragging;
  }, [isComponentDragging]);

  useEffect(() => {
    componentSelectedRef.current = componentSelected;
  }, [componentSelected]);

  const handleDeleteComponent = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    deleteComponent(component);
  };

  const handleOnTitleMouseDown = () => {
    window.addEventListener("mouseup", handleOnMouseUp);

    if (selectedCells?.length) {
      dispatch(setSelectedCell([]));
    }
    if (!isEqual(component, componentSelectedRef.current)) {
      dispatch(setComponentSelected(component));
    }
  };

  const handleOnMouseUp = useCallback(() => {
    if (!isComponentDragging || !isComponentDraggingRef.current) {
      return;
    }

    isComponentDraggingRef.current = false;
    dispatch(setIsComponentDragging(false));
  }, [isComponentDragging, dispatch]);

  const removeListeners = useCallback(() => {
    window.removeEventListener("mouseup", handleOnMouseUp);
  }, [handleOnMouseUp]);

  useEffect(() => {
    isComponentDraggingRef.current = isComponentDragging;
    removeListeners();
  }, [isComponentDragging, removeListeners]);

  return (
    <Flex
      id={getHeaderComponentElementId(component?.componentId)}
      h="2.5rem"
      p="0.5rem"
      overflow="hidden"
      cursor="pointer"
      maxW="100%"
      bg={
        isSelectComponentOnly
          ? DEFAULT_BORDER_COLOR_ACTIVE
          : component.detail?.style?.backgroundColor || "#fff"
      }
      color={
        isSelectComponentOnly
          ? "#fff"
          : component?.detail?.style?.color || "#fff"
      }
      borderStyle="solid"
      borderColor={
        isSelectComponentOnly
          ? DEFAULT_BORDER_COLOR_ACTIVE
          : component?.detail?.style?.borderColor
      }
      borderTopRadius={4}
      position="absolute"
      alignItems="center"
      top="-2.5rem"
      left="0"
      minW={`${DEFAULT_WIDTH_HEADER_TABLE}px`}
      zIndex={99}
      onMouseDown={handleOnTitleMouseDown}
      style={{
        borderWidth:
          component.size.width > DEFAULT_WIDTH_HEADER_TABLE
            ? "1px 1px 0px 1px"
            : "1px",
      }}
      {...props}
    >
      <Flex h="100%" overflow="hidden" alignItems="center" gap="0.5rem">
        <SvgIcon
          src={
            component?.isCustomComponent
              ? "/img/icon-custom-component.svg"
              : TemplateComponentProps?.[
                  (component.type as keyof typeof TemplateComponentProps) ||
                    TemplateComponentType.Table
                ]?.iconSrc
          }
          w="1.4rem"
          h="1.4rem"
          sx={{
            rect: {
              stroke: isSelectComponentOnly
                ? "#fff"
                : component.detail?.style?.borderColor,
            },
            path: {
              stroke: isSelectComponentOnly
                ? "#fff"
                : component.detail?.style?.borderColor,
              ...(component?.isCustomComponent
                ? {
                    fill: isSelectComponentOnly
                      ? "#fff"
                      : component.detail?.style?.borderColor,
                  }
                : {}),
            },
          }}
        />
        <Text
          style={{
            color: isSelectComponentOnly
              ? "#fff"
              : component.detail?.style?.borderColor,
            fontSize: "1.2rem",
            paddingRight: "0.2rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
            wordBreak: "break-word",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
          }}
        >
          {
            TemplateComponentProps[
              component.type as keyof typeof TemplateComponentProps
            ]?.name
          }
        </Text>
      </Flex>

      <SvgIcon
        src={"/img/editor-builder/delete.svg"}
        w="1.4rem"
        h="1.4rem"
        ml="0.5rem"
        fill="transparent"
        stroke={
          isSelectComponentOnly ? "#fff" : component.detail?.style?.borderColor
        }
        cursor="pointer"
        onClick={handleDeleteComponent}
      />
    </Flex>
  );
}
