import { Button, Flex, Input, Text } from "@chakra-ui/react";
import { FieldPreviewProps } from "../../types";
import { SOURCE } from "components/containers/InspectionFormat/Content/SettingPinLayoutFrame/context/pinLayoutFrameReducer";
import { DroppableBox } from "components/containers/InspectionFormat/containers/LayoutFrameBuilder";
import FieldDevicePreviewWrapper from "../../../components/FieldDevicePreviewWrapper";
import { useCallback } from "react";
import Peripheral, { PressureResponse } from "libs/peripheral/Peripheral";
import { logDev } from "utils/logs";
import CheckboxIcon from "components/icon/CheckboxIcon";

export enum PressureOptKey {
  START_DATE = "startDate",
  START_VALUE = "startValue",
  END_DATE = "endDate",
  END_VALUE = "endValue",
}

export const pressureOptions = [
  { value: PressureOptKey.START_DATE, label: "開始時刻" },
  { value: PressureOptKey.START_VALUE, label: "開始値" },
  { value: PressureOptKey.END_DATE, label: "終了時刻" },
  { value: PressureOptKey.END_VALUE, label: "終了値" },
];

interface DevicePressurePreviewerProps
  extends FieldPreviewProps<PressureResponse> {}

const DevicePressurePreviewer: React.FC<DevicePressurePreviewerProps> = ({
  item,
  value,
  isReadOnly,
  onChange,
  isLoading,
  isDraggable,
}) => {
  const onClickRequest = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      if (isReadOnly) return;
      try {
        const result = await Peripheral.pressure();
        onChange?.(result);
      } catch (error) {
        /** Ignore */
        logDev(error);
      }
    },
    [isReadOnly, onChange]
  );

  if (isDraggable) {
    return (
      <FieldDevicePreviewWrapper title={item.name} data-type={item.dataType}>
        <Flex display={"inline-flex"} flexDirection="column" gap="1rem">
          {pressureOptions.map(({ value, label }) => {
            return (
              <DroppableBox
                key={value}
                name={label}
                icon={<CheckboxIcon w="3rem" h="3rem" />}
                id={item.id}
                extraId={value}
                source={SOURCE.INSPECTION_FORM}
              >
                <Input
                  className="draggable-area"
                  isReadOnly={isReadOnly}
                  backgroundColor="white"
                  height="4rem"
                  maxWidth="100%"
                  placeholder={label}
                />
              </DroppableBox>
            );
          })}
        </Flex>
      </FieldDevicePreviewWrapper>
    );
  }

  return (
    <FieldDevicePreviewWrapper title={item.name}>
      <Flex alignItems={"center"} gap="1rem" mb="0.5rem">
        <Text>みるみるくん 圧力試験器III</Text>
        <Button
          onClick={onClickRequest}
          variant="filled"
          isDisabled={isLoading}
        >
          機器連携
        </Button>
      </Flex>
      <Flex position={"relative"} flexDir={"column"} gap="0.5rem">
        {pressureOptions.map((option) => {
          return (
            <Input
              key={option.value}
              isReadOnly
              backgroundColor="#f2f2f2"
              height="4rem"
              cursor={"not-allowed"}
              maxWidth="100%"
              width="100%"
              placeholder={option.label}
              fontSize={"1.6rem"}
              color="#171717"
              value={value?.[option.value] ?? ""}
              paddingRight={isLoading ? "3rem" : "1rem"}
            />
          );
        })}
      </Flex>
    </FieldDevicePreviewWrapper>
  );
};

export default DevicePressurePreviewer;
