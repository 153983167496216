import { Box, BoxProps, Flex, Stack, Text } from "@chakra-ui/react";
import { memo } from "react";

interface Props {
  title?: any;
  children?: React.ReactNode;
}

const FieldPreviewWrapper = (props: Props & Omit<BoxProps, "title">) => {
  const { title, children, ...rest } = props;

  return (
    <Stack gap="0.5rem" {...rest}>
      <Flex
        alignItems={"center"}
        gap={"0.5rem"}
        fontWeight="bold"
        fontSize={"1.6rem"}
        className="field-title"
        position={"relative"}
      >
        <Box flex="0 0 8px" w="8px" h="8px" bg="#525252" />
        <Text as="span">{title}</Text>
      </Flex>
      <Box className="field-wrapper">{children}</Box>
    </Stack>
  );
};

export default memo(FieldPreviewWrapper);
