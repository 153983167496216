import { DAYS_ABOUT_TO_EXPIRE } from "components/widgets/DashBoard/hooks/useTask";
import { MenuOption } from "components/widgets/DashBoard/components/SelectTag";
import { flattenDeep, isEqual, isEmpty } from "lodash-es";
import { getBimFileInfo } from "./bim";
import { DateRange, formatDate, getDaysFromNow } from "./date";
import { compareArray } from "./array";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import dayjs, { ConfigType } from "dayjs";
import { DeadlineType, FilterInputType, SetImageType } from "constants/enum";
import { FilterFormI } from "interfaces/models/dashboard";
import { listCustomTags, listOfficeTags, listSystemTags } from "constants/task";

const ALL_LABEL = "全て";

export const getFilterFields = (
  filteredForm: FilterFormI,
  levelOptions?: MenuOption[],
  statusOptions?: MenuOption[],
  familyPropertyOptions?: MenuOption[],
  projectOptions?: MenuOption[],
  partnerCompanyOptions?: MenuOption[],
  families?: string[]
) => {
  return Object.keys(filteredForm)
    ?.filter((key) => {
      const currentField = filteredForm?.[key as keyof FilterFormI];

      return !isEmpty(currentField?.value);
    })
    ?.map((key) => {
      let value = "";

      const currentField = filteredForm?.[key as keyof FilterFormI];

      switch (currentField?.inputType) {
        case FilterInputType.MANAGER: {
          value = currentField?.value?.name;

          break;
        }

        case FilterInputType.FLOORS: {
          value = compareArray(currentField?.value, levelOptions || [])
            ? ALL_LABEL
            : currentField?.value?.map((item: any) => item?.name)?.join(", ");

          break;
        }

        case FilterInputType.PARTNER_COMPANY: {
          value = compareArray(currentField?.value, partnerCompanyOptions || [])
            ? ALL_LABEL
            : currentField?.value?.map((item: any) => item?.name)?.join(", ");

          break;
        }

        case FilterInputType.STATUS: {
          value = compareArray(currentField?.value, statusOptions || [])
            ? ALL_LABEL
            : currentField?.value?.map((item: any) => item?.name)?.join(", ");

          break;
        }

        case FilterInputType.FAMILY: {
          value = compareArray(currentField?.value, familyPropertyOptions || [])
            ? ALL_LABEL
            : currentField?.value?.map((item: any) => item?.name)?.join(", ");

          break;
        }

        case FilterInputType.TAGS: {
          const allTags = flattenDeep([
            listCustomTags.map((item) => item.value),
            listOfficeTags.map((item) => item.value),
            listSystemTags.map((item) => item.value),
            families || [],
          ]);
          value = compareArray(currentField?.value, allTags)
            ? ALL_LABEL
            : currentField?.value?.map((item: any) => item)?.join(", ");

          break;
        }

        case FilterInputType.PROJECTS: {
          value = compareArray(currentField?.value, projectOptions || [])
            ? ALL_LABEL
            : currentField?.value?.map((item: any) => item?.name)?.join(", ");

          break;
        }

        case FilterInputType.IMAGES: {
          value = compareArray(Object.values(SetImageType), currentField?.value)
            ? ALL_LABEL
            : currentField?.value?.map((item: any) => item)?.join(", ");

          break;
        }

        default: {
          value = `${formatDate(currentField?.value?.startDate)}${
            currentField?.value?.startDate || currentField?.value?.endDate
              ? ` - `
              : ""
          }${formatDate(currentField?.value?.endDate)}`;
        }
      }

      return {
        label: currentField?.label,
        value,
      };
    })
    .filter((item) => item.value !== ALL_LABEL && item.value);
};

export const getIntersetedItems = (...listArray: Array<any>[]) => {
  const intersectedItems: Array<any> = [];

  const mergedItems = flattenDeep(listArray);

  mergedItems.forEach((itemInMergedItems) => {
    const isEveryListArrayContainsOption = listArray?.every((array) =>
      array?.some((itemInArray) => isEqual(itemInArray, itemInMergedItems))
    );

    const isIntersectedItemsContainsItem = intersectedItems?.some((item) =>
      isEqual(item, itemInMergedItems)
    );

    if (isEveryListArrayContainsOption && !isIntersectedItemsContainsItem) {
      intersectedItems.push(itemInMergedItems);

      return;
    }
  });

  return intersectedItems;
};

export const getProjectByBimFileId = (
  bimFileId: string,
  projects: DataProjectModel[]
) => {
  return projects?.find((project) => {
    const urn = project?.defaultBimPathId?.split("/").pop() as string;

    const { bimFileId: bimFileIdOfProject } = getBimFileInfo(urn);

    return bimFileIdOfProject === bimFileId;
  });
};

export const getDeadlineType = (date: Date | string) => {
  const diffDays = getDaysFromNow(new Date(date)) as number;

  if (diffDays > 0 && !!diffDays && date) {
    return DeadlineType.expired;
  }

  if (diffDays <= 0 && diffDays > -DAYS_ABOUT_TO_EXPIRE && date) {
    return DeadlineType.aboutToExpire;
  }

  return DeadlineType.notExpired;
};

export const getDateRange = (...dateRanges: DateRange[]) => {
  let startDate: ConfigType;
  let endDate: ConfigType;

  if (dateRanges?.every((item) => !item?.startDate)) {
    startDate = null;
  } else {
    startDate = dateRanges.find((item) => !!item?.startDate)?.startDate;

    dateRanges.forEach((item) => {
      if (
        dayjs(item?.startDate).isBefore(dayjs(startDate), "day") &&
        item?.startDate
      ) {
        startDate = item?.startDate;

        return;
      }
    });
  }

  if (dateRanges?.every((item) => !item?.endDate)) {
    endDate = null;
  } else {
    endDate = dateRanges.find((item) => !!item?.endDate)?.endDate;

    dateRanges.forEach((item) => {
      if (
        dayjs(item?.endDate).isAfter(dayjs(endDate), "day") &&
        item?.endDate
      ) {
        endDate = item?.endDate;

        return;
      }
    });
  }

  return {
    startDate,
    endDate,
  };
};
