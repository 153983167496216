import { Flex, Img } from "@chakra-ui/react";
import { Attribute, Node } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { CustomNodeComponentProps, NodeType } from "../type";
import ImageIcon from "components/icon/ImageIcon";

const ImgPresigned = withPresignedUrl(Img);

export interface ImageNodeAttrs {
  src: string;
}

export const ImageNode = Node.create({
  name: NodeType.IMAGE,
  group: "block",
  // fence the cursor for regular editing operations
  // [https://tiptap.dev/docs/editor/core-concepts/schema#isolating]
  selectable: false,

  addAttributes() {
    const attrs: {
      [key in keyof ImageNodeAttrs]: Attribute;
    } = {
      src: { default: null },
    };

    return attrs;
  },
  parseHTML() {
    return [
      {
        tag: "image",
      },
    ];
  },
  renderHTML() {
    return ["image", {}, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});

const Component = (props: CustomNodeComponentProps<ImageNodeAttrs>) => {
  const { src } = props.node.attrs;
  const editorAttributes = props.editor.options.editorProps?.attributes as any;
  const isPreview = editorAttributes?.isPreview === "true";

  return (
    <NodeViewWrapper
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        cursor: "default",
        pointerEvents: "auto",
        position: "relative",
      }}
      className={src ? "wrapper-image-node" : undefined}
      onClick={() => {
        props.editor.commands.blur();
      }}
    >
      {src && !isPreview && (
        <Flex
          bg="rgba(221, 206, 110, 0.6)"
          w="4rem"
          h="4rem"
          color="white"
          position="absolute"
          right="0"
          top="0"
          cursor="pointer"
          className="image-thumb-icon"
          alignItems="center"
          justifyContent="center"
        >
          <ImageIcon w="1.7rem" height="1.7rem" />
        </Flex>
      )}
      <ImgPresigned
        src={src}
        alt=""
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          margin: "auto",
          display: "block",
          objectFit: "contain",
        }}
        crossOrigin="anonymous"
      />
    </NodeViewWrapper>
  );
};
