import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { IconBase } from "components/base";
import { SYSTEM_NAME_JP_TEXT } from "constants/user";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { TaskComment, TaskLogDTO } from "interfaces/models/task";
import { User } from "interfaces/models/user";
import { isEqual } from "lodash-es";
import { Fragment, memo } from "react";
import { formatDate } from "utils/date";
import MediasByLog from "./MediasByLog";
import useRecordComment from "./useRecordComment";
import { defaultAvatarPath } from "constants/file";

interface Props {
  comments: TaskComment[];
  currentUser: User | null;
  listAllUserById: { [key: string]: UserDTO | null };
  listAssignedUserById: { [key: string]: UserDTO | null };
  mapTaskType: Map<string, string>;
  mapPartnerCompany: { [key: string]: string };
  avatar: string | undefined;
  onRevertDataByLog: (log: TaskLogDTO) => void;
}

const CommentCombine = memo(
  (
    props: Omit<
      Props,
      | "comments"
      | "avatar"
      | "listAssignedUserById"
      | "listAllUserById"
      | "currentUser"
    > & {
      comment: TaskComment;
      userCreatedLog: string;
      logText: JSX.Element;
      isRevert: boolean;
      isDisableRevert: boolean;
    }
  ) => {
    const {
      comment,
      userCreatedLog,
      logText,
      isRevert,
      isDisableRevert = false,
      onRevertDataByLog,
    } = props;

    return (
      <>
        <Flex width="100%" gap="1rem" alignItems="center">
          <Box fontSize="1rem" wordBreak="break-word">
            <Text as="b">
              {comment?.value?.isCombine ? SYSTEM_NAME_JP_TEXT : userCreatedLog}
            </Text>
            {logText}
            {formatDate(comment?.createdAt)}
          </Box>

          {comment.field && isRevert && (
            <Button
              isDisabled={isDisableRevert}
              p="0"
              variant="text"
              color="black"
              minHeight="2.4rem"
              ml="auto"
            >
              <IconBase
                icon="/img/icon-revert-data.svg"
                height="2rem"
                minHeight="2rem"
                flexShrink={0}
                onClick={() => onRevertDataByLog(comment as TaskLogDTO)}
              >
                revert
              </IconBase>
            </Button>
          )}
        </Flex>

        <MediasByLog log={comment} />
      </>
    );
  },
  (prevProps: any, nextProps: any): boolean => {
    return isEqual(prevProps, nextProps);
  }
);

const RecordCommentCombine = ({
  comments,
  listAssignedUserById,
  listAllUserById,
  mapTaskType,
  mapPartnerCompany,
  avatar,
  currentUser,
  onRevertDataByLog,
}: Props) => {
  const { getLogText } = useRecordComment({
    currentUser,
    listUserById: listAssignedUserById,
    mapPartnerCompany,
    mapTaskType,
  });

  return (
    <Flex
      borderBottom="1px solid #e2e2e3"
      background="#E5E5E5"
      p="0.5rem 1.5rem"
      flexDir="column"
      color="#737373"
    >
      <Flex mt="0.5rem" h="100%">
        <Image
          flexShrink={0}
          w="2.4rem"
          h="2.4rem"
          mt="0.4rem"
          mr="8px"
          borderRadius="full"
          objectFit="cover"
          src={avatar || defaultAvatarPath}
          fallbackSrc={defaultAvatarPath}
        />
        <Flex flexDir="column" alignItems="start" gap="1rem" width="100%">
          {comments.map((comment: TaskComment) => {
            return (
              <Fragment key={comment.id}>
                <CommentCombine
                  comment={comment}
                  userCreatedLog={
                    listAllUserById[comment.createdBy || ""]?.name ||
                    "user name"
                  }
                  mapPartnerCompany={mapPartnerCompany}
                  mapTaskType={mapTaskType}
                  logText={getLogText(comment)?.logText}
                  isRevert={getLogText(comment)?.isRevert}
                  isDisableRevert={getLogText(comment)?.isDisabledRevert}
                  onRevertDataByLog={onRevertDataByLog}
                />
              </Fragment>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default memo(
  RecordCommentCombine,
  (prevProps: any, nextProps: any): boolean => {
    return isEqual(prevProps, nextProps);
  }
);
