import {
  Center,
  Spinner,
  Table,
  TableColumnHeaderProps,
  TableHeadProps,
  TableProps,
  TableRowProps,
  TableCellProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  Cell,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Header,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import { isEmpty } from "lodash-es";
import { memo, useMemo, Fragment } from "react";
import { isFunction } from "utils/object";

export interface TableBaseProps<T> {
  tableProps?: TableProps;
  records: T[];
  columns: ColumnDef<T, any>[];
  theadProps?: TableHeadProps;
  colGroup?: React.ColHTMLAttributes<HTMLTableColElement>[];
  isLoading: boolean;
  isOpenFormCreateUpdate?: boolean;
  rowEditing?: T;
  formCreateUpdateRecord?: React.ReactNode;
  rowProps?: ((row: Row<T>) => TableRowProps) | TableRowProps;
  cellProps?: ((cell: Cell<T, any>) => TableCellProps) | TableCellProps;
  headerProps?:
    | ((header: Header<T, any>) => TableColumnHeaderProps)
    | TableColumnHeaderProps;
  emptyText?: any;
}

const TableBase = <T,>(props: TableBaseProps<T>) => {
  const {
    tableProps,
    theadProps,
    records,
    columns,
    colGroup,
    isLoading,
    isOpenFormCreateUpdate,
    rowEditing,
    formCreateUpdateRecord,
    rowProps,
    cellProps,
    headerProps,
    emptyText = "データがありません",
  } = props;

  const table = useReactTable({
    data: records,
    columns,
    autoResetPageIndex: false, //turn off auto reset of pageIndex
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const colCount = table.getAllColumns().length;

  const rowForm = useMemo(() => {
    return (
      <Tr className="row-form">
        <Td colSpan={colCount}>{formCreateUpdateRecord}</Td>
      </Tr>
    );
  }, [formCreateUpdateRecord, colCount]);

  const body = useMemo(
    () => {
      if (isLoading) {
        return (
          <Tr>
            <Td colSpan={colCount}>
              <Center py="2rem">
                <Spinner
                  zIndex={1000}
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Center>
            </Td>
          </Tr>
        );
      }

      const isEmptyRow = !records.length || isEmpty(table.getRowModel().rows);
      if (isEmptyRow && !isOpenFormCreateUpdate) {
        return (
          <Tr lineHeight="normal">
            <Td
              lineHeight="normal"
              height="100%"
              textAlign="center"
              colSpan={6}
            >
              {emptyText}
            </Td>
          </Tr>
        );
      }

      return (
        <>
          {isOpenFormCreateUpdate && !rowEditing && rowForm}

          {table.getRowModel().rows.map((row, rowIndex) => {
            const rowOriginal = row.original as any;
            if (rowEditing && rowOriginal?.id === (rowEditing as any)?.id) {
              return <Fragment key={row.id}>{rowForm}</Fragment>;
            }

            const _rowProps = isFunction(rowProps)
              ? (rowProps as any)(row)
              : rowProps;

            return (
              <Tr
                key={rowOriginal?.id ?? rowIndex}
                data-id={rowOriginal?.id ?? rowIndex}
                lineHeight="normal"
                {..._rowProps}
              >
                {row.getVisibleCells().map((cell) => {
                  const _cellProps = isFunction(cellProps)
                    ? (cellProps as any)(cell)
                    : cellProps;

                  return (
                    <Td
                      key={cell.id}
                      lineHeight="normal"
                      height="100%"
                      {..._cellProps}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      columns,
      isLoading,
      isOpenFormCreateUpdate,
      rowEditing,
      // eslint-disable-next-line
      table.getRowModel().rows,
      table,
      rowForm,
      records,
      colCount,
      cellProps,
      rowProps,
    ]
  );

  return (
    <Table {...tableProps}>
      {colGroup?.length && (
        <colgroup>
          {colGroup.map((col, colIndex) => (
            <col key={colIndex} {...col} />
          ))}
        </colgroup>
      )}
      <Thead minHeight="4rem" border="none" zIndex={10} top="0" {...theadProps}>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const _headerProps =
                headerProps instanceof Function
                  ? headerProps(header)
                  : headerProps;

              return (
                <Th
                  key={header.id}
                  width={`${header.getSize()}px`}
                  colSpan={header.colSpan}
                  lineHeight="normal"
                  color="font.default"
                  fontSize="1.4rem"
                  fontWeight="400"
                  {..._headerProps}
                >
                  <Text>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </Text>
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>

      <Tbody>{body}</Tbody>
    </Table>
  );
};

export default memo(TableBase) as <T>(props: TableBaseProps<T>) => JSX.Element;
