import { PATH_API } from "constants/serviceWorker";
import { ApiResponse } from "interfaces/models/api";
import {
  CreatePinList,
  CreatePinListResponse,
  GetPinReq,
  Pin,
  UpdatePinContentReq,
  UpdatePinGroupAction,
} from "interfaces/models/pin";
import { chunk } from "lodash-es";
import { transformBodyPinContent } from "models/pin";
import { axiosECS } from "services/baseAxios";
import { transformBodyForCreatePinList } from "utils/offline";

const pathV2 = `/v1/${PATH_API.INSPECTION_PIN}`;

const getPinList = async (params: GetPinReq): Promise<ApiResponse<Pin[]>> => {
  const { limit, ...rest } = params;

  return axiosECS.get(pathV2, {
    params: { limit: limit || 500, ...rest },
  });
};

export const getPin = async (id: string): Promise<ApiResponse<any>> => {
  return axiosECS.get(`${pathV2}/${id}`);
};

export const createPinList = async (
  args: CreatePinList
): Promise<ApiResponse<CreatePinListResponse>> => {
  const body = await transformBodyForCreatePinList(args);

  return axiosECS.post(pathV2, body);
};

export const updatePin = async (
  pin: Partial<Pin>
): Promise<ApiResponse<Pin>> => {
  return axiosECS.patch(pathV2, pin);
};

export const deletePinList = async (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(pathV2, {
    data: { ids },
  });
};

// update value in content
export const updatePinContent = async (
  body: UpdatePinContentReq
): Promise<ApiResponse> => {
  const newBody = transformBodyPinContent(body);

  return axiosECS.patch(`${pathV2}/content`, newBody);
};

export interface UpdatePinGroup {
  pinGroupId: string;
  order?: string | number;
  action: UpdatePinGroupAction;
  pinIds?: string[];
}

// add or remove pin from group
export const updatePinGroup = async (
  body: UpdatePinGroup
): Promise<ApiResponse<UpdatePinGroup>> => {
  return axiosECS.patch(`${pathV2}/group`, body);
};

export const getAllPins = async (params: GetPinReq) => {
  const pins: Pin[] = [];
  let cursor: string | undefined;
  const limit = 500;
  do {
    const { data, pagination } = await getPinList({
      ...params,
      paging: "cursor",
      cursor,
      limit,
    });
    cursor = pagination?.cursor;
    if (data.length) {
      pins.push.apply(pins, data);
    }
  } while (cursor);

  if (params.shouldCache) {
    const chunks = chunk(pins, 5);
    for (const items of chunks) {
      await Promise.all(items.map((item) => getPin(item.id)));
    }
  }

  return pins;
};

export const cachePins = async (params: GetPinReq) => {
  return axiosECS.get(pathV2, {
    params,
  });
};
