import { Box, Button, Flex } from "@chakra-ui/react";
import IconChevronBackward from "components/icon/IconChevronBackward";
import React from "react";

interface HeaderProps {
  title?: any;
  onBack(): void;
}

const Header: React.FC<HeaderProps> = ({ title, onBack }) => {
  return (
    <Flex
      w="100%"
      alignItems={"center"}
      p="1rem 2rem"
      height="6.4rem"
      bg="#1a1a1a"
      justifyContent={"space-between"}
      color="#fff"
    >
      <Button
        w="10rem"
        variant="text"
        color="white"
        fill="white"
        onClick={onBack}
      >
        <Flex alignItems="center">
          <IconChevronBackward
            width="6px"
            height="10px"
            mt="0.2rem"
            mr="1rem"
          />
          キャンセル
        </Flex>
      </Button>
      <Box mx="auto" fontSize={"1.8rem"} fontWeight={"bold"}>
        {title}
      </Box>
      <Box w="10rem"></Box>
    </Flex>
  );
};

export default Header;
