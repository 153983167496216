import { Box, Textarea, useOutsideClick } from "@chakra-ui/react";
import HeaderCommon from "components/editor-builder/component-preview/HeaderCommon";
import { DEFAULT_BLACKBOARD_CONTENT_SIZE } from "constants/blackBoardTemplate";
import { COMPONENT_SELECTED_OPACITY } from "constants/documentTemplate";
import {
  CellSizeSetting,
  ContentType,
  FontFamiLyType,
  PaperDirectionType,
  PaperSize,
  PaperType,
  TextPosition,
} from "constants/enum";
import { CellType, TemplateComponent } from "interfaces/models/component";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { omit } from "lodash-es";
import Component from "models/component";
import {
  DEFAULT_BORDER_COLOR,
  DEFAULT_BORDER_COLOR_ACTIVE,
} from "pages/document/template-page/hooks";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setComponents,
  setComponentSelected,
  setSelectedCell,
} from "redux/documentSlice";
import { RootState } from "redux/store";
import { hexToRgbA } from "utils/common";
import NormalTextPreview from "../NormalTextPreview";

export interface iTextboxComponentPreview {
  component: TemplateComponent;
  zoomRatio?: number;
  isOnlyView?: boolean;
  isResizable: boolean;
  contentType?: ContentType;
  isAutoResize?: boolean;
  isDisabled?: boolean;
  template?: DocumentTemplate;
  isBlackboardTemplate?: boolean;
}

const TextboxComponentPreview = (props: iTextboxComponentPreview) => {
  const {
    component,
    template,
    zoomRatio,
    isOnlyView = false,
    isResizable = false,
    contentType = ContentType.EDITOR,
    isAutoResize = true,
    isDisabled,
    isBlackboardTemplate = false,
  } = props;

  const [inputValue, setInputValue] = useState(component?.detail?.value || "");
  const [isShowInput, setIsShowInput] = useState(false);

  const dispatch = useDispatch();
  const {
    componentSelected,
    components,
    currentTemplate,
    documentContainerSize,
  } = useSelector((state: RootState) => state.document);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useOutsideClick({
    ref: inputRef,
    enabled: isShowInput,
    handler: () => {
      setIsShowInput(false);
      const newComponents = components.map((com) => {
        if (com.componentId === component.componentId) {
          return {
            ...com,
            detail: {
              ...com.detail,
              value: inputValue,
            },
          };
        }

        return com;
      });

      dispatch(setComponents(newComponents));
    },
  });

  useEffect(() => {
    setInputValue(component?.detail?.value || "");
  }, [component?.detail?.value]);

  useEffect(() => {
    if (!isShowInput) {
      return;
    }

    inputRef.current?.focus();
  }, [isShowInput]);

  const style = useMemo(() => {
    const css = component.detail?.style;

    return {
      textAlign: (css?.justifyContent ?? TextPosition.START) as any,
      fontWeight: css?.bold ? "bold" : "normal",
      fontStyle: css?.italic ? "italic" : "",
      textDecoration:
        css?.underline && css?.lineThrough
          ? "underline line-through"
          : css?.underline
          ? "underline"
          : css?.lineThrough
          ? "line-through"
          : "",
      fontSize: `${(css?.fontSize || 14) * Number(zoomRatio)}px`,
      color: css?.color ? css?.color : "",
      backgroundColor: css?.backgroundColor ? css?.backgroundColor : "",
      paddingLeft: `${css?.tab ?? 0}px`,
      paddingRight: `${css?.tab ?? 0}px`,
      fontFamily:
        css?.fontFamily === FontFamiLyType.NOTO_SANS
          ? `'Noto Sans JP', sans-serif`
          : css?.fontFamily === FontFamiLyType.NOTO_SERIF
          ? `'Noto Serif JP', serif`
          : "",
    };
  }, [component.detail?.style, zoomRatio]);

  const isSelectComponent = useMemo(() => {
    return componentSelected.componentId === component.componentId;
  }, [component.componentId, componentSelected.componentId]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    component.componentId !== componentSelected.componentId &&
      dispatch(setComponentSelected(component));

    dispatch(setSelectedCell([]));
  };

  const handleDoubleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsShowInput(true);
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const backgroundColor = useMemo(() => {
    const bgColor = component?.detail?.style?.backgroundColor || "#fff";

    if (!isSelectComponent) {
      return bgColor;
    }

    return hexToRgbA(bgColor, COMPONENT_SELECTED_OPACITY);
  }, [component?.detail?.style?.backgroundColor, isSelectComponent]);

  const maxWidth = useMemo(() => {
    if (isBlackboardTemplate) {
      return DEFAULT_BLACKBOARD_CONTENT_SIZE.width;
    }

    let pageInfo = currentTemplate?.pages?.[component?.page];
    if (template) {
      pageInfo = template?.pages?.[component?.page];
    }

    if (pageInfo) {
      const _pageSize = (pageInfo?.pageSize ||
        PaperType.A4) as keyof typeof PaperSize;
      let numOfColumns = PaperSize[_pageSize]?.numOfColumns;
      let numOfRows = PaperSize[_pageSize]?.numOfRows;
      if (pageInfo?.pageDirection === PaperDirectionType.HORIZONTAL) {
        [numOfColumns, numOfRows] = [numOfRows, numOfColumns];
      }

      const maxWidth = numOfColumns * CellSizeSetting.MIN_WIDTH;

      return maxWidth;
    }

    return;
  }, [currentTemplate, template, component.page, isBlackboardTemplate]);

  const isFullWidth = useMemo(() => {
    if (!maxWidth) {
      return false;
    }

    if (isBlackboardTemplate) {
      return component?.size?.width === maxWidth;
    }

    return component?.realSize?.width === maxWidth;
  }, [
    maxWidth,
    component?.size?.width,
    component?.realSize?.width,
    isBlackboardTemplate,
  ]);

  const isOverlapRightSide = useMemo(() => {
    const _template = template || currentTemplate;

    const components = _template?.components.filter(
      (com) => com.page === component.page
    );

    if (isFullWidth) {
      return false;
    }

    const componentIns = new Component({
      component,
      components,
      documentContainerSize,
      scale: zoomRatio,
    });

    const availableSpaceRight = componentIns.availableSpace.right;

    return !availableSpaceRight;
  }, [
    component,
    template,
    currentTemplate,
    documentContainerSize,
    zoomRatio,
    isFullWidth,
  ]);

  return (
    <Box
      id={`${component.componentId}`}
      className="textbox-component"
      style={{
        display: "flex",
        position: "relative",
        alignItems: "center",
        backgroundColor,
        border:
          isSelectComponent && !isOnlyView
            ? `1px solid ${DEFAULT_BORDER_COLOR_ACTIVE}`
            : component.detail?.style?.border &&
              component.detail?.style?.borderColor
            ? `${1 * (zoomRatio || 1)}px solid ${
                component.detail?.style?.borderColor
              }`
            : component.detail?.style?.border
            ? `${1 * (zoomRatio || 1)}px solid ${DEFAULT_BORDER_COLOR}`
            : "none",

        width: `calc(100% + ${
          isOverlapRightSide ? 1 * (zoomRatio || 1) : 0
        }px)`,
        height: "calc(100% + 1px)",
        fontSize: style.fontSize,
      }}
      {...(isOnlyView
        ? {}
        : { onClick: handleClick, onDoubleClick: handleDoubleClick })}
    >
      {isResizable && !isOnlyView && contentType === ContentType.EDITOR && (
        <HeaderCommon
          components={components}
          isSelectComponentOnly={isSelectComponent}
          component={component}
          left="-1px"
        />
      )}

      {contentType === ContentType.EDITOR && isShowInput && (
        <Textarea
          position="relative"
          zIndex="2"
          rows={1}
          ref={inputRef}
          style={{
            width: `calc(100% + ${
              isOverlapRightSide ? 1 * (zoomRatio || 1) : 0
            }px)`,
            height: "calc(100% - 2px)",
          }}
          p="5px"
          resize="none"
          overflow="hidden"
          textDecoration={style.textDecoration}
          fontSize={style.fontSize}
          border="none"
          overflowY="auto"
          outline="none"
          _focus={{
            borderColor: "none",
          }}
          value={inputValue}
          onChange={handleChangeInput}
          className="box-scroll-bar text-area-scroll-bar"
          isDisabled={isDisabled}
        />
      )}

      {!isShowInput && (
        <NormalTextPreview
          isAutoResize={isAutoResize}
          data={
            {
              value: component?.detail?.value,
              style: {
                ...component?.detail?.style,
                backgroundColor,
                fontSize:
                  (component?.detail?.style?.fontSize || 14) *
                  Number(zoomRatio || 1),
              },
            } as CellType
          }
          style={omit({ ...style, backgroundColor, padding: 0 }, ["fontSize"])}
        />
      )}
    </Box>
  );
};

export default memo(TextboxComponentPreview);
