import { Box } from "@chakra-ui/react";
import {
  fillFakeContent,
  getDefaultValueByLinkedData,
} from "components/containers/InspectionFormat/Content/SettingFontSize/utils/style";
import { memo, useEffect, useRef } from "react";
import { FieldRawProps } from "../../types";

const DevicePressureRaw = (props: FieldRawProps) => {
  const { item, containerRef, linkedData, style, ...boxProps } = props;

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current || !contentRef.current) {
      return;
    }

    const defaultText = getDefaultValueByLinkedData(linkedData);
    fillFakeContent({
      textEle: contentRef.current,
      parentElement: containerRef.current,
      defaultText,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [style?.fontSize, linkedData]);

  return <Box ref={contentRef} {...boxProps} />;
};

export default memo(DevicePressureRaw);
