import { Icon, IconProps } from "@chakra-ui/icons";

const IconChevronBackward = (props: IconProps) => {
  return (
    <Icon
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="currentcolor"
      {...props}
    >
      <path d="M1.58261 5.0001L5.31643 8.73369C5.44323 8.86065 5.50816 9.02022 5.51122 9.21242C5.51412 9.40446 5.44919 9.56694 5.31643 9.69985C5.18351 9.83262 5.02248 9.899 4.83334 9.899C4.6442 9.899 4.48318 9.83262 4.35026 9.69985L0.230531 5.58013C0.144822 5.49426 0.0843229 5.40374 0.0490312 5.30856C0.0137395 5.21338 -0.00390625 5.11056 -0.00390625 5.0001C-0.00390625 4.88965 0.0137395 4.78683 0.0490312 4.69165C0.0843229 4.59647 0.144822 4.50594 0.230531 4.42008L4.35026 0.300354C4.47722 0.173548 4.63679 0.108617 4.82899 0.105562C5.02103 0.102659 5.18351 0.16759 5.31643 0.300354C5.44919 0.43327 5.51557 0.594298 5.51557 0.783437C5.51557 0.972576 5.44919 1.1336 5.31643 1.26652L1.58261 5.0001Z" />
    </Icon>
  );
};

export default IconChevronBackward;
