import { createStandaloneToast, Flex, Spinner, Text } from "@chakra-ui/react";
import { message } from "components/base";
import ErrorBoundary from "components/ErrorBoundary";
import { SYNC_PROJECT_BIM_FILES } from "constants/cache";
import { CHANNEL_SYNC_DATA, MessageType } from "constants/websocket";
import { useAppWebSocket } from "hooks/useAppWebSocket";
import { WSMessage } from "interfaces/models/websocket";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router-dom";
import {
  setActualNetworkStatus,
  setAppSize,
  setIsCheckedPrecacheMissStaticFile,
  setIsSyncOfflineData,
  setLoadingNetworkStatus,
  setOnline,
  setTimeBeginOffline,
} from "redux/appSlice";
import store, { RootState } from "redux/store";
import { offlineRoutes, routePath } from "routes/path";
import { checkTokenExpired, doRefreshToken } from "utils/authen";
import { syncCachedDataToOnline } from "utils/syncData";
import { getNetworkStatus, getDatabaseSize } from "utils/indexedDb";
import { getLocalStorage, setLocalStorage } from "utils/storage";
import AppRoute from "./routes";
import { register as registerServiceWorker } from "./serviceWorkerRegistration";
import { pdfjs } from "react-pdf";
import { INVALID_ROUTE_OFFLINE_MESSAGE } from "constants/offline";
import { changeNetworkStatus } from "utils/offline";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const { toast, ToastContainer } = createStandaloneToast();

export { toast };

function App() {
  const disableSW = process.env.NODE_ENV === "development";

  const {
    isInstallingServiceWorker,
    shouldLogout,
    isCheckedPrecacheMissStaticFile,
    isServiceWorkerReady,
    isOnline,
    isWebSocketBimFileOpen,
    loadingNetworkStatus,
  } = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [messageWaitings, setMessagesWaiting] = useState<WSMessage[]>([]);
  const isForgeViewPage = matchPath(routePath.ForgeViewer, pathname);
  const { sendWebSocketMessage, isWebSocketOpen } = useAppWebSocket(
    CHANNEL_SYNC_DATA,
    // wait for messages with data and
    // channel a is connected to excludeConnectId when sending data in channel all
    messageWaitings.length > 0 &&
      (isForgeViewPage ? isWebSocketBimFileOpen : true)
  );

  const isInstallingSW =
    !disableSW &&
    (isInstallingServiceWorker || !isCheckedPrecacheMissStaticFile);

  useEffect(() => {
    if (!loadingNetworkStatus) {
      return;
    }
    (async () => {
      if (isInstallingSW && !isOnline && navigator.onLine) {
        // continue install service worker
        await changeNetworkStatus(true);
        dispatch(setActualNetworkStatus(true));
        setTimeout(() => {
          registerServiceWorker();
        });
      }
    })();
    // when first time access, will installing service worker if offline mode but device online
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInstallingSW, loadingNetworkStatus]);

  useEffect(() => {
    if (loadingNetworkStatus) {
      return;
    }
    if (!isOnline) {
      const isValidRouteOffline = offlineRoutes.some((pathPattern) => {
        return matchPath(pathPattern, pathname);
      });
      if (!isValidRouteOffline) {
        message.warning(INVALID_ROUTE_OFFLINE_MESSAGE);

        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline, pathname, loadingNetworkStatus]);

  useEffect(() => {
    if (!isWebSocketOpen || !messageWaitings.length) {
      return;
    }
    messageWaitings.forEach((msg) => {
      sendWebSocketMessage(msg);
    });
    setMessagesWaiting([]);
  }, [messageWaitings, isWebSocketOpen, sendWebSocketMessage]);

  useEffect(() => {
    if (shouldLogout === 0) {
      return;
    }

    (async () => {
      const user = getLocalStorage("userInfo");
      const isOnline = await getNetworkStatus();
      if (!isOnline && !!user) {
        return;
      }

      window.location.href = process.env.REACT_APP_PLANETS_AUTH_LOGIN_URL!;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLogout]);

  useEffect(() => {
    const currentVersion = getLocalStorage("ui-version");
    const metaUi = document.getElementById("ui-version");
    const newVersion = metaUi?.getAttribute("content");
    if (currentVersion !== newVersion) {
      setLocalStorage("ui-version", newVersion || "");
    }
  }, []);

  useEffect(() => {
    (async () => {
      const isOnline = await getNetworkStatus();
      store.dispatch(setOnline(isOnline));
      dispatch(setActualNetworkStatus(navigator.onLine));
      dispatch(setLoadingNetworkStatus(false));
    })();
  }, []);

  useEffect(() => {
    if (loadingNetworkStatus) {
      return;
    }
    if (disableSW) return;
    const handleOnline = async () => {
      if (!("serviceWorker" in navigator)) {
        return;
      }

      // only sync offline data if any data size
      const offlineEditDataSize = await getDatabaseSize();
      if (!offlineEditDataSize) {
        registerServiceWorker();

        return;
      }

      const isTokenExpired = checkTokenExpired();
      if (isTokenExpired) {
        const response = await doRefreshToken();
        if (!response) {
          return;
        }
      }
      // sync data from indexedDB
      dispatch(setIsSyncOfflineData(true));
      const isSyncSuccess = await syncCachedDataToOnline();
      dispatch(setIsSyncOfflineData(false));
      if (!isSyncSuccess) {
        // if not sync success -> not allow online
        return await changeNetworkStatus(false);
      }
      dispatch(setTimeBeginOffline(0));
      const syncProjectBimFiles: string[] = JSON.parse(
        getLocalStorage(SYNC_PROJECT_BIM_FILES, null) || "[]"
      );

      if (process.env.REACT_APP_SKIP_SEND_SOCKET !== "true") {
        const newMessageWaitings = [...messageWaitings];
        syncProjectBimFiles.forEach((id) => {
          newMessageWaitings.push({
            type: MessageType.RELOAD_DOCUMENT,
            data: "",
            channel: id,
          });
          newMessageWaitings.push({
            type: MessageType.RELOAD_TASK,
            data: "",
            channel: id,
          });
        });
        setMessagesWaiting(newMessageWaitings);
      }
      setLocalStorage(SYNC_PROJECT_BIM_FILES, []);
      // after sync data -> register service worker
      registerServiceWorker();
    };

    const handleOffline = async () => {
      if (!("serviceWorker" in navigator)) {
        return;
      }

      // clear event update found
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        console.log("update found");
        registrations.forEach((registration) => {
          registration.removeEventListener("updatefound", () => {});
        });
      });

      await changeNetworkStatus(false);
    };
    if (isOnline) {
      handleOnline();
    } else {
      handleOffline();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOnline, loadingNetworkStatus]);

  useEffect(() => {
    const handleOnline = () => {
      dispatch(setActualNetworkStatus(true));
    };

    const handleOffline = async () => {
      if (store.getState().app.isShowOfflineMsg) {
        message.warning(
          "ネットワークの接続が確認できないため、オフラインモードに移行します。"
        );
      }
      await changeNetworkStatus(false);
      dispatch(setActualNetworkStatus(false));
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [dispatch]);

  useEffect(() => {
    let timeout: any = null;
    const appHeight = () => {
      if (timeout) clearTimeout(timeout);

      return setTimeout(() => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const doc = document.documentElement;
        // Delay for get correct height screen
        doc.style.setProperty("--app-height", `${windowHeight}px`);
        store.dispatch(
          setAppSize({ width: windowWidth, height: windowHeight })
        );
      }, 300);
    };
    timeout = appHeight();
    window.addEventListener("resize", appHeight);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      window.removeEventListener("resize", appHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!("serviceWorker" in navigator)) {
      dispatch(setIsCheckedPrecacheMissStaticFile(true));
    }

    if (isServiceWorkerReady && !isCheckedPrecacheMissStaticFile) {
      dispatch(setIsCheckedPrecacheMissStaticFile(true));

      setTimeout(() => {
        window.location.reload();
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isServiceWorkerReady, isCheckedPrecacheMissStaticFile]);

  if (loadingNetworkStatus) {
    return <></>;
  }

  return (
    <ErrorBoundary>
      {isInstallingSW && (
        <Flex
          id="asdfasdfasdf"
          position="absolute"
          left={0}
          top={0}
          width="100vw"
          height="100vh"
          backgroundColor="rgba(0,0,0,0.2)"
          zIndex={100}
          cursor="progress"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap="10px"
        >
          <Spinner size="xl" />
          <Text fontWeight="bold" fontSize="3rem">
            Installing app...
          </Text>
        </Flex>
      )}
      <AppRoute />
      <ToastContainer />
    </ErrorBoundary>
  );
}

export default App;
