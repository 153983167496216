import { useDisclosure } from "@chakra-ui/react";
import { message } from "components/base";
import { BlackboardDataDefaultOfFilterPhoto } from "constants/document";
import {
  CellProperty,
  CellSizeSetting,
  DragComponentAction,
  PaperDirectionType,
  TableSizeSetting,
  TemplateComponentType,
  TextPosition,
} from "constants/enum";
import {
  CellPosition,
  CellType,
  CustomTemplateComponent,
  TableStyleType,
  TemplateComponent,
} from "interfaces/models/component";
import { iRegionSelect } from "interfaces/models/rnd";
import { isEmpty, cloneDeep } from "lodash-es";
import { checkHasExistsHeader } from "models/document";
import { isSelfInspectionTemplate } from "models/documentCategory";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setComponents,
  setComponentSelected,
  setIsCreateComponent,
} from "redux/documentSlice";
import { RootState } from "redux/store";
import { generateSubTableForComment } from "utils/document";
import { generateRandomString } from "utils/string";

interface Props {
  zoomRatio: number;
  isTaskSheetTemplate?: boolean;
}

export const DEFAULT_FONT_SIZE = 14;
export const DEFAULT_BORDER_COLOR = "#000000ff";
export const DEFAULT_TEXT_COLOR = "#000000ff";
export const DEFAULT_BORDER_COLOR_ACTIVE = "#009BE0";
export const DEFAULT_BORDER_COLOR_DEFAULT = "#e7e7e7";
export const DEFAULT_BG_COLOR = "#fff";
export const DEFAULT_HEADER_BG_COLOR = "#FAFAFAFF";
export const MIN_CELLS_TO_CREATE_COMPONENT = 2;

export interface DroppedComponent extends CustomTemplateComponent {
  action: DragComponentAction;
}

interface Area {
  x?: number;
  y?: number;
  height?: number;
  width?: number;
}

interface iHandleDropComponent {
  newDroppedComponent: TemplateComponent;
  droppedComponent: DroppedComponent;
  initCellPosition: CellPosition;
  initInfoCell: Partial<CellType>;
  defaultStyleComponent: TableStyleType;
}

interface iHandleValidateDropComponent {
  droppedComponent: DroppedComponent;
}

export default function useDocumentTemplate({ zoomRatio }: Props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.user);
  const {
    currentTemplate,
    components,
    isShowModalBlackBoard,
    documentContainerSize,
    customComponentSelected,
    pageSelected,
  } = useSelector((state: RootState) => state.document);

  const {
    isOpen: isConfirmSaveTemplateModal,
    onOpen: openConfirmSaveTemplateModal,
    onClose: closeConfirmSaveTemplateModal,
  } = useDisclosure();

  const isExistFilterPhoto: boolean = useMemo(
    () =>
      components?.some(
        (item) => item.type === TemplateComponentType.FilterPhoto
      ),
    [components]
  );

  const copyId = (id: string, componentId: string) =>
    `${componentId}${id?.slice(id?.indexOf("-"), id?.length)}`;

  const checkIsDroppedComponentIntersectedWithArea = (
    droppedComponent: DroppedComponent,
    area: Area
  ) => {
    return {
      //return true if droppedComponent intersected with another component on X axis
      xDir:
        (droppedComponent?.position?.y >= Number(area.y) &&
          droppedComponent?.position?.y <=
            Number(area?.y) + Number(area?.height)) ||
        (droppedComponent?.position?.y + droppedComponent?.size?.height >=
          Number(area.y) &&
          droppedComponent?.position?.y + droppedComponent?.size?.height <=
            Number(area?.y) + Number(area?.height)),
      //return true if droppedComponent intersected with another component on Y axis
      yDir:
        (droppedComponent?.position?.x >= Number(area.x) &&
          droppedComponent?.position?.x <=
            Number(area?.x) + Number(area?.width)) ||
        (droppedComponent?.position?.x + droppedComponent?.size?.width >=
          Number(area.x) &&
          droppedComponent?.position?.x + droppedComponent?.size?.width <=
            Number(area?.x) + Number(area?.width)),
    };
  };

  const getDropableZone = (droppedComponent: DroppedComponent) => {
    const sizePageRatio = pageSelected?.sizePageRatio || 1;
    const pageDirectionRatio = pageSelected?.pageDirectionRatio || 1;

    const displayY = (zoomRatio * sizePageRatio) / pageDirectionRatio;
    const displayX = zoomRatio * sizePageRatio * pageDirectionRatio;
    const cloneDroppedComponent = structuredClone(droppedComponent);
    cloneDroppedComponent.position.x =
      cloneDroppedComponent.position.x * displayX;
    cloneDroppedComponent.position.y =
      cloneDroppedComponent.position.y * displayY;

    const dropableZone = {
      x: cloneDroppedComponent?.position?.x,
      y: cloneDroppedComponent?.position?.y,
      height:
        documentContainerSize?.height - cloneDroppedComponent?.position?.y,
      width: documentContainerSize?.width - cloneDroppedComponent?.position?.x,
    };

    const componentAreas = components?.map((component) => ({
      x: component?.realPosition?.x,
      y: component?.realPosition?.y,
      width: component?.size?.width,
      height: component?.size?.height,
    }));

    componentAreas?.forEach((area) => {
      if (cloneDroppedComponent?.position?.y > area?.y + area?.height) {
        dropableZone.y = area?.y + area?.height;
      }

      if (
        cloneDroppedComponent?.position?.y < area?.y &&
        area?.y - cloneDroppedComponent?.position?.y < dropableZone?.height &&
        checkIsDroppedComponentIntersectedWithArea(cloneDroppedComponent, area)
          .yDir
      ) {
        dropableZone.height = area?.y - cloneDroppedComponent?.position?.y;
      }

      if (
        cloneDroppedComponent?.position?.x < area.x &&
        area?.x - cloneDroppedComponent?.position?.x < dropableZone?.width &&
        checkIsDroppedComponentIntersectedWithArea(cloneDroppedComponent, area)
          .xDir
      ) {
        dropableZone.width = area.x - cloneDroppedComponent?.position?.x;
      }
    });

    dropableZone.width = dropableZone.width / displayX;
    dropableZone.height = dropableZone.height / displayY;

    return dropableZone;
  };

  const handleDropFilterPhotoComponent = ({
    newDroppedComponent,
    initCellPosition,
    initInfoCell,
    defaultStyleComponent,
  }: iHandleDropComponent) => {
    const idRow = `${newDroppedComponent.componentId}-tr-0`;
    const subTable = generateSubTableForComment({
      cell: {
        ...initInfoCell,
        idRow,
        idColumn: `${idRow}-td-1`,
      } as CellType,
      blackboardData: BlackboardDataDefaultOfFilterPhoto,
    });

    newDroppedComponent.detail = {
      rows: [
        {
          idRow,
          cells: [
            {
              idRow,
              idColumn: `${idRow}-td-0`,
              cellId: generateRandomString(36, 10),
              ...initInfoCell,
              cellProperty: CellProperty.IMAGE,
              position: initCellPosition,
            },
            {
              idRow,
              idColumn: `${idRow}-td-1`,
              cellId: generateRandomString(36, 10),
              ...initInfoCell,
              cellProperty: CellProperty.DOCUMENT_DATA,
              cellLinkedData: {
                field: CellProperty.COMMENT,
              },
              colSpan: 1,
              rowSpan: 1,
              position: {
                ...initCellPosition,
                idColumn: generateRandomString(36, 10),
              },
              subTable,
            },
          ],
        },
      ],
      style: defaultStyleComponent,
    };

    newDroppedComponent!.detail!.isRepeat = true;
    newDroppedComponent!.detail!.numOfRepeat = 1;
  };

  const handleDropTableComponent = ({
    newDroppedComponent,
    droppedComponent,
    initCellPosition,
    initInfoCell,
    defaultStyleComponent,
  }: iHandleDropComponent) => {
    const { height: componentMaxHeight, width: componentMaxWidth } =
      getDropableZone(droppedComponent);

    if (!isEmpty(droppedComponent?.detail)) {
      newDroppedComponent.detail = {
        ...droppedComponent?.detail,
        rows: droppedComponent?.detail?.rows?.map((row) => {
          return {
            idRow: copyId(row?.idRow, String(droppedComponent?.componentId)),
            cells: row?.cells?.map((cell) => {
              // ratio of new width to old width
              const widthRate =
                (droppedComponent?.size?.width < componentMaxWidth
                  ? droppedComponent?.size?.width
                  : componentMaxWidth) / droppedComponent?.size?.width;

              // ratio of new height to old height
              const heightRate =
                (droppedComponent?.size?.height < componentMaxHeight
                  ? droppedComponent?.size?.height
                  : componentMaxHeight) / droppedComponent?.size?.height;

              const cellWidth = droppedComponent?.isCustomComponent
                ? widthRate * Number(cell?.width)
                : Number(cell?.width);

              const cellHeight = droppedComponent?.isCustomComponent
                ? heightRate * Number(cell?.height)
                : Number(cell?.height);

              return {
                ...cell,
                idColumn: copyId(
                  String(cell?.idColumn),
                  String(droppedComponent?.componentId)
                ),
                idRow: copyId(
                  String(cell?.idRow),
                  String(droppedComponent?.componentId)
                ),
                cellId: generateRandomString(36, 10),
                width: cellWidth,
                height: cellHeight,
              };
            }),
          };
        }),
      };

      return;
    }

    newDroppedComponent.detail = {
      rows: [
        {
          idRow: `${newDroppedComponent.componentId}-tr-0`,
          cells: [
            {
              idColumn: `${newDroppedComponent.componentId}-tr-0-td-0`,
              idRow: `${newDroppedComponent.componentId}-tr-0`,
              cellId: generateRandomString(36, 10),
              position: initCellPosition,
              ...initInfoCell,
            },
            {
              idColumn: `${newDroppedComponent.componentId}-tr-0-td-1`,
              idRow: `${newDroppedComponent.componentId}-tr-0`,
              cellId: generateRandomString(36, 10),
              position: {
                ...initCellPosition,
                idColumn: generateRandomString(36, 10),
              },
              ...initInfoCell,
            },
          ],
        },
      ],
      style: defaultStyleComponent,
      isRepeatTable: false,
      numOfRepeatTable: 1,
    };
  };

  const handleValidateDropComponent = ({
    droppedComponent,
  }: iHandleValidateDropComponent) => {
    const isHeader =
      droppedComponent.type === TemplateComponentType.TableHeader;
    const isFilterPhoto =
      droppedComponent.type === TemplateComponentType.FilterPhoto;
    const isKeyplan = droppedComponent.type === TemplateComponentType.Keyplan;
    const componentTypePhotoBooks = [
      TemplateComponentType.TasksImage,
      TemplateComponentType.PhotoBooksInstruction,
      TemplateComponentType.PhotoBooksReport,
    ];
    const isPhotoBooks = componentTypePhotoBooks.includes(
      droppedComponent.type
    );
    const isExistsKeyplan = components?.some(
      (c) => c.type === TemplateComponentType.Keyplan
    );
    const isExistsPhotoBooks = components?.some((c) =>
      componentTypePhotoBooks.includes(c.type)
    );
    const isExistsHeader = checkHasExistsHeader(components);

    if (isKeyplan && isExistsKeyplan) {
      message.error("Keylan already exists");

      return true;
    }

    if (isPhotoBooks && isExistsPhotoBooks) {
      message.warning("写真帳がすでに登録されています。");

      return true;
    }

    if (isHeader && isExistsHeader) {
      message.warning("ヘッダーは既に存在します");

      return true;
    }

    if (isFilterPhoto && isExistFilterPhoto) {
      message.warning("フィルター写真は既に存在します。");

      return true;
    }

    if (
      isHeader &&
      droppedComponent?.isCustomComponent &&
      customComponentSelected?.documentTemplateType !==
        currentTemplate?.documentType
    ) {
      message.warning(
        "選択されたヘッダー内で使用している項目はこの書類種別には使用できません。"
      );

      return true;
    }

    return false;
  };

  const onDropComponent = (
    droppedComponent: DroppedComponent,
    region?: iRegionSelect
  ) => {
    const isHeader =
      droppedComponent.type === TemplateComponentType.TableHeader;
    const isTable = droppedComponent.type === TemplateComponentType.Table;
    const isText = droppedComponent.type === TemplateComponentType.Text;
    const isImage = droppedComponent.type === TemplateComponentType.Image;
    const isImageUploaded =
      droppedComponent.type === TemplateComponentType.ImageUploaded;
    const isKeyplan = droppedComponent.type === TemplateComponentType.Keyplan;
    const isQRCode = droppedComponent.type === TemplateComponentType.QR_CODE;
    const isFilterPhoto =
      droppedComponent.type === TemplateComponentType.FilterPhoto;
    const newDroppedComponent = cloneDeep(droppedComponent);
    const isLastCell = region?.nthCellOnX === region?.maxCellsOnX;
    const isPhotoBooks = [
      TemplateComponentType.TasksImage,
      TemplateComponentType.PhotoBooksInstruction,
      TemplateComponentType.PhotoBooksReport,
    ].includes(droppedComponent?.type);

    // validate
    const hasError = handleValidateDropComponent({
      droppedComponent,
    });

    if (hasError) {
      return;
    }

    const pageDirectionRatio = pageSelected?.pageDirectionRatio || 1;
    const sizePageRatio = pageSelected?.sizePageRatio || 1;

    const initCellPosition: CellPosition = {
      idRow: generateRandomString(36, 10),
      idColumn: generateRandomString(36, 10),
    };

    if (Number(region?.xCells) < MIN_CELLS_TO_CREATE_COMPONENT || isLastCell) {
      return;
    }

    const { height: componentMaxHeight, width: componentMaxWidth } =
      getDropableZone(droppedComponent);

    const componentWidth = droppedComponent?.isCustomComponent
      ? droppedComponent?.size?.width < componentMaxWidth
        ? droppedComponent?.size?.width
        : componentMaxWidth
      : droppedComponent?.size?.width;

    const componentHeight = droppedComponent?.isCustomComponent
      ? droppedComponent?.size?.height < componentMaxHeight
        ? droppedComponent?.size?.height
        : componentMaxHeight < 0
        ? (droppedComponent?.detail?.rows?.length || 1) *
          CellSizeSetting.MIN_HEIGHT
        : componentMaxHeight
      : droppedComponent?.size?.height;

    if (droppedComponent.action !== DragComponentAction.Create) {
      return;
    }

    const defaultStyleComponent = {
      border: true,
      borderColor: DEFAULT_BORDER_COLOR,
      fontSize: DEFAULT_FONT_SIZE,
      color: DEFAULT_TEXT_COLOR,
      backgroundColor: isHeader ? DEFAULT_HEADER_BG_COLOR : DEFAULT_BG_COLOR,
      bold: isHeader ? true : false,
    };

    const initInfoCell = {
      value: "",
      style: {
        ...defaultStyleComponent,
        borderRight: true,
        borderLeft: true,
        borderTop: true,
        borderBottom: true,
      },
      width: droppedComponent.size?.width
        ? droppedComponent.size?.width / TableSizeSetting.DEFAULT_COLUMN_COUNT
        : CellSizeSetting.DEFAULT_HEIGHT,
      height: newDroppedComponent.size?.height
        ? newDroppedComponent.size?.height / TableSizeSetting.DEFAULT_ROW_COUNT
        : CellSizeSetting.DEFAULT_HEIGHT,
      cellProperty: CellProperty.TEXT,
      rowSpan: 1,
      colSpan: 1,
    };

    if (isTable || isHeader) {
      handleDropTableComponent({
        newDroppedComponent,
        droppedComponent,
        initCellPosition,
        initInfoCell,
        defaultStyleComponent,
      });
    } else if (isFilterPhoto) {
      handleDropFilterPhotoComponent({
        newDroppedComponent,
        droppedComponent,
        initCellPosition,
        initInfoCell,
        defaultStyleComponent,
      });
    } else if (isText) {
      newDroppedComponent.detail = {
        style: {
          tab: 8,
          justifyContent: TextPosition.START, // default value for letterPosition
          ...defaultStyleComponent,
        },
      };
    } else if (
      isImage ||
      isQRCode ||
      isPhotoBooks ||
      isKeyplan ||
      isImageUploaded
    ) {
      newDroppedComponent.detail = {
        style: defaultStyleComponent,
      };
    }

    newDroppedComponent.size = {
      ...droppedComponent?.size,
      width: componentWidth,
      height: componentHeight,
    };

    newDroppedComponent.realSize = {
      width: droppedComponent?.size?.width * pageDirectionRatio * sizePageRatio,
      height:
        droppedComponent?.size?.height / (pageDirectionRatio / sizePageRatio),
    };

    newDroppedComponent.realPosition = {
      x: droppedComponent?.position?.x * pageDirectionRatio * sizePageRatio,
      y: droppedComponent?.position?.y / (pageDirectionRatio / sizePageRatio),
    };

    const fullWidth = documentContainerSize.width / zoomRatio - 1;
    const fullHeight = documentContainerSize.height / zoomRatio - 1;

    if (
      isSelfInspectionTemplate(currentTemplate.documentType) &&
      newDroppedComponent.type === TemplateComponentType.Text
    ) {
      newDroppedComponent.isLinkedHeader = true;
    }

    if (isPhotoBooks) {
      newDroppedComponent.size = {
        width:
          pageSelected.pageDirection === PaperDirectionType.HORIZONTAL
            ? fullHeight
            : fullWidth,
        height:
          pageSelected.pageDirection === PaperDirectionType.HORIZONTAL
            ? fullWidth
            : fullHeight,
      };
      newDroppedComponent.realSize = {
        width:
          pageSelected.pageDirection === PaperDirectionType.HORIZONTAL
            ? fullHeight
            : fullWidth,
        height:
          pageSelected.pageDirection === PaperDirectionType.HORIZONTAL
            ? fullWidth
            : fullHeight,
      };
      newDroppedComponent.position = {
        x: 0,
        y: 0,
      };
      newDroppedComponent.realPosition = {
        x: 0,
        y: 0,
      };
    }
    let newComponents = [...components, newDroppedComponent];

    if (isPhotoBooks) {
      const pages = currentTemplate?.pages;
      const pageIndex = pages.findIndex(
        (p) => p.pageId === pageSelected.pageId
      );
      const remainComponents = newComponents.filter(
        (com) => com.page !== pageIndex
      );

      newComponents = [...remainComponents, newDroppedComponent];
    }

    dispatch(setComponents(newComponents));
    dispatch(setComponentSelected(newDroppedComponent));
    dispatch(setIsCreateComponent(true));
  };

  return {
    currentTemplate,
    components,
    zoomRatio,
    isShowModalBlackBoard,
    currentUser,
    location,
    isConfirmSaveTemplateModal,
    onDropComponent,
    dispatch,
    openConfirmSaveTemplateModal,
    closeConfirmSaveTemplateModal,
  };
}
