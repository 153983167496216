import { S3_URL } from "constants/s3";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import {
  FileHistory,
  PinCombine,
  PinDTO,
  UpdatePinContentReq,
} from "interfaces/models/pin";
import { isUndefined } from "lodash-es";
import { generatePinCombineId } from "utils/forge/extensions/custom-label/utils";

export const transformBodyPinContent = (pinContent: UpdatePinContentReq) => {
  const body = structuredClone(pinContent);
  const data = body.value.data;
  const removePrefix = (url: string) => url.replace(`${S3_URL}/`, "");

  if (data.src) {
    data.src = removePrefix(data.src);
  }

  if (data.originSrc) {
    data.originSrc = removePrefix(data.originSrc);
  }

  if (data?.histories?.length) {
    data.histories.forEach((history: FileHistory) => {
      if (history.src) {
        history.src = removePrefix(history.src);
      }
    });
  }

  body.value = { ...body.value, data };

  return body;
};

export const getPinLevel = (params: {
  pin: PinDTO;
  levelData?: DataProjectModel["levelData"];
}) => {
  const { pin, levelData } = params;
  const positionZ = pin.position?.z;
  if (!levelData || pin?.level || isUndefined(positionZ)) {
    return pin.level || "";
  }
  let levelLabel = pin?.level || "";
  const zMin: { value: number | undefined; levelLabel: string } = {
    value: undefined,
    levelLabel: "",
  };
  const zMax: { value: number | undefined; levelLabel: string } = {
    value: undefined,
    levelLabel: "",
  };

  Object.entries(levelData).forEach(([_, level]) => {
    if (isUndefined(level?.zMin) || isUndefined(level?.zMax) || levelLabel) {
      return;
    }

    const label = level.label;
    if (isUndefined(zMin.value) || zMin.value > level.zMin) {
      zMin.value = level.zMin;
      zMin.levelLabel = label;
    }
    if (isUndefined(zMax.value) || zMax.value < level.zMax) {
      zMax.value = level.zMax;
    }

    if (positionZ > level.zMin && positionZ < level.zMax) {
      levelLabel = label;
    }
  });

  if (levelLabel) {
    return levelLabel;
  }

  if (!isUndefined(zMin.value) && positionZ < zMin.value) {
    return zMin.levelLabel;
  } else if (!isUndefined(zMax.value) && positionZ > zMax.value) {
    return zMax.levelLabel;
  }

  return levelLabel;
};

export const updatePinCombineWhenRemoveDeletePinGroup = (arg: {
  pinIds: string[];
  pinCombine: PinCombine | undefined;
  isFromModeAddPinToGroup?: boolean;
  isDeletePinGroup?: boolean;
}) => {
  const { pinIds, pinCombine, isFromModeAddPinToGroup, isDeletePinGroup } = arg;
  if (!pinCombine || !pinIds.length || !pinCombine.pinIds?.length) {
    return;
  }

  if (!isFromModeAddPinToGroup || isDeletePinGroup) {
    pinCombine.pinIds = pinCombine.pinIds?.filter((id) => !pinIds.includes(id));
    pinCombine.id = generatePinCombineId(pinCombine.pinIds);
  }

  if (!isDeletePinGroup && isFromModeAddPinToGroup) {
    pinCombine.isAddPin = true;
  }
};
