export const CACHED_PROJECT_INFO_KEY = "cached-project-info";
export const SYNC_PROJECT_BIM_FILES = "sync-project-bim-files";

export const CACHE_DOCUMENT_CATEGORY_SELECTED_KEY =
  "CACHE_DOCUMENT_CATEGORY_SELECTED_KEY";

export const PROJECT_RESOURCE = {
  modernScreenshot: "modernScreenshot",
  refreshToken: "doRefreshTokenFromPlanets",
  forgeData: "getForgeData",
  spacesData: "getSpacesData",
  versions: "getVersions",
  listUser: "getListUser",
  userSetting: "getUserSetting",
  project: "getProject",
  projectDetail: "getProjectDetail",
  projectBimFileDetail: "getProjectBimFileDetail",
  partnerCompanies: "getPartnerCompanies",
  aecData: "getAECData",
  currentUser: "currentUser",
  families: "families",
  allView: "getAllBimViewByVersionId",
  allBimView: "getGeneratedViewList",
  inspectionTypeList: "getInspectionTypeList",
  derivative: "getDerivativesByVersionId",
  bimViewList: "getCombinedViewList",
};

export const COMMON_AUTODESK_URL_TO_CACHE_FOR_SAFARI = [
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/environments/SharpHighlights_irr.logluv.dds",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/environments/SharpHighlights_mipdrop.logluv.dds",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/PDF/PDF.worker.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/PDF/PDF.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/PDF/PDF.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/PDF/PDF.worker.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/lmvworker.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/lmvworker.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/locales/ja/allstrings.json",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/locales/en/allstrings.json",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/ui/powered-by-autodesk-blk-rgb.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/AEC/AEC.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/AEC/AEC.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/ViewCubeUi/ViewCubeUi.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/ViewCubeUi/ViewCubeUi.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/MixpanelProvider/MixpanelProvider.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/MixpanelProvider/MixpanelProvider.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/locales/ja/allstrings.json",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/locales/en/allstrings.json",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/locales/ja/VCcrossRGBA8small.dds",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VCedge1.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VChome.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VCarrows.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VCcontext.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VChomeS.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VCarrowsS0.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VCarrowsS1.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VCcontextS.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/MemoryLimited/MemoryLimited.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/MemoryLimited/MemoryLimited.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/environments/boardwalk_irr.logluv.dds",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/environments/boardwalk_mipdrop.logluv.dds",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VCcompass-pointer-b.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VCcompass-base.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/Section/Section.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/Section/Section.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/LayerManager/LayerManager.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/LayerManager/LayerManager.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/BoxSelection/BoxSelection.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/BoxSelection/BoxSelection.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/CompGeom/CompGeom.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/CompGeom/CompGeom.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/MaterialConverterPrism/MaterialConverterPrism.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/MaterialConverterPrism/MaterialConverterPrism.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/fonts/tinos-v24-latin_latin-ext-regular.woff",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/fonts/cousine-v27-latin_latin-ext-regular.woff",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/fonts/arimo-v29-latin_latin-ext-regular.woff",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/fonts/FoxitDingbats.woff",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/fonts/FoxitSymbol.woff",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Bold.woff2",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Book.woff2",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Bold.woff",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Book.woff",
  "https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Bold.ttf",
  "https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Book.ttf",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Light.woff2",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Light.woff",
  "https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Light.ttf",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Regular.woff",
  "https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Regular.ttf",
];

export const COMMON_AUTODESK_URL_TO_CACHE_FOR_CHROME = [
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/environments/SharpHighlights_irr.logluv.dds",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/environments/SharpHighlights_mipdrop.logluv.dds",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/PDF/PDF.worker.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/PDF/PDF.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/PDF/PDF.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/PDF/PDF.worker.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/lmvworker.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/lmvworker.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/locales/ja/allstrings.json",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/locales/en/allstrings.json",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/ui/powered-by-autodesk-blk-rgb.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/AEC/AEC.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/AEC/AEC.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/ViewCubeUi/ViewCubeUi.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/ViewCubeUi/ViewCubeUi.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/MixpanelProvider/MixpanelProvider.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/MixpanelProvider/MixpanelProvider.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/locales/ja/allstrings.json",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/locales/en/allstrings.json",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/locales/ja/VCcrossRGBA8small.dds",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VCedge1.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VChome.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VCarrows.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VCcontext.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VChomeS.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VCarrowsS0.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VCarrowsS1.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VCcontextS.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/MemoryLimited/MemoryLimited.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/MemoryLimited/MemoryLimited.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/environments/boardwalk_irr.logluv.dds",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/environments/boardwalk_mipdrop.logluv.dds",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VCcompass-pointer-b.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/textures/VCcompass-base.png",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/Section/Section.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/Section/Section.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/LayerManager/LayerManager.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/LayerManager/LayerManager.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/BoxSelection/BoxSelection.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/BoxSelection/BoxSelection.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/CompGeom/CompGeom.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/CompGeom/CompGeom.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/MaterialConverterPrism/MaterialConverterPrism.min.js",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/extensions/MaterialConverterPrism/MaterialConverterPrism.min.js.map",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/fonts/tinos-v24-latin_latin-ext-regular.woff",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/fonts/cousine-v27-latin_latin-ext-regular.woff",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/fonts/arimo-v29-latin_latin-ext-regular.woff",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/fonts/FoxitDingbats.woff",
  "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.100.0/res/fonts/FoxitSymbol.woff",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Bold.woff2",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Book.woff2",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Bold.woff",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Book.woff",
  "https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Bold.ttf",
  "https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Book.ttf",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Light.woff2",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Light.woff",
  "https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Light.ttf",
  "https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Regular.woff",
  "https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Regular.ttf",
];

export const TINY_CLOUD_RESOURCE = [];

export const AUTODESK_DB_RESOURCE = [
  "objects_ids.json.gz",
  "objects_attrs.json.gz",
  "objects_avs.json.gz",
  "objects_vals.json.gz",
  "objects_offs.json.gz",
  "objects_viewables.json.gz",
];
