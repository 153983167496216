import { Flex } from "@chakra-ui/react";
import { ColorPickerMicrosoft } from "components/base";
import { NumberInputFormat } from "components/input";
import { iNumberInputFormatHandle } from "components/input/NumberInputFormat";
import IconTooltip from "components/ui/IconTooltip";
import SelectTooltip from "components/ui/SelectTooltip";
import { letterPosition } from "constants/document";
import { fontFamilyDocument } from "constants/documentTemplate";
import { LinkedDataField, StyleType, TextPosition } from "constants/enum";
import { CellType, TableStyleType } from "interfaces/models/component";
import { debounce } from "lodash-es";
import Dropdown from "pages/document/template-page/Dropdown";
import { DEFAULT_TEXT_COLOR } from "pages/document/template-page/hooks";
import SectionField from "pages/document/template-page/SectionField";
import { memo, useMemo, useRef } from "react";

const DELAY_TYPING = 300;

export const STEP_TAB = 8;

interface iProps {
  isTable: boolean;
  style: TableStyleType | undefined;
  zoomRatio: number;
  selectedCells: CellType[];
  handleUpdateStyle: (value: any) => void;
  onOpenAttachLink: () => void;
}

const TextStyle = ({
  isTable,
  style,
  selectedCells,
  handleUpdateStyle,
  onOpenAttachLink,
}: iProps) => {
  const isNotFieldComment =
    selectedCells?.[0]?.cellLinkedData?.field !==
    LinkedDataField.BLACKBOARD.COMMENT_MANAGE_EXECUTE;

  const fontSizeInputRef = useRef<iNumberInputFormatHandle>(null);
  const fontSizeDefault = `${Math.round(style?.fontSize || 14)}`;

  const fontStyles = useMemo(() => {
    return [
      {
        label: "太字",
        src: "/img/bold.svg",
        name: StyleType.BOLD,
        value: style?.bold,
      },
      {
        label: "斜体",
        src: "/img/italicized.svg",
        name: StyleType.ITALIC,
        value: style?.italic,
      },
      {
        label: "下線",
        src: "/img/underlined.svg",
        name: StyleType.UNDERLINE,
        value: style?.underline,
      },
      {
        label: "取り消し線",
        src: "/img/line-throught.svg",
        name: StyleType.LINE_THROUGH,
        value: style?.lineThrough,
      },
    ];
  }, [style]);

  const handleChangeColor = debounce((color: string, name?: string) => {
    if (name) {
      handleUpdateStyle({ [name]: color });
    }
  }, 100);

  const handleChangeFontFamily = (value: string) => {
    handleUpdateStyle({ fontFamily: value });
  };

  const handleChangeFontSize = (prevValue: string, valueNumber: number) => {
    if (isNaN(valueNumber)) return;

    handleUpdateStyle({
      fontSize: valueNumber,
    });
  };

  const handleBlurFontSize = () => {
    const value = fontSizeInputRef?.current?.value;
    if (!value) {
      fontSizeInputRef?.current?.setValue(fontSizeDefault);
    }
  };

  const handleChangeTextStyle =
    (name: string, value?: boolean | string) => () => {
      handleUpdateStyle({
        [name]: typeof value !== "string" ? !value : value,
      });
    };

  const handleTab = (name: string) => () => {
    let newTab = style?.tab ?? 0;

    if (name === StyleType.TAB_LEFT) {
      newTab += STEP_TAB;
    }
    if (name === StyleType.TAB_RIGHT) {
      newTab -= STEP_TAB;
    }

    newTab = newTab < 0 ? 0 : newTab;
    handleUpdateStyle({ tab: newTab });
  };

  const handleShowAttachLink = () => {
    if (selectedCells.length) {
      onOpenAttachLink();
    }
  };

  return (
    <>
      <SectionField title="テキストのスタイル" mb="0.4rem">
        <SelectTooltip label="フォント">
          <Dropdown
            options={fontFamilyDocument}
            onChange={handleChangeFontFamily as any}
            height="4rem"
            w="100%"
            value={style?.fontFamily ?? "1"}
            border="1px solid #A3A3A3"
            fontSize="1.4rem"
            fontWeight={500}
            boxShadow="none"
            color="#171717"
            iconArrow="/img/icon-arrow-down-small.svg"
          />
        </SelectTooltip>
      </SectionField>

      <Flex flexDirection={{ base: "column", lg: "row" }} mt="1rem">
        <Flex>
          <ColorPickerMicrosoft
            mr="1rem"
            name="color"
            onChangeComplete={(color) => handleChangeColor(color, "color")}
            value={style?.color ?? DEFAULT_TEXT_COLOR}
            tooltip="テキストの色"
          />
          <SelectTooltip h="100%" w="8rem" label="フォント サイズ">
            <NumberInputFormat
              ref={fontSizeInputRef}
              name="fontSize"
              initValue={fontSizeDefault}
              min={1}
              max={50}
              step={2}
              h="100%"
              textAlign="left"
              onChangeStyle={handleChangeFontSize}
              onBlur={handleBlurFontSize}
              delayTyping={DELAY_TYPING}
            />
          </SelectTooltip>
        </Flex>
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          h="4rem"
          m={{ base: 0, md: ".5rem 0 0 -1rem", lg: 0 }}
          ml="1rem !important"
        >
          {fontStyles.map((item) => (
            <IconTooltip
              key={item.label}
              label={item.label}
              src={item.src}
              mx=".7rem"
              onClick={handleChangeTextStyle(item.name, item.value)}
            />
          ))}
        </Flex>
      </Flex>

      {isNotFieldComment && (
        <Flex
          justifyContent="space-around"
          mt="1rem"
          border="1px solid #A3A3A3"
          borderRadius={4}
          backgroundColor="#fff"
          width="fit-content"
          h="4rem"
        >
          <Flex
            justifyContent="space-around"
            alignItems="center"
            h="4rem"
            gap="1.5rem"
            padding="0rem 1rem"
            //borderRight="1px solid #A3A3A3"
          >
            {letterPosition.map((item) => (
              <IconTooltip
                key={item.label}
                label={item.label}
                src={item.src}
                bgColor={
                  (style?.justifyContent || TextPosition.CENTER) ===
                  item.position
                    ? "#bcd8f0"
                    : ""
                }
                onClick={handleChangeTextStyle(
                  StyleType.JUSTIFY_CONTENT,
                  item.position
                )}
              />
            ))}
          </Flex>

          {/* Temporarily hide command below */}
          {/*<Flex
            position="relative"
            justifyContent="space-around"
            alignItems="center"
            h="4rem"
            flex="3"
          >
            <Flex flex="2" justifyContent="space-around">
              <IconTooltip
                w="1.7rem"
                h="1.7rem"
                label="インデントを増やす"
                src="/img/tab-right.svg"
                onClick={handleTab(StyleType.TAB_LEFT)}
              />
              <IconTooltip
                w="1.7rem"
                h="1.7rem"
                label="インデントを減らす"
                src="/img/tab-left.svg"
                onClick={handleTab(StyleType.TAB_RIGHT)}
              />
            </Flex>
            {isTable && (
              <>
                <Flex
                  flex="1"
                  alignItems="center"
                  h="100%"
                  w="100%"
                  pl=".7rem"
                  borderLeft="1px solid #A3A3A3"
                >
                  <IconTooltip
                    w="1.7rem"
                    h="1.7rem"
                    label="リンク"
                    src="/img/attach.svg"
                    onClick={handleShowAttachLink}
                  />
                </Flex>
              </>
            )}
          </Flex>*/}
        </Flex>
      )}
    </>
  );
};

export default memo(TextStyle);
