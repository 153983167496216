import {
  Flex,
  Grid,
  GridItem,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  Stack,
} from "@chakra-ui/react";
import Header from "./Header";
import Footer from "./Footer";
import React, { useCallback, useState } from "react";
import Item, { ItemData } from "./Item";
import { isArray } from "lodash-es";
import EmptyData from "components/EmptyData";
import CommonModal from "components/ui/CommonModal";
import TrashIcon from "components/icon/TrashIcon";
import { logDev } from "utils/logs";

const GAP = 8;
const DEFAULT_ZOOM = 3;

export interface ContainerProps {
  isOpen: boolean;
  items?: ItemData[];
  aspectRatio?: number | string;
  selected?: string | string[];
  isMultiple?: boolean;
  title?: any;
  navBarTitle?: string;
  isLoading?: boolean;
  onSelect?(selected: string | string[] | undefined): void;
  onDeleteImage: (item: ItemData) => Promise<boolean | undefined>;
  onBack(): void;
}

const Container: React.FC<ContainerProps> = ({
  isOpen,
  items,
  aspectRatio = 222 / 125,
  selected,
  isMultiple,
  title,
  isLoading,
  onDeleteImage,
  onSelect,
  onBack,
}) => {
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [state, setState] = useState(selected);
  const stateIsArray = isArray(state);
  const stateSet = stateIsArray ? new Set<string>(state) : new Set<string>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteItem, setDeleteItem] = useState<ItemData>();

  const handleSelect = useCallback(
    (item: ItemData) => {
      if (isMultiple) {
        let value = isArray(state) ? state : [];
        value = value.includes(item.id)
          ? value.filter((i) => i !== item.id)
          : [...value, item.id];
        setState?.(value);
      } else {
        const value = state === item.id ? null : item.id;
        setState?.(value);
      }
    },
    [isMultiple, state]
  );

  const onDelete = useCallback(async (item: ItemData) => {
    setDeleteItem(item);
  }, []);

  const handleDelete = useCallback(async () => {
    if (!deleteItem) {
      return;
    }
    try {
      setIsDeleting(true);
      const result = await onDeleteImage(deleteItem);
      if (result) {
        setDeleteItem(undefined);
      }
    } catch (err) {
      logDev(err);
    } finally {
      setIsDeleting(false);
    }
  }, [deleteItem, onDeleteImage]);

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onBack}>
        <ModalOverlay />
        <ModalContent
          display="flex"
          flexDirection="column"
          width="80vw"
          height="91rem"
          maxWidth="120rem"
          maxHeight="80vh"
        >
          <Header onBack={onBack} title={title} />
          <Flex
            flexGrow={1}
            borderTop="2px solid var(--primary-border-color)"
            borderBottom="2px solid var(--primary-border-color)"
            p={{ base: "2rem", lg: `${GAP - zoom}rem` }}
            alignItems="flex-start"
            overflowY="auto"
          >
            <Flex h="auto" w="100%">
              {items?.length ? (
                <Grid
                  w="100%"
                  templateColumns={`repeat(${zoom}, 1fr)`}
                  gap={{ base: "2rem", lg: `${GAP - zoom}rem` }}
                  h="auto"
                >
                  {items?.map((item) => {
                    return (
                      <GridItem minW="0" key={item.id} w="100%" h="100%">
                        <Item
                          isActive={
                            isMultiple && stateIsArray
                              ? stateSet.has(item.id)
                              : state === item.id
                          }
                          item={item}
                          onClick={() => handleSelect(item)}
                          onDelete={onDelete}
                          aspectRatio={aspectRatio}
                        />
                      </GridItem>
                    );
                  })}
                </Grid>
              ) : (
                <Flex
                  w="100%"
                  h="100%"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  {isLoading ? (
                    <Flex>
                      <Spinner />
                    </Flex>
                  ) : (
                    <EmptyData />
                  )}
                </Flex>
              )}
            </Flex>
          </Flex>
          <Footer
            isDisabled={state === selected}
            selected={state}
            zoom={zoom}
            onZoom={setZoom}
            onBack={onBack}
            onSelect={() => onSelect?.(state)}
          />
        </ModalContent>
      </Modal>
      <CommonModal
        size="7xl"
        isOpen={!!deleteItem}
        title={
          <Flex
            alignItems="center"
            justifyContent="center"
            color="#171717"
            gap="0.2rem"
          >
            <TrashIcon width="2.4rem" height="2.4rem" />
            <Text>撮影履歴から写真を削除しますか？</Text>
          </Flex>
        }
        content={
          <Stack
            gap="2rem"
            borderTop="1px solid rgba(212, 212, 212, 0.6)"
            padding="4rem 0"
          >
            <Text fontSize="1.6rem" margin="auto">
              この操作を取り消すことはできません。
            </Text>
            <Text fontSize="1.6rem" margin="auto">
              本当に写真を削除してよろしいですか？
            </Text>
          </Stack>
        }
        modalCloseButtonProps={{
          display: "none",
        }}
        onClose={() => setDeleteItem(undefined)}
        okeLabel="削除"
        cancelLabel="キャンセル"
        modalContentProps={{
          padding: "1.5rem 0 0 0",
          maxW: "500px",
        }}
        modalBodyProps={{
          padding: "0 2rem",
        }}
        modalHeaderProps={{ textAlign: "center" }}
        okeButtonProps={{
          variant: "outline",
          color: "#EC3B3B",
          px: "1.6rem",
          w: "10rem",
          height: "4rem",
          fontWeight: "600",
          borderRadius: "4px",
          borderColor: "#A3A3A3",
          isLoading: isDeleting,
          leftIcon: <TrashIcon width="1.8rem" height="1.8rem" />,
        }}
        cancelButtonProps={{
          variant: "outline",
          color: "#009BE0",
          w: "10rem",
          height: "4rem",
          borderRadius: "4px",
          fontWeight: "600",
          borderColor: "#A3A3A3",
          isLoading: isDeleting,
        }}
        modalFooterProps={{
          borderTop: "1px solid rgba(212, 212, 212, 0.6)",
          flexDirection: "row-reverse",
          gap: "1rem",
          justifyContent: "flex-start",
          paddingY: "1.5rem",
        }}
        onOke={handleDelete}
      />
    </>
  );
};

export default Container;
