import { authApi, userApi } from "apiClient/v2";

import { EXP_TOKEN_COOKIE, ID_TOKEN_COOKIE } from "constants/storage";
import { User } from "interfaces/models/user";
import store from "redux/store";
import { setToken } from "redux/userSlice";
import { logError } from "./logs";
import { getLocalStorage, setLocalStorage } from "./storage";
import { getLocalStorageUser, logout, storageUser } from "./user";

export const EXPIRE_TIME_TOKEN = 5 * 60 * 1000;

export const getCurrentUser = async (
  callback: (error: any, data: any) => void
) => {
  const user: User = getLocalStorage("userInfo");

  if (!user?.id) {
    return callback(null, null);
  }

  try {
    const req = await userApi.handleGetProfile();

    return callback(null, req);
  } catch (err) {
    return callback(err, null);
  }
};

export const checkTokenExpired = () => {
  const exp = getLocalStorage(EXP_TOKEN_COOKIE, null);
  if (isNaN(Number(exp)) || !exp) {
    return true;
  }

  // Check exp time less than 10 minutes from current time then refresh token
  return Number(exp) - 2 * 5 * 60 * 1000 < Date.now() && !!exp;
};

export const getCurrentUserAndIdToken = () => {
  const user = getLocalStorageUser();
  const token = getLocalStorage(ID_TOKEN_COOKIE, null);

  return { user, token };
};

export const doRefreshToken = async () => {
  try {
    const isTokenExpired = checkTokenExpired();
    if (isTokenExpired) {
      const response = await authApi.refreshTokenFromPlanet();
      // eslint-disable-next-line no-console
      console.log("doRefreshToken Response", response);
      if (!response) {
        logout();

        return;
      }
    }

    const userData = await (
      await fetch(
        `${process.env.REACT_APP_API_HOST_URL}/v1/auth/current-user`,
        {
          credentials: "include",
        }
      )
    ).json();
    const user = userData?.data?.user;
    const tokens = userData?.data?.tokens;

    if (!user || !tokens) {
      logout();

      return;
    }

    setLocalStorage(EXP_TOKEN_COOKIE, Date.now() + Number(tokens.expIn) * 1000);
    setLocalStorage(ID_TOKEN_COOKIE, tokens.idToken);
    storageUser(user);
    store.dispatch(setToken(tokens.idToken));

    return user;
  } catch (err) {
    logError(err);
    logout();
  }
};

export const decodeJwt = (token: string) => {
  const jwtParts = token.split(".");
  const jwtPayload = jwtParts[1];
  const decodedPayload = window.atob(jwtPayload);
  const jsonPayload = JSON.parse(decodedPayload);

  return jsonPayload;
};
