import { message } from "components/base";
import { DISPLAY_MODE, VIEW_ROLE } from "constants/forge";
import useFamilyInstance from "hooks/useFamilyInstance";
import { Level, Sheet } from "interfaces/models";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLevels, setLevelSelected } from "redux/forgeViewerSlice";
import { setDataProjectDetail } from "redux/projectSlice";
import { RootState } from "redux/store";
import { isEqual as isEqualVal, cloneDeep } from "lodash-es";

import { getCurrentViewer } from "utils/forge";
import {
  calculatePositionOnSheet,
  expandBoundingBoxByScale,
  find2DBounds,
  ___sheetTransformMatrix,
} from "utils/forge/forge2d";
import { getNetworkStatus } from "utils/indexedDb";
import { getPropertiesByDbIds, getAllDbIds } from "utils/forge/viewerData";
import { bimFileApi } from "apiClient/v2";
import { View } from "apiClient/v2/forgeApi";

const VIEWPORT_DATA_NOT_SUITABLE =
  "からピンの位置情報を取得できません。管理者へ確認お願いします。";

const MISSING_VIEWPORT_MESSAGE =
  "にビューポートが存在しないため、ピンの位置はずれることがあります。";
const INCORRECT_SHEET_MESSAGE =
  "ではピンの位置がずれています。2Dシートに図面枠が入っていない可能性があるため、再度シートを確認お願いします。";

const ERROR_ITEM_RATE = 0.1;
const SCALE_BOUNDING = 0.15;
const MIN_ITEM_INVALID = 100;

interface Props {
  selectedView?: View;
}

export default function useCheckSheet(props: Props) {
  const { selectedView } = props;
  const dispatch = useDispatch();
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );

  const {
    displayMode,
    levelSelected,
    isLoadedFamilyInstances,
    isLoadedViewerModelData,
    isLoadedSheetTransformRatio,
  } = useSelector((state: RootState) => state.forgeViewer);
  const { familyInstances } = useFamilyInstance();

  const currentDisplayMode = useMemo(() => {
    if (selectedView) {
      return selectedView?.role === VIEW_ROLE.ROLE_2D
        ? DISPLAY_MODE["2D"]
        : DISPLAY_MODE["3D"];
    }

    return displayMode;
  }, [selectedView, displayMode]);

  const guid = useMemo(() => {
    if (selectedView) {
      return selectedView?.sheetGuid;
    }
    const is2d = currentDisplayMode === DISPLAY_MODE["2D"];

    return is2d
      ? levelSelected.sheetGuid ||
          levelSelected.sheets?.[0]?.guid ||
          levelSelected.guid ||
          ""
      : levelSelected.guid;
  }, [
    currentDisplayMode,
    selectedView,
    levelSelected.guid,
    levelSelected.sheetGuid,
    levelSelected.sheets,
  ]);

  const currentSheet = useMemo(() => {
    if (selectedView) {
      return dataProjectDetail?.sheetData?.find((sheet) => sheet.guid === guid);
    }
    const projectDetail = cloneDeep(dataProjectDetail);
    const levelData = projectDetail?.levelData || {};
    const currentLevelData: Level = levelData[levelSelected.label || ""];
    if (!currentLevelData) {
      return;
    }
    const sheetData: Sheet[] = currentLevelData.sheets || [];

    return sheetData.find((sheet) => sheet.guid === guid);
  }, [dataProjectDetail, guid, selectedView, levelSelected.label]);

  const handleIncorrectSheet = useCallback(
    async (isIncorrect: boolean) => {
      const projectDetail = cloneDeep(dataProjectDetail!);

      // update level data
      const levelData = projectDetail.levelData || {};
      let newLevelData = null;
      Object.keys(levelData).forEach((levelId) => {
        const currentLevelData: Level = levelData[levelId];
        if (!currentLevelData) {
          return;
        }
        let levelSheets: Sheet[] = currentLevelData.sheets || [];
        const currentSheet = levelSheets.find((sheet) => sheet.guid === guid);
        if (!currentSheet || currentSheet.isMissingViewport) {
          return;
        }
        levelSheets = levelSheets.map((sheet) => {
          if (sheet.guid === guid) {
            sheet.isIncorrectViewport = isIncorrect;
          }

          return sheet;
        });
        currentLevelData.sheets = levelSheets;
        levelData[levelId] = currentLevelData;

        if (currentLevelData.guid === levelSelected.guid) {
          newLevelData = currentLevelData;
        }
      });
      if (newLevelData) {
        dispatch(setLevelSelected(newLevelData));
      }

      projectDetail.levelData = levelData;

      // update sheet data
      const sheetData = projectDetail.sheetData || [];
      projectDetail.sheetData = sheetData.map((sheet) => {
        if (sheet.guid === guid) {
          return {
            ...sheet,
            isIncorrectViewport: isIncorrect,
          };
        }

        return sheet;
      });
      dispatch(setDataProjectDetail(projectDetail));
      dispatch(setLevels(Object.values(levelData)));
      const isOnline = await getNetworkStatus();
      if (isOnline) {
        bimFileApi.updateSheetLevel({
          id: projectDetail.id,
          levelData,
          sheetData: projectDetail.sheetData,
          defaultBimPathId: projectDetail.defaultBimPathId,
          name: projectDetail.name,
        });
      }
    },
    [dataProjectDetail, dispatch, guid, levelSelected.guid]
  );
  const lastSheetGuidRef = useRef("");
  useLayoutEffect(() => {
    if (isLoadedViewerModelData) {
      lastSheetGuidRef.current = currentSheet?.guid || "";
    }
  }, [isLoadedViewerModelData]);

  useEffect(() => {
    if (
      currentDisplayMode !== DISPLAY_MODE["2D"] ||
      !guid ||
      !isLoadedViewerModelData ||
      !isLoadedSheetTransformRatio ||
      !currentSheet ||
      !isLoadedFamilyInstances
    ) {
      return;
    }
    if (lastSheetGuidRef.current !== currentSheet.guid) {
      return;
    }
    if (currentSheet?.isMissingViewport) {
      message.warning(`「${currentSheet.name}」${MISSING_VIEWPORT_MESSAGE}`);

      return;
    }

    const viewer = getCurrentViewer();
    if (!viewer) {
      return;
    }
    const fragList = viewer.model.getFragmentList();
    const instanceTree = viewer.model.getInstanceTree();
    if (!fragList || !instanceTree) {
      return;
    }
    const dbIds = getAllDbIds();
    if (dbIds.length === 0) {
      message.warning(`「${currentSheet.name}」${INCORRECT_SHEET_MESSAGE}`);
      handleIncorrectSheet(true);

      return;
    }
    if (!___sheetTransformMatrix) {
      message.warning(`「${currentSheet.name}」${VIEWPORT_DATA_NOT_SUITABLE}`);
      handleIncorrectSheet(true);

      return;
    }

    const maxCheck = dbIds.length;
    let maxInvalid = Math.min(dbIds.length * ERROR_ITEM_RATE, MIN_ITEM_INVALID);
    console.log("Max invalid", maxInvalid, maxCheck);
    const checkDbIds = dbIds.slice(0, maxCheck);
    (async () => {
      console.time("Total time check sheet");
      const instanceProperties = await getPropertiesByDbIds(checkDbIds);
      let isIncorrect = false;
      for (let i = 0; i < instanceProperties.length; i++) {
        const externalId = instanceProperties[i].externalId;
        const instance = familyInstances[externalId || ""];
        if (!instance) {
          continue;
        }
        const mappedPosition = calculatePositionOnSheet(instance.position);

        const currentPosBounds = find2DBounds(fragList, instanceTree, dbIds[i]);
        const minPos = calculatePositionOnSheet(instance.bounds.min);
        const maxPos = calculatePositionOnSheet(instance.bounds.max);
        const boundingExpandBy3d = new THREE.Box3(minPos, maxPos);

        const currentPosition = currentPosBounds.getCenter();
        const clientPosition = viewer.worldToClient(mappedPosition);
        const hitTest = viewer.impl.hitTest(clientPosition.x, clientPosition.y);
        expandBoundingBoxByScale(boundingExpandBy3d, SCALE_BOUNDING);
        // check dbId get by position s3 and current bounding box contain position generate from s3 position
        if (
          !currentPosition.equals(new THREE.Vector3(0, 0, 0)) &&
          !isEqualVal(instance.position, { x: 0, y: 0, z: 0 }) &&
          hitTest?.dbId !== dbIds[i] &&
          !boundingExpandBy3d.containsPoint(currentPosition)
        ) {
          maxInvalid--;
        }

        if (maxInvalid <= 0) {
          message.warning(`「${currentSheet.name}」${INCORRECT_SHEET_MESSAGE}`);
          isIncorrect = true;
          break;
        }
      }
      handleIncorrectSheet(isIncorrect);
      console.timeEnd("Total time check sheet");
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    guid,
    currentDisplayMode,
    familyInstances,
    isLoadedFamilyInstances,
    isLoadedViewerModelData,
    isLoadedSheetTransformRatio,
  ]);
}
