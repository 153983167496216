import { PressureOptKey } from "components/containers/inspectionSetting/containers/templateBuilder/fields/device-pressure/previewer";
import dayjs from "dayjs";
import { EventEmitter } from "eventemitter3";
import { uuid } from "utils/common";
import { base64ToFile } from "utils/file";

export enum ReceivedDeviceType {
  PRESSURE = "pressure",
}

const formatDate = "YYYY/MM/DD HH:mm:ss";

export enum SupportHandlers {
  requestDeviceDataAtNeptune = "requestDeviceDataAtNeptune",
  requestPhotoDataAtNeptune = "requestPhotoDataAtNeptune",
}

export enum ERRORS {
  CANNOT_CONNECT = "CANNOT_CONNECT",
  NO_RESPONSE = "NO_RESPONSE",
}
/**
 * Example:
 * {
 *    "type": "1",
 *    "identify_info": "Base64 encoded",
 *    "result": {
 *        "start_date": 1723939205,
 *        "start_value": "10MPa",
 *        "end_date": 1729159942,
 *        "end_value": "15MPa"
 *    }
 * }
 */
interface ReceiveDataResponse {
  type: string;
  identify_info: string;
  result: {
    start_date: number;
    start_value: string;
    end_date: number;
    end_value: string;
  };
}

export interface PressureResponse {
  [PressureOptKey.START_DATE]: string;
  [PressureOptKey.START_VALUE]: string;
  [PressureOptKey.END_DATE]: string;
  [PressureOptKey.END_VALUE]: string;
}

export interface PhotoResponse {
  file: null | File;
}

class Peripheral {
  protected TIME_OUT = 30; // 30s

  protected eventEmitter = new EventEmitter();
  protected waitings: any = {};

  public constructor() {
    this.registerListener();
  }

  protected registerListener() {
    window.receiveDeviceDataAtNeptune = (message: any) => {
      this.eventEmitter.emit(
        SupportHandlers.requestDeviceDataAtNeptune,
        JSON.parse(message)
      );
    };
    window.receivePhotoDataAtNeptune = (message: any) => {
      this.eventEmitter.emit(
        SupportHandlers.requestPhotoDataAtNeptune,
        JSON.parse(message)
      );
    };
  }

  protected formatDate(date: number) {
    return dayjs(date * 1000).format(formatDate);
  }

  public hasMessageHandlers(key: string) {
    return !!window.webkit?.messageHandlers[key];
  }

  protected callHandler<T = any>(
    key: SupportHandlers,
    payload?: Record<string, any>
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      if (!this.hasMessageHandlers(key)) return reject(ERRORS.CANNOT_CONNECT);

      if (this.waitings[key]) {
        clearTimeout(this.waitings[key].timeOut);
        this.waitings[key].reject(ERRORS.NO_RESPONSE);
      }

      this.eventEmitter.once(key, resolve);

      this.waitings[key] = {
        reject,
        timeOut: setTimeout(() => {
          reject(ERRORS.NO_RESPONSE);
        }, this.TIME_OUT * 1000),
      };

      this.postMessage(key, payload);
    });
  }

  protected postMessage(key: any, payload?: Record<string, any>) {
    return window.webkit?.messageHandlers[key]!.postMessage(
      JSON.stringify(payload)
    );
  }

  public async pressure(): Promise<PressureResponse> {
    const { result } = await this.callHandler<ReceiveDataResponse>(
      SupportHandlers.requestDeviceDataAtNeptune,
      { identify_info: uuid(), type: "1" }
    );

    return {
      [PressureOptKey.START_DATE]: this.formatDate(result.start_date),
      [PressureOptKey.START_VALUE]: result.start_value,
      [PressureOptKey.END_DATE]: this.formatDate(result.end_date),
      [PressureOptKey.END_VALUE]: result.end_value,
    };
  }

  public async camera(): Promise<PhotoResponse> {
    const { data } = await this.callHandler<any>(
      SupportHandlers.requestPhotoDataAtNeptune,
      { identify_info: uuid() }
    );

    const mimeType = "data:image/jpeg;base64";
    const fileName = `${new Date().getTime()}.jpg`;

    /**
     * Hard code prepend data:image to "data" field
     * because the response of device is incorrect base64 string
     */
    return {
      file: data
        ? base64ToFile(
            !data.includes(mimeType) ? `${mimeType},${data}` : data,
            fileName
          )
        : null,
    };
  }

  public isCameraConnected() {
    return this.hasMessageHandlers(SupportHandlers.requestPhotoDataAtNeptune);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Peripheral();
