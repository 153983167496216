import { documentItemApi, documentTemplateApi } from "apiClient/v2";
import { DocumentCategoryKey, ModalType } from "constants/enum";
import { Operation, StoreName } from "constants/serviceWorker";
import { MessageType } from "constants/websocket";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";

import { WSMessage } from "interfaces/models/websocket";
import {
  isSelfInspectionTemplate,
  reMappingWidthDocItemAfterAddDoc,
} from "models/documentCategory";
import { saveIndexDB } from "pages/forge-viewer/hooks/useSyncData";
import { Dispatch } from "react";
import {
  addDocumentTemplate,
  deleteDocumentTemplate,
  removeDocumentItems,
  setAllDocItemAndDocCategory,
  setBlackboardWithIndex,
  setIsLoadingDocument,
  updateDocumentCategories,
  updateDocumentCategory,
  updateDocumentGroup,
  updateDocumentItem,
  updateDocumentTemplate,
} from "redux/documentSlice";
import store from "redux/store";
import { updateForgeWhenSelectCategory } from "utils/document";
import { arrayToObject } from "utils/object";
import {
  getRestMethodUpdateSubItem,
  mergeDataChangeVisibleItem,
  mergeDataUpdateDocSubItem,
  mergeDataUpdateSocket,
  transformDataAddDocItem,
} from "utils/transformDataSocket";
import { removeDocItem } from "models/documentItem";
import { setModalType } from "redux/forgeViewerSlice";
import { isArray } from "lodash-es";
import { setTasks, updateTaskByIndex } from "redux/taskSlice";
import { isLatestByUpdatedTime } from "utils/date";

type Props = {
  dispatch: Dispatch<any>;
};

export default class ReceiveDataSocketHelper {
  dispatch: Dispatch<any>;
  constructor(params: Props) {
    this.dispatch = params.dispatch;
  }

  getDocumentState() {
    return store.getState().document;
  }
  getTaskState() {
    return store.getState().task;
  }

  handleCrudSubItem(wsMsg: WSMessage) {
    const documentState = this.getDocumentState();
    const { type, data, docCategoryId, docItemId, subItemId } = wsMsg;
    const docs = documentState.documentCategories;
    const doc = docs.find((doc) => doc.id === docCategoryId);
    const docItem = doc?.documentItems?.find((item) => item.id === docItemId);
    if (!docItem || !isLatestByUpdatedTime(docItem, data)) {
      return;
    }
    const isDeleteSubItem = type === MessageType.DELETE_SUB_ITEM;
    if (isDeleteSubItem) {
      data.id = subItemId;
    }
    const mergedData = mergeDataUpdateDocSubItem(
      structuredClone(docItem),
      data,
      type as MessageType
    );
    this.dispatch(updateDocumentItem(mergedData));
    const method = getRestMethodUpdateSubItem(type as MessageType);
    saveIndexDB(StoreName.SUB_ITEMS, method, docItem);
    if (isDeleteSubItem) {
      saveIndexDB(StoreName.DOCUMENT_ITEMS, Operation.Patch, docItem);
    }
  }

  handleUpdateDocGroup(docGroupId: string | undefined, data: any) {
    const documentState = this.getDocumentState();
    const group = documentState.documentGroups.find(
      (group) => group.id === docGroupId
    );
    if (!group || !isLatestByUpdatedTime(group, data)) return;

    const result = mergeDataUpdateSocket(group, data);
    this.dispatch(updateDocumentGroup(result));
    saveIndexDB(StoreName.DOCUMENT_GROUP, Operation.Patch, result);
  }

  handleUpdateDocItem(docItemId: string, data: Partial<DocumentItemDTO>) {
    const { documentItems } = this.getDocumentState();
    const documentItem = documentItems.find((node) => node.id === docItemId);
    if (!documentItem || !isLatestByUpdatedTime(documentItem, data)) return;
    const result = mergeDataUpdateSocket(documentItem, data);
    this.dispatch(updateDocumentItem(result));
    saveIndexDB(StoreName.DOCUMENT_ITEMS, Operation.Patch, result);
  }

  async handleChangeDocCategories(data: any) {
    const {
      documentTemplates,
      documentItems,
      documentCategories,
      documentCategorySelected,
    } = this.getDocumentState();
    const {
      categories = [],
      isNew,
    }: { categories: DocumentCategoryDTO[]; isNew: boolean } = data || {};
    const { dataProjectDetail } = store.getState().project;

    const mapDocumentCategories = arrayToObject(
      documentCategories,
      DocumentCategoryKey.ID
    );

    const newCategories: DocumentCategoryDTO[] = [];
    for (let i = 0; i < categories.length; i++) {
      const oldCategory = mapDocumentCategories[categories[i].id];

      if (!isLatestByUpdatedTime(oldCategory, categories[i])) continue;

      // add change data to index DB
      saveIndexDB(
        StoreName.DOCUMENT_CATEGORIES,
        isNew ? Operation.Post : Operation.Patch,
        categories[i]
      );
      // with case self inspection when add doc => we need refetch doc item
      const template = documentTemplates[categories[i]?.templateId];
      if (isNew && isSelfInspectionTemplate(template?.documentType)) {
        const documentItems = await documentItemApi.handleGetItemList({
          documentCategoryId: categories[i].id,
          level: categories[i].level,
          bimFileId: dataProjectDetail?.id,
        });
        // add new doc item to index db
        documentItems.map((documentItem) => {
          saveIndexDB(StoreName.DOCUMENT_ITEMS, Operation.Post, documentItem);
        });
        categories[i].documentItems = documentItems;
      }
      if (oldCategory) {
        const { documentItems, ...rest } = oldCategory;
        // only need merge attribute of document category
        categories[i] = mergeDataUpdateSocket(
          structuredClone(rest),
          categories[i]
        );
      }
      newCategories.push(categories[i]);
    }
    const { newDocumentCategories, newDocumentItems } =
      reMappingWidthDocItemAfterAddDoc(
        newCategories,
        documentCategories,
        documentItems,
        documentTemplates
      );
    this.dispatch(
      setAllDocItemAndDocCategory({
        documentCategories: newDocumentCategories,
        documentItems: newDocumentItems,
      })
    );
    newDocumentCategories.map((category) => {
      if (category.id === documentCategorySelected?.id) {
        updateForgeWhenSelectCategory(category);
      }
    });
  }

  async handleChangeDocCategory(
    docCategoryId: string,
    data: Partial<DocumentCategoryDTO>
  ) {
    this.handleChangeDocCategories({
      categories: [{ id: docCategoryId, ...data }],
      isNew: false,
    });
  }

  handleAddDocItem(data: any) {
    const {
      documentTemplates,
      documentItems: allDocumentItems,
      documentCategories,
    } = this.getDocumentState();

    const { documentItems = [] }: { documentItems: DocumentItemDTO[] } =
      data || {};

    if (!documentItems.length) return;

    const { cacheCategories, newDocumentCategories, newDocumentItems } =
      transformDataAddDocItem(
        documentItems,
        allDocumentItems,
        documentCategories,
        documentTemplates
      );

    this.dispatch(
      setAllDocItemAndDocCategory({
        documentCategories: newDocumentCategories,
        documentItems: newDocumentItems,
      })
    );
    documentItems.map((item) => {
      saveIndexDB(StoreName.DOCUMENT_ITEMS, Operation.Post, item);
    });

    cacheCategories.map((documentCategory) => {
      saveIndexDB(StoreName.DOCUMENT_CATEGORIES, Operation.Patch, {
        id: documentCategory.id,
        itemIds: documentCategory.itemIds,
        selectedExternalIds: documentCategory.selectedExternalIds,
      });
    });
  }

  handleDeleteDocItem(deleteItemIds: string[]) {
    const { documentItemSelected, documentCategories } =
      this.getDocumentState();

    this.dispatch(removeDocumentItems(deleteItemIds));

    const categories: DocumentCategoryDTO[] = removeDocItem(
      documentCategories,
      deleteItemIds
    );

    this.dispatch(updateDocumentCategories(categories));

    /**
     * If the user is selecting these document items on the right sidebar, the right sidebar
     * will auto close and document category will be selected, but the modal of that category don't
     * show on the right sidebar, so, we should be change modal type to display that.
     * NOTE: This code should be a useEffect in forgeviewer, why?:
     * - If modalType is DOC_ITEM and documentItemSelected is null, modalType should be
     * auto change to other value
     * - Why I don't do it? It'll give more bugs, this is should be apply after refactoring done.
     */
    deleteItemIds?.map((id: string) => {
      saveIndexDB(StoreName.DOCUMENT_ITEMS, Operation.Delete, { id });
      if (documentItemSelected?.id === id) {
        this.dispatch(setModalType(ModalType.DOC_CATEGORY));
      }
    });
    categories?.map((documentCategory) => {
      saveIndexDB(StoreName.DOCUMENT_CATEGORIES, Operation.Patch, {
        id: documentCategory.id,
        itemIds: documentCategory.itemIds,
        selectedExternalIds: documentCategory.selectedExternalIds,
      });
    });
  }

  handleChangeVisibleDocItem(data: any) {
    const { documentCategories } = this.getDocumentState();
    const {
      documentCategoryId,
      documentItemId,
      isVisible,
      isUpdateVisibleItem,
      updatedAt,
    } = data;

    const documentCategory = documentCategories.find(
      (node) => node.id === documentCategoryId
    );

    if (
      !documentCategory ||
      !isLatestByUpdatedTime(documentCategory, { updatedAt })
    )
      return;

    const { category, documentItem } = mergeDataChangeVisibleItem(
      documentCategory,
      documentItemId,
      isVisible
    );

    if (documentItem && !isLatestByUpdatedTime(documentItem, { updatedAt }))
      return;

    if (documentItem) {
      this.dispatch(
        updateDocumentItem({ ...documentItem, isUpdateVisibleItem })
      );

      documentItem.subItems?.map((subItem) => {
        saveIndexDB(StoreName.SUB_ITEMS, Operation.Patch, {
          id: subItem.id,
          isHidden: !isVisible,
        });
      });
    }
    this.dispatch(updateDocumentCategory(category));
    saveIndexDB(StoreName.DOCUMENT_CATEGORIES, Operation.Patch, {
      id: category.id,
      selectedExternalIds: category.selectedExternalIds,
    });
  }

  async fetchDocumentTemplate(templateId: string) {
    const { data: template } = await documentTemplateApi.getTemplate(
      templateId
    );

    if (!template?.id) return;

    const { documentTemplates } = this.getDocumentState();

    if (documentTemplates[templateId]) {
      this.dispatch(updateDocumentTemplate(template));
      saveIndexDB(StoreName.DOCUMENT_TEMPLATES, Operation.Patch, template);
    } else {
      this.dispatch(addDocumentTemplate(template));
      saveIndexDB(StoreName.DOCUMENT_TEMPLATES, Operation.Post, template);
    }
    this.dispatch(setIsLoadingDocument(true));
  }

  deleteDocumentTemplate(id: string) {
    this.dispatch(deleteDocumentTemplate(id));
    this.dispatch(setIsLoadingDocument(true));
    const ids = isArray(id) ? id : [id];
    ids.map((id) =>
      saveIndexDB(StoreName.DOCUMENT_TEMPLATES, Operation.Delete, { id })
    );
  }

  handleUpdateTask(data: any) {
    if (!data?.id) return;

    const { tasks } = this.getTaskState();
    const index = tasks.findIndex((task) => task.id === data.id);

    if (index === -1) return;

    if (!isLatestByUpdatedTime(tasks[index], data)) return;

    this.dispatch(updateTaskByIndex({ task: data, index }));
    saveIndexDB(StoreName.TASKS, Operation.Patch, data);
  }
  handleUpdateBlackboard(data: any) {
    const { dataBlackboards } = this.getDocumentState();
    const index = dataBlackboards.findIndex((node) => node.id === data.id);
    if (index !== -1 && !isLatestByUpdatedTime(dataBlackboards[index], data))
      return;
    this.dispatch(setBlackboardWithIndex({ blackboard: data, index }));
    saveIndexDB(StoreName.BLACKBOARDS, Operation.Patch, data);
  }

  handleTaskByDocumentItemId(data: any) {
    const { tasks } = this.getTaskState();
    const updates = arrayToObject(data, "id");

    this.dispatch(
      setTasks(
        tasks.map((task) => {
          if (!updates[task.id]) return task;
          const update = { ...task, ...updates[task.id] };
          saveIndexDB(StoreName.TASKS, Operation.Patch, updates[task.id]);

          return update;
        })
      )
    );
  }
}
