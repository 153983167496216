import {
  bimFileApi,
  documentCategoryApi,
  documentGroupApi,
  documentItemApi,
  documentTemplateApi,
} from "apiClient/v2";
import { encode } from "base-64";
import { message } from "components/base";
import { TaskModalHandleType } from "components/modal/TaskModal";
import { TYPE_USER } from "constants/app";
import {
  InspectionItemType,
  MapInspectionItemColor,
  ModalType,
  SystemModeType,
} from "constants/enum";
import {
  ALL_LEVEL_LABEL,
  CURRENT_LEVEL_KEY,
  DB_ID_COLOR_SELECTED_COLOR_DEFAULT,
  DISPLAY_MODE,
} from "constants/forge";
import { OPERATION } from "constants/task";
import { MessageType } from "constants/websocket";
import useChangeLevel from "hooks/useChangeLevel";
import useFamilyInstance from "hooks/useFamilyInstance";
import { useRoles } from "hooks/usePermission";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import {
  FilterDataType,
  Level,
  OperationCaptureKeyplanData,
} from "interfaces/models";
import { NeptuneArea, Space } from "interfaces/models/area";
import { DocumentGroup } from "interfaces/models/documentGroup";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { UserSetting } from "interfaces/models/user";
import { WSMessage } from "interfaces/models/websocket";
import { isEqual, isEmpty } from "lodash-es";
import {
  doMapDocumentCategories,
  isPhotoLedgerTemplate,
  isSelfInspectionTemplate,
} from "models/documentCategory";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setSyncDataOption } from "redux/appSlice";
import {
  fetchBlackBoardByCategoryId,
  setAllDocItemAndDocCategory,
  setDocumentCategories,
  setDocumentCategorySelected,
  setDocumentGroups,
  setDocumentGroupSelected,
  setDocumentItems,
  setDocumentItemSelected,
  setDocumentTemplates,
  setIsFetchingDocument,
  setIsLoadingDocument,
  setIsLoadingDocumentItem,
  toggleIsCaptureKeynoteByOperation,
} from "redux/documentSlice";
import {
  setIsLoadedSheetTransformRatio,
  setIsLoadedViewer,
  setIsShowArea,
  setLevels,
  setLevelSelected,
  setLoadedLevels,
  setModalType,
} from "redux/forgeViewerSlice";
import { setDataProjectDetail } from "redux/projectSlice";
import store, { RootState } from "redux/store";
import { setTaskSelected } from "redux/taskSlice";
import { safelyParseJSON } from "utils/common";
import { updateForgeWhenSelectCategory } from "utils/document";
import {
  hightLightDocumentItem,
  updateForgeWhenSelectDocumentItem,
} from "utils/documentItem";
import {
  clearForgeSelection,
  getCurrentViewer,
  grayScaleForgeViewer,
  selectDbIds,
  setThemingColor,
} from "utils/forge";
import { get3DTo2DMatrix } from "utils/forge/aec";
import {
  getAECData,
  getLevelsData,
  getSheetsData,
} from "utils/forge/forgeApiData";
import { AreaExtension } from "utils/forge/extensions/area-extension";
import {
  ClickInfo,
  ClickExtensionEvents,
} from "utils/forge/extensions/click-extension";
import {
  AreaDisplayItem,
  clearLabelAndForgeView,
  clearSelectedLabel,
  CustomLabelExtension,
  DisplayItem,
  getLabelExtension,
  NormalDisplayItem,
  ShowLabelsOptions,
} from "utils/forge/extensions/custom-label";
import { LabelClicked } from "utils/forge/extensions/custom-label/constant";
import { setSheetTransformMatrix } from "utils/forge/forge2d";
import { logDev, logError } from "utils/logs";
import { transformBodyForCombineData } from "utils/offline";
import { updateClickingTaskId, _clicking_task_id } from "utils/task";
import useArea from "./useArea";
import { TypeHandleInitData } from "./useSupportSyncDataOffline";
import { getNetworkStatus } from "utils/indexedDb";

interface Props {
  filterTasks: TaskDTO[];
  isSettingFloor: boolean;
  mapTaskType: Map<string, string>;
  clickedLabelInfoRef: any;
  taskModalRef: React.RefObject<TaskModalHandleType>;
  filterDocumentGroupAndCategories: {
    categories: DocumentCategoryDTO[];
    groups: DocumentGroup[];
  };
  isGenerating: boolean;
  setClickInfo: (clickInfo: ClickInfo) => void;
  setClickedLabelInfo: (data: any) => void;
  sendWebSocketMessage: (message: WSMessage) => void;
  webSocketMessages: WSMessage[];
}

export default function useForgeViewer({
  filterTasks,
  isSettingFloor,
  mapTaskType,
  filterDocumentGroupAndCategories,
  webSocketMessages,
  isGenerating,
  setClickInfo,
  setClickedLabelInfo,
}: Props) {
  const [operation] = useState(
    new URLSearchParams(window.location.search).get("operation")
  );
  const [getModelDbIdsTime, setGetModelDbIdsTime] = useState<number | null>();
  const { currentUser } = useSelector((state: RootState) => state.user);

  const filterDocumentGroupAndCategoriesRef = useRef(
    filterDocumentGroupAndCategories
  );
  filterDocumentGroupAndCategoriesRef.current =
    filterDocumentGroupAndCategories;

  const {
    isShowArea,
    isGeneratingFamilyInstances,
    isGeneratingAllFamilyInstance,
    isGeneratingSpaces,
    isLoadedViewerModelData,
    isLoadedViewer,
    isLoadedNeptuneAreas,
    isLoadedSpaces,
    displayMode,
    levels,
    levelSelected,
    systemMode,
    isLoadedLevels,
    isLoadedSheetTransformRatio,
    isFilter,
    isLoadedExternalId,
    isOpenFilter,
    isCreateTask,
  } = useSelector((state: RootState) => state.forgeViewer);
  const { familyInstances } = useFamilyInstance();

  useLayoutEffect(() => {
    updateClickingTaskId("");
  }, []);

  const {
    documentCategorySelected,
    isLoadingDocument,
    documentItemSelected,
    documentGroupSelected,
    isLoadingDocumentItem,
  } = useSelector((state: RootState) => state.document);
  const [timeReloadDocumentBySocket, setTimeReloadDocumentBySocket] =
    useState<number>(0);

  const { settings } = useSelector((state: RootState) => state.user);
  const { tasks, taskSelected, isFetchedTasks } = useSelector(
    (state: RootState) => state.task
  );
  const forgeViewContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { bimFileId, version } = useParams();
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );
  const { isSyncOfflineData, syncDataOption } = useSelector(
    (state: RootState) => state.app
  );

  const { changeLevelSelected } = useChangeLevel();

  const { isTakasagoGroupAndPartnerLeader } = useRoles();
  const controllerDocumentItemsRef = useRef(new AbortController());
  const controllerDocumentCategoriesRef = useRef(new AbortController());

  const areaParentCategoryId = useRef<string | null>(null);

  const urn = useMemo(() => {
    return bimFileId && version
      ? encode(decodeURIComponent(`${bimFileId}?version=${version}`))
      : "";
  }, [bimFileId, version]);

  const { areas, spaces, allAreas, allSpaces } = useArea({});
  const areasRef = useRef<NeptuneArea[]>([]);
  const spacesRef = useRef<Space[]>([]);
  const allAreasRef = useRef<NeptuneArea[]>([]);
  const allSpacesRef = useRef<Space[]>([]);
  const levelSelectedRef = useRef<Level | undefined>(levelSelected);
  const documentGroupSelectedRef = useRef<DocumentGroup | undefined>(
    documentGroupSelected
  );
  const documentItemSelectedRef = useRef<DocumentItemDTO | undefined>(
    documentItemSelected
  );
  const documentCategorySelectedRef = useRef<DocumentCategoryDTO | undefined>(
    documentCategorySelected
  );
  const isShowAreaRef = useRef(true);

  areasRef.current = areas;
  spacesRef.current = spaces;
  allAreasRef.current = allAreas;
  allSpacesRef.current = allSpaces;
  levelSelectedRef.current = levelSelected;
  documentItemSelectedRef.current = documentItemSelected;
  documentCategorySelectedRef.current = documentCategorySelected;
  documentGroupSelectedRef.current = documentGroupSelected;
  isShowAreaRef.current = isShowArea;

  useEffect(() => {
    if (!documentItemSelected && !documentCategorySelected) {
      clearForgeSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!documentItemSelected, !!documentCategorySelected]);

  useEffect(() => {
    if (
      isLoadedViewer &&
      isLoadedViewerModelData &&
      documentCategorySelected &&
      getModelDbIdsTime &&
      systemMode === SystemModeType.Document
    ) {
      if (documentItemSelected) {
        if (!isSelfInspectionTemplate(documentCategorySelected?.documentType)) {
          hightLightDocumentItem(documentItemSelected);
        }
        updateForgeWhenSelectDocumentItem(
          documentCategorySelected!,
          documentItemSelected
        );
      } else {
        clearLabelAndForgeView();
        updateForgeWhenSelectCategory(documentCategorySelected);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadedViewer,
    isLoadedViewerModelData,
    getModelDbIdsTime,
    documentItemSelected,
  ]);

  // set status model cached
  useEffect(() => {
    const mapModelCached = structuredClone(
      syncDataOption?.mapModelCached || {}
    );
    const modelCached =
      mapModelCached?.[bimFileId || ""]?.[levelSelected?.label || ""] || {};

    if (
      !levelSelected?.guid ||
      !isLoadedViewer ||
      !bimFileId ||
      !displayMode ||
      !!modelCached?.modelType?.includes(displayMode)
    ) {
      return;
    }

    modelCached.modelType = [
      ...new Set([...(modelCached?.modelType || []), displayMode]),
    ];

    dispatch(
      setSyncDataOption({
        mapModelCached: {
          ...mapModelCached,
          [bimFileId]: {
            ...(mapModelCached?.[bimFileId || ""] || {}),
            [levelSelected.label]: modelCached,
          },
        },
      })
    );
  }, [
    isLoadedViewer,
    syncDataOption?.mapModelCached,
    bimFileId,
    levelSelected?.guid,
    levelSelected?.label,
    displayMode,
    dispatch,
  ]);

  useEffect(() => {
    const isClearGrayOutForge =
      (systemMode === SystemModeType.Task && isEmpty(taskSelected)) ||
      (systemMode === SystemModeType.Document &&
        !documentCategorySelected &&
        !documentItemSelected);
    if (isClearGrayOutForge) {
      clearForgeSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemMode]);

  const getDocumentCategorySelected = useCallback(
    (documentCategorySelected: DocumentCategoryDTO) => {
      return filterDocumentGroupAndCategoriesRef.current.categories.find(
        (doc) => doc.id === documentCategorySelected.id
      );
    },
    []
  );

  const hasPermission = useMemo(
    () => !currentUser?.role || currentUser.role === TYPE_USER.ROLE_ADMIN,
    [currentUser?.role]
  );

  const updateLabels = useCallback(
    async (params: {
      customLabelExtension: CustomLabelExtension;
      tasks: TaskDTO[];
      filterTasks: TaskDTO[];
      mapTaskType: Map<string, string>;
      settings: UserSetting;
    }) => {
      const { customLabelExtension, filterTasks } = params;
      areaParentCategoryId.current = null;
      let data: DisplayItem[] = [];
      const isSystemModeTask = systemMode === SystemModeType.Task;
      if (isSystemModeTask) {
        data = filterTasks.map((task) => ({
          id: task.id,
          indexId: task.indexId,
          position: task.position,
          title: "-",
          status: task.status,
          showImage: Number(task.images?.length) > 0,
          externalId: task.externalId,
        }));
      } else {
        const mapDocCategoryData = (categories: DocumentCategoryDTO[]) => {
          const docData = categories || [];
          const result = docData
            .map((documentCategory) => {
              if (isSelfInspectionTemplate(documentCategory?.documentType)) {
                const isAllFloor = !!documentCategory?.allFloor;
                const isNoArea = !!documentCategory?.noArea;
                let areaIds = documentCategory?.neptuneAreaIds || [];
                if (isAllFloor) {
                  areaIds = areasRef.current.map((item) => item.id);
                } else if (isNoArea) {
                  areaIds = [];
                }

                return {
                  id: `${documentCategory?.id}/${areaIds.join(",") || ""}`,
                  title: documentCategory.title || "-",
                  status: documentCategory.status || "",
                  originId: documentCategory?.id,
                  externalIds: areaIds,
                  templateId: documentCategory.templateId,
                  items: (documentCategory.documentItems ?? []).map(
                    (doc, docIndex) =>
                      ({
                        id: doc.id,
                        title: doc.title,
                        indexId: doc.indexId,
                        position: new THREE.Vector3(),
                        status: doc.status,
                        categoryId: documentCategory.id,
                        templateId: documentCategory.templateId,
                        displayOrder: docIndex,
                      } as NormalDisplayItem)
                  ),
                } as AreaDisplayItem;
              } else if (!!documentCategory.documentItems?.length) {
                return documentCategory.documentItems.map(
                  (documentItem, documentItemIndex) => {
                    return {
                      id: `${documentItem.id}`,
                      originId: documentItem.id,
                      position: documentItem.position!,
                      title: documentCategory.title || "-",
                      subTitle: documentItem.title || "-",
                      displayOrder: documentItemIndex,
                      status: documentItem.status,
                      externalId: documentItem.externalId,
                      categoryId: documentCategory.id,
                      templateId: documentCategory.templateId,
                      unVisible: !(
                        documentItem.externalId &&
                        documentCategory.selectedExternalIds?.includes(
                          documentItem.id
                        )
                      ),
                    } as NormalDisplayItem;
                  }
                );
              }

              return [];
            })
            .flat();

          return result;
        };
        let categories: DocumentCategoryDTO[] = [];
        if (!!documentCategorySelected && !documentGroupSelected) {
          const selected = getDocumentCategorySelected(
            documentCategorySelected
          );
          categories = selected ? [selected] : [];
        }
        data = mapDocCategoryData(categories);
      }
      if (!data.length) {
        customLabelExtension.data = {};
        customLabelExtension.clear();

        return;
      }

      const displayingData: DisplayItem[] = [];
      Object.values(customLabelExtension.data).forEach(
        ({ isShowLabel, ...item }) => {
          if (!item.tempId) {
            displayingData.push(item);
          }
        }
      );
      customLabelExtension.setShouldUpdateData(true);
      const isShowLabels = !document.querySelector(
        `div.adsk-viewing-viewer .canvas-wrap label.markup`
      );

      if (isSystemModeTask) {
        if (isShowLabels || !isEqual(data, displayingData)) {
          customLabelExtension.showLabels({
            mode: SystemModeType.Task,
            data,
          });
        }
      } else {
        if (isShowLabels || !isEqual(data, displayingData)) {
          const options: ShowLabelsOptions = {
            parentId: areaParentCategoryId.current,
            displayMode,
            sheetGuid: levelSelected.sheetGuid,
            forceUpdate: true,
          };
          if (
            documentCategorySelected &&
            isSelfInspectionTemplate(documentCategorySelected?.documentType)
          ) {
            options.selectedIds = [documentItemSelectedRef.current?.id || ""];
          }
          customLabelExtension.showLabels({
            mode: SystemModeType.Document,
            data,
            options,
          });
        }
      }
    },
    [
      systemMode,
      documentGroupSelected,
      documentCategorySelected,
      displayMode,
      levelSelected.sheetGuid,
      getDocumentCategorySelected,
    ]
  );

  useLayoutEffect(() => {
    const viewer = getCurrentViewer();
    const customLabelExtension = viewer?.getExtension(
      "CustomLabelExtension"
    ) as CustomLabelExtension | undefined;
    if (!customLabelExtension) {
      return;
    }
    // when is fetching task or fetching docs -> not show label
    if (
      !isLoadedSheetTransformRatio ||
      !isLoadedViewerModelData ||
      isGenerating ||
      (systemMode === SystemModeType.Task && !isFetchedTasks) ||
      (systemMode === SystemModeType.Document &&
        (isLoadingDocument || isLoadingDocumentItem))
    ) {
      customLabelExtension.data = {};
      customLabelExtension.clear();

      return;
    }

    updateLabels({
      settings,
      mapTaskType,
      customLabelExtension,
      tasks,
      filterTasks,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadedViewerModelData,
    displayMode,
    systemMode,
    isGenerating,
    levelSelected?.sheetGuid,
    isLoadedSheetTransformRatio,
    documentCategorySelected?.id,
    settings,
    filterTasks,
    isFilter,
    isFetchedTasks,
    isLoadingDocument,
    isLoadingDocumentItem,
    updateLabels,
  ]);

  useEffect(() => {
    if (!isLoadedViewer) {
      return;
    }
    const viewer = getCurrentViewer();
    const areaExtension = viewer?.getExtension("AreaExtension") as
      | AreaExtension
      | undefined;
    if (areaExtension) {
      areaExtension.setShouldReDrawArea(true);
    }
  }, [isLoadedViewer]);

  /**
   * Auto disable area extensions when visit forge view page
   */
  useEffect(() => {
    if (isShowArea) {
      dispatch(setIsShowArea(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelSelected]);

  useEffect(() => {
    const viewer = getCurrentViewer();
    const areaExtension = viewer?.getExtension("AreaExtension") as
      | AreaExtension
      | undefined;

    if (
      !isLoadedViewer ||
      !isLoadedNeptuneAreas ||
      !isLoadedSpaces ||
      !(displayMode === DISPLAY_MODE["3D"] || isLoadedSheetTransformRatio)
    ) {
      return;
    }
    if (!isShowArea) {
      areaExtension?.setSpacesToDraw(spaces);
      areaExtension?.setAreasToDraw(areas);

      return;
    }

    if (areaExtension) {
      areaExtension?.drawAreas({
        initialize: true,
      });
      areaExtension?.toggleVisibleArea(isShowAreaRef.current);
      const category = documentCategorySelectedRef.current;
      if (
        category?.neptuneAreaIds?.length &&
        isShowAreaRef.current &&
        systemMode === SystemModeType.Document
      ) {
        setTimeout(() => {
          areaExtension.select(category);
        }, 1);
      }
    }
  }, [
    areas,
    spaces,
    isLoadedViewer,
    isLoadedNeptuneAreas,
    isLoadedSpaces,
    displayMode,
    systemMode,
    levelSelected.sheetGuid,
    isLoadedSheetTransformRatio,
    levelSelected,
    isShowArea,
  ]);

  const handleForgeClick = useCallback(
    (info: ClickInfo) => {
      const targetEvent = info.originalEvent?.target;
      if (_clicking_task_id) {
        return;
      }
      if (
        targetEvent.closest(
          ".area-label, .label-item, .marker-pin, .combined-label"
        )
      ) {
        return;
      }
      const state = store.getState();
      const isSystemModeTask = systemMode === SystemModeType.Task;
      const isModeTaskAndHasTaskSelected =
        !isEmpty(state.task.taskSelected) && isSystemModeTask;

      // case waiting to create new task
      if (isModeTaskAndHasTaskSelected && state.task.isCreatingTask) {
        message.warning(
          "現在、ピン作成中です。次のピンを作成するには、しばらくお待ちください。"
        );

        return;
      }

      if (state.forgeViewer.isCreateSelfInspectionTask) {
        setClickInfo(info);

        return;
      }
      if (!state.document.isMovingDoc && !state.document.documentItemSelected) {
        clearSelectedLabel();
      }

      // case click anywhere on forge viewer if has task selected => clear selected task and redirect task selected
      if (isModeTaskAndHasTaskSelected && !state.forgeViewer.isMoveTaskLabel) {
        clearSelectedLabel();
        if (!state.forgeViewer.isCreateTask) {
          clearForgeSelection();
        }
        dispatch(setTaskSelected());
      }

      // case click anywhere on forge viewer if has label selected => clear selected label and redirect category selected
      if (
        !isEmpty(getLabelExtension().getSelectedItems()) &&
        systemMode === SystemModeType.Document
      ) {
        getLabelExtension()?.setSelectedItems([]);
        dispatch(setDocumentItemSelected());
        dispatch(setModalType(ModalType.DOC_CATEGORY));
        // highlight object when document category selected
        documentCategorySelected &&
          updateForgeWhenSelectCategory(documentCategorySelected);

        const elementRemoveLabels = document.querySelectorAll(
          ".combined-child-label-wrapper, .combined-child-label, .combined-label.selected-label, .document-label.selected-label"
        );
        for (const elm of elementRemoveLabels) {
          elm.classList.remove("selected-label");
        }
      }

      if (state.forgeViewer.isMoveTaskLabel) {
        setClickInfo(info);

        return;
      }

      if (state.forgeViewer.isCreatingNewTask) {
        return;
      }

      if (
        state.forgeViewer.isCreateTask ||
        state.forgeViewer.isCreateDocumentItem
      ) {
        setClickInfo(info);
      }

      const viewer = getCurrentViewer();

      if (info.forgeData?.dbId && viewer) {
        const color = DB_ID_COLOR_SELECTED_COLOR_DEFAULT; // color default selected for forge viewer
        // set selected color for 2d model
        viewer.set2dSelectionColor(color, 1);
        // set selected color for 3d model
        viewer.setSelectionColor(color, 1);
      }
    },
    [systemMode, setClickInfo, dispatch, documentCategorySelected]
  );

  useEffect(() => {
    const viewer = getCurrentViewer();
    if (!isLoadedExternalId || !isLoadedViewerModelData || !viewer) {
      return;
    }
    setThemingColor(undefined);
    setGetModelDbIdsTime(new Date().getTime());
  }, [isLoadedExternalId, isLoadedViewerModelData, displayMode]);

  useEffect(() => {
    const viewer = getCurrentViewer();
    if (!viewer) {
      return;
    }

    const handleClickEvent = (e: any) => {
      handleForgeClick(e.payload);
    };

    const handleLabelClick = (e: any) => {
      setClickedLabelInfo(e.payload);
    };

    const handleTouchEnd = () => {
      const hitTest = viewer.clientToWorld(
        window.innerWidth / 2,
        window.innerHeight / 2,
        true
      );
      viewer.navigation.setPivotPoint(hitTest.point);
    };

    viewer.addEventListener(
      ClickExtensionEvents.SingleClickEvent,
      handleClickEvent
    );
    viewer.addEventListener(LabelClicked, handleLabelClick);
    viewer.addEventListener("touchend", handleTouchEnd);

    return () => {
      viewer.removeEventListener(
        ClickExtensionEvents.SingleClickEvent,
        handleClickEvent
      );
      viewer.removeEventListener(LabelClicked, handleLabelClick);
      viewer.removeEventListener("touchend", handleTouchEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedViewer, handleForgeClick]);

  const isCaptureKeyplanByOperation = useMemo(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const data: { documentTaskId: string; guid: string } = JSON.parse(
      urlParams.get("data") || "{}"
    );

    return (
      data?.documentTaskId &&
      data?.guid &&
      operation === OPERATION.CaptureKeyplan
    );
  }, [operation]);

  const handleChangeSheet = useCallback(
    async (sheetGuid: string, isSaveData = true) => {
      if (!dataProjectDetail?.id) {
        return;
      }

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const data = urlParams.get("data");
      let level = { ...levelSelected, sheetGuid };

      const selectedSheetGuid =
        levelSelected?.sheetGuid || levelSelected?.sheets?.[0]?.guid;

      if (selectedSheetGuid === sheetGuid) {
        return;
      }

      getLabelExtension()?.clear();
      dispatch(setIsLoadedSheetTransformRatio(false));
      dispatch(setIsShowArea(true));
      if (isCaptureKeyplanByOperation && data) {
        const dataFromURL: OperationCaptureKeyplanData = JSON.parse(
          decodeURIComponent(data) || "{}"
        );
        const filterData: FilterDataType = JSON.parse(dataFromURL?.filterData);
        const _levelSelected = levels.find(
          (l) => l.label === filterData?.levelSelected.label
        );

        if (_levelSelected?.label) {
          level = { ..._levelSelected, sheetGuid };
        }
      }

      const { levelData, newProject } = changeLevelSelected({
        level,
        projectDetail: dataProjectDetail,
      });
      if (!isSaveData || isCaptureKeyplanByOperation) {
        return;
      }

      if (hasPermission) {
        await bimFileApi.updateProject({
          id: newProject.id,
          levelData,
          defaultBimPathId: newProject.defaultBimPathId,
          name: newProject.name,
        });
      }
    },
    [
      levelSelected,
      isCaptureKeyplanByOperation,
      dataProjectDetail,
      hasPermission,
      levels,
      dispatch,
      changeLevelSelected,
    ]
  );

  //#region generate levels data
  useEffect(() => {
    const urn = dataProjectDetail?.defaultBimPathId?.split("/").pop();

    if (!dataProjectDetail?.id || !urn) {
      return;
    }
    (async () => {
      dispatch(setLoadedLevels(false));
      const newProject = { ...dataProjectDetail };
      let shouldUpdate = false;

      if (!dataProjectDetail.sheetData) {
        const sheetData = await getSheetsData({
          projectId: dataProjectDetail.projectId,
          versionId: decodeURIComponent(urn),
        });
        newProject.sheetData = sheetData;
        shouldUpdate = true;
      }

      if (!dataProjectDetail.levelData) {
        const levelData = await getLevelsData({
          projectId: dataProjectDetail.projectId,
          versionId: decodeURIComponent(urn),
        });

        const map = levelData.reduce(
          (map: { [key: string]: Level }, level: Level) => {
            map[level.label || ""] = level;

            return map;
          },
          {}
        );
        newProject.levelData = map;
        shouldUpdate = true;
      }

      if (shouldUpdate && hasPermission) {
        dispatch(setDataProjectDetail(newProject));
        await bimFileApi.updateProject({
          id: newProject.id,
          levelData: newProject.levelData,
          sheetData: newProject.sheetData,
          defaultBimPathId: newProject.defaultBimPathId,
          name: newProject.name,
        });
      }

      const levelData = newProject.levelData! as {
        [key: string]: Level;
      };

      const levels = Object.values(levelData)?.filter(
        (item) => item.label !== ALL_LEVEL_LABEL
      );

      dispatch(setLevels(levels));
      const currentLevel = levels?.find((item) => {
        const levelGuid = safelyParseJSON(
          localStorage.getItem(CURRENT_LEVEL_KEY) as string
        )?.[bimFileId as string];

        return item.guid === levelGuid;
      });
      const operation = new URLSearchParams(window.location.search).get(
        "operation"
      );
      if (
        [OPERATION.ExportTask, OPERATION.CaptureKeyplan].includes(
          operation || ""
        )
      ) {
        dispatch(setLevelSelected(undefined));
      } else {
        dispatch(setLevelSelected(currentLevel || levels?.[0]));
      }

      dispatch(setLoadedLevels(true));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProjectDetail?.id, hasPermission]);
  //#endregion

  // grey out when change task sheet and when reload page has create task
  useEffect(() => {
    const viewer = getCurrentViewer();
    if (
      !isLoadedExternalId ||
      !isLoadedViewerModelData ||
      !viewer ||
      !isCreateTask ||
      !getModelDbIdsTime
    ) {
      return;
    }

    grayScaleForgeViewer(viewer);
    viewer.impl.invalidate(true, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCreateTask,
    isLoadedViewer,
    isLoadedExternalId,
    getModelDbIdsTime,
    isLoadedViewerModelData,
  ]);

  useEffect(() => {
    if (
      isLoadedViewer &&
      isLoadedViewerModelData &&
      getModelDbIdsTime &&
      !isEmpty(taskSelected)
    ) {
      selectDbIds(taskSelected.externalId, {
        color:
          MapInspectionItemColor[
            (taskSelected.status ||
              InspectionItemType.Defect) as InspectionItemType
          ],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadedViewer,
    isLoadedViewerModelData,
    getModelDbIdsTime,
    taskSelected,
  ]);

  //#region calculate 2d transform ratio
  const calculate2dTransformRatio = async (
    bimFileId: string,
    version: string,
    sheetGuid: string
  ) => {
    try {
      let sheetMatrix: THREE.Matrix4 = new THREE.Matrix4();
      const aecData: any = await getAECData(`${bimFileId}?version=${version}`);
      const viewport = aecData.viewports.find(
        (v: any) => v.sheetGuid === sheetGuid
      );
      if (!!viewport) {
        sheetMatrix = get3DTo2DMatrix(viewport, 0.001)?.clone();
      }
      setSheetTransformMatrix(sheetMatrix);
      dispatch(setIsLoadedSheetTransformRatio(true));
    } catch (e) {
      logError(e);
    }
  };

  useEffect(() => {
    if (
      isGeneratingFamilyInstances ||
      isGeneratingAllFamilyInstance ||
      isGeneratingSpaces ||
      isSettingFloor ||
      !isLoadedViewer ||
      !bimFileId ||
      !version ||
      !displayMode ||
      isLoadedSheetTransformRatio
    ) {
      return;
    }

    if (displayMode === DISPLAY_MODE["2D"]) {
      const sheetGuid =
        levelSelected.sheetGuid || levelSelected.sheets?.[0]?.guid || "";
      calculate2dTransformRatio(bimFileId, version, sheetGuid);
    } else {
      setSheetTransformMatrix(new THREE.Matrix4());
      dispatch(setIsLoadedSheetTransformRatio(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadedSheetTransformRatio,
    bimFileId,
    displayMode,
    isLoadedViewer,
    levelSelected,
    version,
    isSettingFloor,
  ]);

  //#region fetch/generate document data
  const initDocumentData = useCallback(
    async ({
      documentCategories,
      documentItems,
      documentGroups,
      documentTemplates,
    }: {
      documentCategories: DocumentCategoryDTO[];
      documentItems: DocumentItemDTO[];
      documentGroups: DocumentGroup[];
      documentTemplates: DocumentTemplate[];
    }) => {
      const {
        documentCategories: newDocumentCategories,
        documentItems: newDocumentItems,
        documentCategoryNeedUpdateItemIds,
      } = doMapDocumentCategories({
        documentCategories,
        documentItems,
        familyInstances,
        documentTemplates,
      });

      // update itemIds for get blackboard by category correct
      const isOnline = await getNetworkStatus();
      if (documentCategoryNeedUpdateItemIds.length && isOnline) {
        Promise.all(
          documentCategoryNeedUpdateItemIds.map((category) =>
            documentCategoryApi.updateCategory(
              transformBodyForCombineData<DocumentCategoryDTO>({
                body: {
                  id: category.id!,
                  itemIds: category.itemIds,
                } as DocumentCategoryDTO,
                bodyBefore: undefined,
                typeInitData: TypeHandleInitData.DOCUMENT_CATEGORY,
              })
            )
          )
        );
      }

      dispatch(
        setAllDocItemAndDocCategory({
          documentCategories: newDocumentCategories,
          documentItems: newDocumentItems,
        })
      );
      let documentCategory = documentCategorySelected;
      if (documentCategorySelected) {
        documentCategory = newDocumentCategories.find(
          (item) => item.id === documentCategorySelected.id
        );
        dispatch(setDocumentCategorySelected(documentCategory));
        if (documentCategory) {
          updateForgeWhenSelectCategory(documentCategory);
        }
        // document item select allway have document category selected
        if (documentItemSelected && documentCategory) {
          const documentItem = documentCategory.documentItems?.find(
            (item) => item.id === documentItemSelected.id
          );
          updateForgeWhenSelectDocumentItem(
            documentCategory!,
            documentItemSelected
          );
          dispatch(setDocumentItemSelected(documentItem));
          if (!documentItem) {
            dispatch(setModalType(ModalType.DOC_CATEGORY));
          }
        }
        if (
          isPhotoLedgerTemplate(documentCategorySelected?.documentType) &&
          documentCategorySelected?.id
        ) {
          dispatch(fetchBlackBoardByCategoryId(documentCategorySelected.id));
        }
      }

      if (documentGroupSelected) {
        const documentGroup = documentGroups.find(
          (item) => item.id === documentGroupSelected.id
        );
        dispatch(setDocumentGroupSelected(documentGroup));
      }
    },
    [
      familyInstances,
      documentCategorySelected,
      documentItemSelected,
      documentGroupSelected,
      dispatch,
    ]
  );

  const generateDocumentCategoriesAndItems = async (
    bimFileId: string,
    level?: string,
    systemMode?: string
  ) => {
    if (level && level !== levelSelectedRef.current?.label) {
      return;
    }

    if (systemMode === SystemModeType.Task) {
      documentCategoryApi
        .handleGetCategoryList({
          bimFileId,
          level: levelSelected.label,
          signal: controllerDocumentCategoriesRef.current.signal,
        })
        .then((categories) => {
          dispatch(setDocumentCategories(categories));
        });

      return;
    }

    dispatch(setIsFetchingDocument(true));
    /* eslint-disable-next-line no-console */
    console.log("---LOAD---");
    let documentTemplatesPromise: any = Promise.all([]);

    // need reload document templates and groups when offline -> online
    if (isTakasagoGroupAndPartnerLeader) {
      documentTemplatesPromise = documentTemplateApi.handleGetTemplateList({
        bimFileId: dataProjectDetail?.id,
        isActive: true,
      });
    }

    const promiseDocumentGroup = documentGroupApi.getGroupsList({
      bimFileId: dataProjectDetail?.id,
    });

    const documentCategoriesPromise = documentCategoryApi.handleGetCategoryList(
      {
        bimFileId,
        level: levelSelected.label,
        signal: controllerDocumentCategoriesRef.current.signal,
      }
    );

    const [responseDocumentGroup, documentCategories, documentTemplates] =
      await Promise.all([
        promiseDocumentGroup,
        documentCategoriesPromise,
        documentTemplatesPromise,
      ]);

    if (documentTemplates) {
      dispatch(setDocumentTemplates(documentTemplates));
    }

    dispatch(setDocumentCategories(documentCategories));

    if (responseDocumentGroup?.data) {
      dispatch(setDocumentGroups(responseDocumentGroup?.data));
    }

    dispatch(setIsFetchingDocument(false));
    dispatch(setIsLoadingDocumentItem(true));

    const documentItems = await documentItemApi.handleGetItemList({
      bimFileId,
      level,
    });
    initDocumentData({
      documentCategories,
      documentItems,
      documentGroups: responseDocumentGroup?.data,
      documentTemplates,
    });
    dispatch(setIsLoadingDocumentItem(false));
    dispatch(setIsLoadingDocument(false));
  };

  useEffect(() => {
    const controllerDocumentCategories =
      controllerDocumentCategoriesRef.current;
    const controllerDocumentItems = controllerDocumentItemsRef.current;

    return () => {
      dispatch(toggleIsCaptureKeynoteByOperation(false));
      dispatch(setDocumentItems([]));
      dispatch(setDocumentCategories([]));
      controllerDocumentCategories.abort();
      controllerDocumentItems.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lastFetchDocumentArgs = useRef<{
    bimFileId: string | null;
    levelSelected: string | null;
  }>({ bimFileId: null, levelSelected: null });
  const lastFetchTaskArgs = useRef<{
    bimFileId: string | null;
    levelSelected: string | null;
  }>({ bimFileId: null, levelSelected: null });
  // generate document categories, document items
  useEffect(() => {
    const levelName = levelSelected.label;
    if (
      !levelName ||
      !bimFileId ||
      !bimFileId ||
      !dataProjectDetail?.id ||
      dataProjectDetail.isDiffVersion ||
      !isLoadingDocument ||
      !isLoadedSheetTransformRatio
    ) {
      return;
    }

    if (isLoadingDocument) {
      lastFetchDocumentArgs.current = { bimFileId: null, levelSelected: null };
    }

    const isModeDocument = systemMode === SystemModeType.Document;
    const isModeTask = systemMode === SystemModeType.Task;
    const isNotRefetchData = (lastArgs: any) =>
      lastArgs.bimFileId === bimFileId && lastArgs.levelSelected === levelName;

    if (isModeTask) {
      if (isNotRefetchData(lastFetchTaskArgs.current)) {
        return;
      }
      if (isFetchedTasks) {
        lastFetchTaskArgs.current = {
          bimFileId,
          levelSelected: levelName,
        };
        generateDocumentCategoriesAndItems(
          bimFileId,
          levelName,
          SystemModeType.Task
        );
      }

      return;
    }

    // Disable refetch if the user change the system mode
    if (isNotRefetchData(lastFetchDocumentArgs.current)) {
      return;
    }

    if (isModeDocument || isOpenFilter) {
      lastFetchDocumentArgs.current = {
        bimFileId,
        levelSelected: levelName,
      };
      logDev("---FETCH---");
      generateDocumentCategoriesAndItems(bimFileId, levelName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadedSheetTransformRatio,
    isOpenFilter,
    systemMode,
    levelSelected.label,
    bimFileId,
    dataProjectDetail?.isDiffVersion,
    dataProjectDetail?.id,
    bimFileId,
    isFetchedTasks,
    isLoadingDocument,
    timeReloadDocumentBySocket,
  ]);

  useEffect(() => {
    (async () => {
      if (!isLoadedLevels || !bimFileId || !bimFileId || isSyncOfflineData) {
        return;
      }

      dispatch(setIsLoadingDocument(true));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSyncOfflineData]);

  useEffect(() => {
    if (!webSocketMessages.length) {
      return;
    }

    webSocketMessages.forEach((e) => {
      if (e?.type === MessageType.RELOAD_DOCUMENT) {
        dispatch(setIsLoadingDocument(true));
        setTimeReloadDocumentBySocket(Date.now());
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketMessages]);

  //#endregion
  const handleSetLoadedViewerOff = useCallback(() => {
    dispatch(setIsLoadedViewer(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    urn,
    familyInstances,
    isLoadedViewer,
    isLoadedSheetTransformRatio,
    isGeneratingSpaces,
    isGeneratingFamilyInstances,
    isGeneratingAllFamilyInstance,
    forgeViewContainerRef,
    isLoadedViewerModelData,
    handleChangeSheet,
    handleSetLoadedViewerOff,
  };
}
