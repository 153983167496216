import { Icon, IconProps } from "@chakra-ui/icons";

const IconZoomOut = (props: IconProps) => {
  return (
    <Icon width="18" height="18" viewBox="0 0 18 18" fill="inherit" {...props}>
      <path
        d="M4.88284 7.25002C4.67034 7.25002 4.49226 7.1781 4.34859 7.03427C4.20493 6.89043 4.13309 6.71227 4.13309 6.49976C4.13309 6.2871 4.20493 6.10901 4.34859 5.96552C4.49226 5.82185 4.67034 5.75002 4.88284 5.75002H8.15209C8.36459 5.75002 8.54276 5.82193 8.68659 5.96577C8.83026 6.1096 8.90209 6.28777 8.90209 6.50027C8.90209 6.71293 8.83026 6.89102 8.68659 7.03452C8.54276 7.17818 8.36459 7.25002 8.15209 7.25002H4.88284ZM6.51759 12.6153C4.80843 12.6153 3.36193 12.0235 2.17809 10.84C0.99426 9.65652 0.402344 8.21035 0.402344 6.50152C0.402344 4.79285 0.994094 3.34618 2.17759 2.16152C3.36109 0.977016 4.80726 0.384766 6.51609 0.384766C8.22476 0.384766 9.67143 0.976682 10.8561 2.16051C12.0406 3.34435 12.6328 4.79085 12.6328 6.50002C12.6328 7.21418 12.513 7.89627 12.2733 8.54627C12.0335 9.19627 11.7136 9.7616 11.3136 10.2423L17.0676 15.9963C17.2061 16.1346 17.2769 16.3086 17.2801 16.5183C17.2833 16.7279 17.2124 16.9052 17.0676 17.05C16.9228 17.1948 16.7471 17.2673 16.5406 17.2673C16.3343 17.2673 16.1587 17.1948 16.0138 17.05L10.2598 11.296C9.75984 11.7088 9.18484 12.0319 8.53484 12.2653C7.88484 12.4986 7.21243 12.6153 6.51759 12.6153ZM6.51759 11.1155C7.80609 11.1155 8.89743 10.6683 9.79159 9.77402C10.6859 8.87985 11.1331 7.78852 11.1331 6.50002C11.1331 5.21152 10.6859 4.12018 9.79159 3.22601C8.89743 2.33168 7.80609 1.88452 6.51759 1.88452C5.22909 1.88452 4.13776 2.33168 3.24359 3.22601C2.34926 4.12018 1.90209 5.21152 1.90209 6.50002C1.90209 7.78852 2.34926 8.87985 3.24359 9.77402C4.13776 10.6683 5.22909 11.1155 6.51759 11.1155Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default IconZoomOut;
