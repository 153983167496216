import { authApi, userApi } from "apiClient/v2";
import {
  DEFAULT_EDIT_TASK_MODAL_PERMISSION,
  DEFAULT_PERMISSION,
} from "constants/user";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { ROLE, RoleSettings } from "interfaces/models/role";
import { User } from "interfaces/models/user";
import { isEqual, merge } from "lodash-es";
import { setShouldLogout } from "redux/appSlice";
import store from "redux/store";
import { setCurrentUser } from "redux/userSlice";
import { getPreSignUrl, imageUrlToBase64 } from "./file";
import { changeNetworkStatus } from "./offline";

let logoutRequest: any;

export const storageUser = (user: User) => {
  const currentUser = store.getState().user.currentUser;
  if (!isEqual(currentUser, user)) {
    store.dispatch(setCurrentUser(user));
  }

  localStorage.setItem("userInfo", JSON.stringify(user));
};

export const getLocalStorageUser = (): User | null => {
  const user = localStorage.getItem("userInfo");

  return user ? JSON.parse(user) : null;
};

export const deleteLocalStorageUser = () => {
  store.dispatch(setCurrentUser(null));

  return localStorage.removeItem("userInfo");
};

export const getListUser = async (params?: any, shouldCache = false) => {
  const { data } = await userApi.getUserList(params, shouldCache);
  const mapPictureBase64 = Object.assign(
    {},
    ...(await Promise.all(
      data?.map((user) => {
        return new Promise(async (resolve) => {
          const valueDefault = { [user.id!]: "" };

          if (!user.id) {
            return resolve(valueDefault);
          }

          if (!user.avatar) {
            return resolve(valueDefault);
          }

          const presignedUrl = await getPreSignUrl(
            user.avatar,
            "",
            true,
            shouldCache
          );

          if (!presignedUrl) {
            return resolve(valueDefault);
          }

          const base64 = await imageUrlToBase64(
            presignedUrl,
            shouldCache
          ).catch(() => "");

          return resolve({ [user.id]: base64 });
        });
      })
    ))
  );
  const newDataItems: UserDTO[] = data?.map((item) => {
    return {
      ...item,
      avatarBase64: mapPictureBase64[item?.id || ""],
    };
  });

  return newDataItems;
};

export const logout = async () => {
  if (!store.getState().app.isOnline) {
    return;
  }

  logoutRequest = logoutRequest || authApi.logoutFromPlanet();
  await changeNetworkStatus(true);
  await logoutRequest;
  logoutRequest = null;
  store.dispatch(setShouldLogout());
};

export const checkUserExistsById = ({
  listUserById,
  userId,
}: {
  listUserById: { [key: string]: UserDTO | null };
  userId: string | null;
}) => {
  return !!listUserById?.[userId || ""] && !!userId;
};

export const getPermissionByRole = (role: string | undefined) => {
  let permissions: Partial<RoleSettings> = {};

  switch (role) {
    case ROLE.TAKASAGO_ADMIN:
      permissions = {
        canAddProject: true,
        canEditProject: true,
        canDeleteProject: true,
        canViewProject: true,
        canEditUserList: true,
        canViewUserList: true,
        canAccessDocumentTemplate: true,
        canAccessBlackboardTemplate: true,
        canAccessTaskTypeList: true,
        canAccessUserDashboard: true,
        canAccessProjectDashboard: true,
        canAccessForgeViewer: true,
        canAccessTask: true,
        canAccessTaskSheetDoc: true,
        canAccessDocument: true,
        canAccessCheckItem: true,
        canViewTaskModal: true,
        canAccessSettingFamily: true,
        canAddFolder: true,
        canEditFolder: true,
        canDeleteFolder: true,
        canAddDocumentCategory: true,
        canEditDocumentCategory: true,
        canDeleteDocumentCategory: true,
        canAddDocumentItem: true,
        canEditDocumentItem: true,
        canDeleteDocumentItem: true,
        canDeleteTask: true,
        canEditTaskModal: DEFAULT_EDIT_TASK_MODAL_PERMISSION,
        canEditTaskList: true,
        canEditDocumentCategoryInfo: true,
        canEditDocumentCategoryTitle: true,
      };
      break;

    case ROLE.TAKASAGO_FIELD_AGENT:
      permissions = {
        canAccessUserDashboard: true,
        canAccessForgeViewer: true,
        canAccessTask: true,
        canAccessDocument: true,
        canAccessCheckItem: true,
        canViewTaskModal: true,
        canEditFolder: true,
        canAddDocumentItem: true,
        canEditDocumentItem: true,
        canDeleteDocumentItem: true,
        canDeleteTask: true,
        canEditTaskModal: DEFAULT_EDIT_TASK_MODAL_PERMISSION,
        canEditTaskList: true,
        canEditDocumentCategoryTitle: true,
        canEditDocumentCategoryInfo: true,

        canAddFolder: true,
        canAddDocumentCategory: true,
        canEditDocumentCategory: true,
      };
      break;

    case ROLE.TAKASAGO_ONSITE:
      permissions = {
        canAccessUserDashboard: true,
        canAccessForgeViewer: true,
        canAccessTask: true,
        canAccessDocument: true,
        canAccessCheckItem: true,
        canViewTaskModal: true,
        canEditTaskModal: DEFAULT_EDIT_TASK_MODAL_PERMISSION,
        canAddDocumentItem: true,
        canEditDocumentItem: true,
        canDeleteDocumentItem: true,
        canDeleteTask: true,
        canEditTaskList: true,
        canEditDocumentCategoryTitle: true,
        canEditDocumentCategoryInfo: true,
      };
      break;

    case ROLE.PARTNER_LEADER:
      permissions = {
        canAccessUserDashboard: true,
        canAccessProjectDashboard: true,
        canAccessForgeViewer: true,
        canAccessTask: true,
        canAccessDocument: true,
        canAccessCheckItem: true,
        canViewTaskModal: true,
        canEditTaskModal: {
          canEditEndDateScheduledField: true,
          canEditConfirmedMemoField: true,
          canEditCommentField: true,
          canEditTaskTypeField: false,
          canEditTagsField: false,
          canEditImagesField: false,
          canEditMemoField: false,
          canEditAssignedUserField: false,
          canEditDeadlineField: false,
          canEditTaskDateField: false,
          canEditPartnerCompaniesField: false,
          canEditConfirmedDateTimeField: false,
          canEditConfirmedUserField: false,
          canEditAttachsField: false,
          canViewImageOriginal: false,
        },
      };
      break;
  }

  return merge(DEFAULT_PERMISSION, permissions);
};
