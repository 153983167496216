import { bimFileApi, taskApi, taskTypeApi } from "apiClient/v2";
import { MenuOption } from "components/widgets/DashBoard/components/SelectTag";
import { RateI } from "components/widgets/DashBoard/components/TrendSection/Achievement";
import { TrendCardI } from "components/widgets/DashBoard/components/TrendSection/TrendCard";
import {
  DataModeE,
  DeadlineType,
  InspectionItemType,
  MapInspectionItemColor,
  MapInspectionItemType,
  MapTaskDealineTitle,
  SetImageType,
} from "constants/enum";
import { listCustomTags, listOfficeTags, listSystemTags } from "constants/task";
import useGetCurrentPath from "hooks/useGetCurrentPath";
import {
  FilterFormI,
  QuickFilterForm,
  TaskDeadlineI,
  TaskSectionI,
} from "interfaces/models/dashboard";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { ProjectListRes } from "interfaces/models/project";
import { Task } from "interfaces/models/task";
import { TaskType } from "interfaces/models/taskType";
import { User } from "interfaces/models/user";
import { flattenDeep, intersection } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { compareArray, uniqueArray } from "utils/array";
import { getBimFileInfo } from "utils/bim";
import { getDeadlineType, getProjectByBimFileId } from "utils/dashboard";
import {
  checkIsBetween,
  DATE_FORMAT,
  formatDate,
  getDaysFromNow,
} from "utils/date";
import { logError } from "utils/logs";
import { TABLE_ITEMS_TITLE } from "./useDocument";

export const DAYS_ABOUT_TO_EXPIRE = 7;

export const SEPERATOR = "-";

interface PropsI {
  users: User[];
  filteredForm?: FilterFormI;
  quickFilterForm?: QuickFilterForm;
  levelOptions?: MenuOption[];
  projectOptions?: MenuOption[];
  isUserDashboard?: boolean;
  isProjectDashboard?: boolean;
  projects?: DataProjectModel[];
  familyPropertyOptions?: MenuOption[];
  dataProjectList?: DataProjectModel[];
  isExpired?: boolean;
  trendStatuses?: string[];
  mapPartnerCompany?: {
    [partnerCompanyId: string]: PartnerCompany;
  };
  partnerCompanies?: PartnerCompany[];
}

const useTasks = (props: PropsI) => {
  const {
    users,
    filteredForm,
    quickFilterForm,
    isUserDashboard,
    isProjectDashboard,
    projects,
    dataProjectList,
    isExpired,
    trendStatuses,
    mapPartnerCompany,
    partnerCompanies,
  } = props;
  const [tasks, setTasks] = useState<Task[]>([]);
  const [taskTypes, setTaskTypes] = useState<TaskType[]>([]);
  const [loadingTaskData, setLoadingTaskData] = useState<boolean>(false);
  const { params } = useGetCurrentPath();
  const { families } = useSelector((state: RootState) => state.forgeViewer);
  const { currentUser } = useSelector((state: RootState) => state.user);

  const getTaskNameByContentType = (contentType: string) => {
    const foundItem = taskTypes?.find((item) => item.id === contentType);

    if (!foundItem) return SEPERATOR;

    return foundItem.title;
  };

  const fetchTaskTypes = async () => {
    const taskTypes = await taskTypeApi.handleGetTaskTypes({});
    setTaskTypes(taskTypes);
  };

  const fetchTasks = async () => {
    setLoadingTaskData(true);

    try {
      const dataProjects = dataProjectList?.length
        ? dataProjectList
        : (await bimFileApi.getProjectList()).data;

      const currentDataProject = dataProjects?.find(
        (project) => project.id === params?.projectId
      );

      const urn = currentDataProject?.defaultBimPathId?.split("/").pop();
      const { bimFileId } = getBimFileInfo(urn || "");

      const tasks = await taskApi.handleGetTasks({
        ...(isProjectDashboard && {
          bimFileId,
        }),
      });

      setTasks(tasks);
    } catch (err) {
      logError(err);
    } finally {
      setLoadingTaskData(false);
    }
  };

  const filterTasks = useCallback(
    (tasks: Task[], filterForm: FilterFormI) => {
      const filteredLevels = filterForm?.floor?.value || [];

      const filteredProjects = filterForm?.projects?.value || [];

      const filteredPartnerCompanies = filterForm?.partnerCompany?.value || [];
      const allTags = flattenDeep([
        listCustomTags.map((item) => item.value),
        listOfficeTags.map((item) => item.value),
        listSystemTags.map((item) => item.value),
        families.map((family) => family.name) || [],
      ]);
      const tagsInQuickFilterForm = quickFilterForm?.tags?.length
        ? quickFilterForm?.tags?.map((item) => item.name)
        : allTags;

      const tagsInFilterForm = filterForm?.tags?.value?.length
        ? filterForm?.tags?.value
        : allTags;

      const filteredTags = intersection(
        tagsInQuickFilterForm,
        tagsInFilterForm
      );
      const filteredStatuses = filterForm?.status?.value || [];
      const filteredSetImages = filterForm?.images?.value;

      const filteredFamily = filterForm?.family?.value?.length
        ? filterForm?.family?.value
        : [];

      const filteredTasks = tasks?.filter((item) => {
        const project = getProjectByBimFileId(
          item?.bimFileId,
          projects as DataProjectModel[]
        );

        const filteredByLevel =
          isUserDashboard || !filteredLevels.length
            ? true
            : filteredLevels?.some(
                (levelOption) => levelOption?.name === item?.level
              );

        const filteredByProject =
          isProjectDashboard || !filteredProjects.length
            ? true
            : filteredProjects?.some((option) => {
                return option?.value === project?.id;
              });

        const filteredByStartDateScheduled = checkIsBetween({
          targetDate: formatDate(item?.startDateScheduled, DATE_FORMAT),
          startDate: filterForm?.startDateScheduled?.value?.startDate,
          endDate: filterForm?.startDateScheduled?.value?.endDate,
        });

        const filteredByDeadLine = checkIsBetween({
          targetDate: formatDate(item?.deadline, DATE_FORMAT),
          startDate: filterForm?.endDateScheduled?.value?.startDate,
          endDate: filterForm?.endDateScheduled?.value?.endDate,
        });
        const filteredByStartDate = checkIsBetween({
          targetDate: formatDate(item?.creationDateTime, DATE_FORMAT),
          startDate: filterForm?.startDate?.value?.startDate,
          endDate: filterForm?.startDate?.value?.endDate,
        });

        const filteredByEndDate =
          !filterForm?.endDate?.value?.startDate &&
          !filterForm?.endDate?.value?.endDate
            ? true
            : checkIsBetween({
                targetDate: formatDate(item?.confirmedDateTime, DATE_FORMAT),
                startDate: filterForm?.endDate?.value?.startDate,
                endDate: filterForm?.endDate?.value?.endDate,
              });

        const filteredByTags = compareArray(filteredTags, uniqueArray(allTags))
          ? true
          : intersection(filteredTags, item?.tags).length;

        const filteredByStatus = !filteredStatuses?.length
          ? true
          : filteredStatuses?.some((status) => {
              if (status.value === InspectionItemType.Defect) {
                return (
                  item?.status === InspectionItemType.Defect || !item.status
                );
              }

              return status.value === item?.status;
            });

        const filteredBySetImages =
          filteredSetImages?.length === 2 || !filteredSetImages?.length
            ? true
            : filteredSetImages?.length === 1
            ? filteredSetImages?.[0] === SetImageType.SetImage
              ? item?.images?.length
              : !item?.images?.length
            : false;

        const filteredByFamily = !filteredFamily?.length
          ? true
          : intersection(
              filteredFamily?.map((option) => option?.value),
              item?.objectTypes
            )?.length;

        const filteredByPartnerCompany =
          !filteredPartnerCompanies.length ||
          filteredPartnerCompanies.length === partnerCompanies?.length
            ? true
            : filteredPartnerCompanies?.some(
                (partnerCompany) =>
                  partnerCompany?.value === item?.partnerCompanyId
              );

        const userAssignedId =
          filteredForm?.manager?.value?.value ||
          (isProjectDashboard ? "" : currentUser?.id);
        const filteredByAssignedUser = ((task) => {
          if (!userAssignedId) {
            return true;
          }

          if (task.userAssigned?.includes(userAssignedId)) {
            return true;
          }

          if (task.userConfirmed === userAssignedId) {
            return true;
          }

          return false;
        })(item);

        return (
          filteredByLevel &&
          filteredByTags &&
          filteredByStatus &&
          filteredBySetImages &&
          filteredByProject &&
          filteredByFamily &&
          filteredByStartDateScheduled &&
          filteredByDeadLine &&
          filteredByStartDate &&
          filteredByEndDate &&
          filteredByPartnerCompany &&
          filteredByAssignedUser
        );
      });

      return filteredTasks;
    },
    [
      currentUser?.id,
      families,
      filteredForm?.manager?.value?.value,
      isProjectDashboard,
      isUserDashboard,
      partnerCompanies?.length,
      projects,
      quickFilterForm?.tags,
    ]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFilteredTasksByStatus = (filterForm: FilterFormI) => {
    const filteredTasks: {
      [InspectionItemType.Defect]: Task[];
      [InspectionItemType.Treated]: Task[];
      [InspectionItemType.Confirmed]: Task[];
    } = {
      [InspectionItemType.Defect]: [],
      [InspectionItemType.Treated]: [],
      [InspectionItemType.Confirmed]: [],
    };

    const sortedFilteredTasksKeys = [
      InspectionItemType.Defect,
      InspectionItemType.Treated,
      InspectionItemType.Confirmed,
    ];

    tasks
      ?.map((item) => {
        const user = users?.find((user) => user?.id === item?.userAssigned);
        const deadlineType = getDeadlineType(item?.deadline as string);
        const project = getProjectByBimFileId(
          item?.bimFileId,
          projects as DataProjectModel[]
        );

        const projectName = project?.name;

        const statusName = item?.status
          ? MapInspectionItemType?.[
              item?.status as keyof typeof MapInspectionItemType
            ]
          : MapInspectionItemType?.defect;

        const partnerCompanyName =
          mapPartnerCompany?.[String(item?.partnerCompanyId)]?.name || SEPERATOR;

        return {
          ...item,
          title: getTaskNameByContentType(item?.taskTypeId as string),
          assignedUserName: user?.name || SEPERATOR,
          avatarUrl: user?.avatar,
          deadlineType,
          projectName,
          statusName,
          partnerCompanyName,
        };
      })
      ?.filter((item) => item.projectName)
      ?.forEach((task: Task) => {
        if (!!task?.status) {
          filteredTasks?.[task?.status as `${InspectionItemType}`]?.push(task);
        } else {
          filteredTasks?.[InspectionItemType.Defect]?.push(task);
        }
      });

    return Object.keys(filteredTasks)
      ?.sort((a, b) => {
        return (
          sortedFilteredTasksKeys.indexOf(a as any) -
          sortedFilteredTasksKeys.indexOf(b as any)
        );
      })
      ?.map((key) => {
        const tasks = filterTasks(
          filteredTasks[key as `${InspectionItemType}`],
          filterForm
        );

        return {
          title: TABLE_ITEMS_TITLE,
          tasks,
          status: key,
        };
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFilteredTasksByDeadline = (filterForm: FilterFormI) => {
    const filteredTasks: TaskDeadlineI = {
      expired: {
        title: "",
        tasks: [],
      },
      aboutToExpire: {
        title: "",
        tasks: [],
      },
      notExpired: {
        title: "",
        tasks: [],
      },
    };

    tasks
      ?.map((item) => {
        const user = users?.find((user) => user?.id === item?.userAssigned);
        const project = getProjectByBimFileId(
          item?.bimFileId,
          projects as DataProjectModel[]
        );

        const projectName = project?.name;

        const statusName = item?.status
          ? MapInspectionItemType?.[
              item?.status as keyof typeof MapInspectionItemType
            ]
          : MapInspectionItemType?.defect;

        const partnerCompanyName =
          mapPartnerCompany?.[String(item?.partnerCompanyId)]?.name || SEPERATOR;

        return {
          ...item,
          title: getTaskNameByContentType(item?.taskTypeId as string),
          assignedUserName: user?.name || SEPERATOR,
          avatarUrl: user?.avatar,
          projectName,
          statusName,
          partnerCompanyName,
        };
      })
      ?.filter((item) => item.projectName)
      .forEach((task: Task) => {
        const deadlineType = getDeadlineType(task?.deadline as string);

        if (deadlineType === DeadlineType.expired) {
          filteredTasks?.expired?.tasks?.push(task);
          filteredTasks.expired.title = MapTaskDealineTitle.expired;

          return;
        }

        if (deadlineType === DeadlineType.aboutToExpire) {
          filteredTasks?.aboutToExpire?.tasks?.push(task);
          filteredTasks.aboutToExpire.title = MapTaskDealineTitle.aboutToExpire;

          return;
        }

        filteredTasks?.notExpired.tasks?.push(task);
        filteredTasks.notExpired.title = MapTaskDealineTitle.notExpired;
      });

    return Object.keys(filteredTasks)?.map((key) => {
      return {
        title: filteredTasks[key as keyof typeof MapTaskDealineTitle].title,
        tasks: filterTasks(
          filteredTasks[key as keyof typeof MapTaskDealineTitle].tasks,
          filterForm as FilterFormI
        ),
        deadlineType: key as DeadlineType,
      };
    });
  };

  const filteredTasksByDeadline = useMemo(() => {
    return getFilteredTasksByDeadline(filteredForm as FilterFormI);
  }, [filteredForm, getFilteredTasksByDeadline]);

  const filteredTasksByStatus = useMemo(() => {
    return getFilteredTasksByStatus(filteredForm as FilterFormI);
  }, [filteredForm, getFilteredTasksByStatus]);

  const taskData: TaskSectionI[] = useMemo(() => {
    if (quickFilterForm?.mode === DataModeE.ALL) {
      return filteredTasksByDeadline;
    }

    return filteredTasksByStatus;
  }, [filteredTasksByDeadline, filteredTasksByStatus, quickFilterForm?.mode]);

  const taskRates: RateI[] = useMemo(() => {
    const total = filteredTasksByStatus?.reduce((acc, item) => {
      return acc + Number(item.tasks.length);
    }, 0);

    return filteredTasksByStatus?.map((item) => {
      return {
        title: `${
          MapInspectionItemType[
            item?.status as keyof typeof MapInspectionItemType
          ]
        }: ${item?.tasks?.length}`,
        rate: (item?.tasks?.length / total) * 100,
        color:
          MapInspectionItemColor[
            item.status as keyof typeof MapInspectionItemColor
          ],
      };
    });
  }, [filteredTasksByStatus]);

  const trendingList: TrendCardI[] = useMemo(() => {
    const numOfExpiredTask =
      filteredTasksByDeadline.find((item) => item.deadlineType === "expired")
        ?.tasks?.length || 0;

    const { numOfDefectTask, numOfTreatedTask, numOfConfirmedTask } =
      filteredTasksByStatus?.reduce(
        (acc, item) => {
          if (item.status === InspectionItemType.Defect) {
            Object.assign(acc, {
              numOfDefectTask: item?.tasks?.length,
            });
          }

          if (item.status === InspectionItemType.Treated) {
            Object.assign(acc, {
              numOfTreatedTask: item?.tasks?.length,
            });
          }

          if (item.status === InspectionItemType.Confirmed) {
            Object.assign(acc, {
              numOfConfirmedTask: item?.tasks?.length,
            });
          }

          return acc;
        },
        {
          numOfDefectTask: 0,
          numOfTreatedTask: 0,
          numOfConfirmedTask: 0,
        }
      );

    const totalTasks = numOfDefectTask + numOfTreatedTask + numOfConfirmedTask;

    return [
      {
        title: "期限超過是正指示",
        count: numOfExpiredTask,
        status: Object.values(InspectionItemType),
      },
      {
        title: "修正待ち是正指示",
        count: numOfDefectTask,
        status: [InspectionItemType.Defect],
      },
      {
        title: "承認待ち是正指示",
        count: numOfTreatedTask,
        status: [InspectionItemType.Treated],
      },
      {
        title: "承認済み是正指示",
        count: numOfConfirmedTask,
        status: [InspectionItemType.Confirmed],
      },
      {
        title: "合計是正指示",
        count: totalTasks,
        status: Object.values(InspectionItemType),
      },
    ];
  }, [filteredTasksByDeadline, filteredTasksByStatus]);

  const isTaskDataEmpty = useMemo(() => {
    return taskData?.every((item) => {
      const filteredTasks = item.tasks.filter((task) => {
        const diffDays = getDaysFromNow(
          new Date(String(task?.deadline))
        ) as number;

        if (isExpired) {
          return diffDays >= 0 && !!diffDays && task?.deadline;
        }

        return true;
      });

      const hidden =
        (trendStatuses?.length &&
          !trendStatuses?.includes(String(item?.status))) ||
        !filteredTasks?.length;

      return !item.tasks.length || hidden;
    });
  }, [isExpired, taskData, trendStatuses]);

  useEffect(() => {
    fetchTaskTypes();
  }, []);

  useEffect(() => {
    fetchTasks();
  }, []);

  return {
    taskData,
    loadingTaskData,
    taskRates,
    trendingList,
    isTaskDataEmpty,
    filteredTasksByStatus,
  };
};

export default useTasks;
