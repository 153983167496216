import { isArray, omit } from "lodash-es";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { MessageType } from "constants/websocket";
import { Operation } from "constants/serviceWorker";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { toggleElementInArray } from "./array";
import {
  getItemBelongDocs,
  reMappingWidthDocItemAfterAddDoc,
} from "models/documentCategory";

export const mergeDataUpdateSocket = (item: any, updateProps: any) => {
  const mergedData = {
    ...item,
    ...(omit(updateProps, "isIgnoreInsertLog") as any),
  };

  if (updateProps.data) {
    mergedData.data = item.data
      ? {
          ...item.data,
          ...updateProps.data,
        }
      : updateProps.data;
  }

  return mergedData;
};

export const mergeDataUpdateDocSubItem = (
  docItem: DocumentItemDTO,
  data: any,
  type: MessageType
) => {
  if (!docItem!.subItems) {
    docItem!.subItems = [];
  }
  const isCreate = type === MessageType.ADD_SUB_ITEM;
  const isUpdate = type === MessageType.UPDATE_SUB_ITEM;
  const isDelete = type === MessageType.DELETE_SUB_ITEM;
  if (isCreate) {
    const newData = omit(data, "isIgnoreInsertLog") as DocumentSubItemDTO;
    docItem.subItems.push(newData);
  } else if (isUpdate) {
    const listData = isArray(data) ? data : [data];
    for (const node of listData) {
      const subItemIndex = docItem!.subItems.findIndex(
        (subItem) => subItem.id === node.id
      );
      if (subItemIndex === -1) {
        continue;
      }
      docItem.subItems[subItemIndex] = mergeDataUpdateSocket(
        docItem!.subItems[subItemIndex],
        node
      );
    }
  } else if (isDelete) {
    docItem.subItemIds = docItem.subItemIds?.filter((id) => id !== data.id);
    docItem.subItems = docItem.subItems?.filter(({ id }) => id !== data.id);
  }
  if (isCreate || isUpdate) {
    //@ts-ignore
    docItem.isUpdateVisibleItem =
      type === MessageType.ADD_SUB_ITEM ? true : data.isUpdateVisibleItem;
  }

  return docItem;
};

export const getRestMethodUpdateSubItem = (type: MessageType) => {
  return type === MessageType.ADD_SUB_ITEM
    ? Operation.Post
    : type === MessageType.UPDATE_SUB_ITEM
    ? Operation.Patch
    : Operation.Delete;
};

export const mergeDataChangeVisibleItem = (
  documentCategory: DocumentCategoryDTO,
  documentItemId: string,
  isVisible: boolean
) => {
  const category = structuredClone(documentCategory);
  if (!category.selectedExternalIds) {
    category.selectedExternalIds = [];
  }
  let documentItem: DocumentItemDTO | undefined;
  toggleElementInArray(category.selectedExternalIds, documentItemId, isVisible);
  if (!category.documentItems) {
    category.documentItems = [];
  }
  const docItems = category.documentItems;
  const index = docItems.findIndex((doc) => doc.id === documentItemId);
  if (index >= 0) {
    documentItem = docItems[index];
    documentItem!.subItems = documentItem!.subItems?.map((subItem) => ({
      ...subItem,
      isHidden: !isVisible,
    }));
  }

  return { category, documentItem };
};

/**
 * get mapped document categories and document items after add document item
 * @param documentItems
 * @param allDocumentItems
 * @param allDocumentCategories
 * @param documentTemplates: Object template
 * @returns
 */
export function transformDataAddDocItem(
  newDocItems: DocumentItemDTO[],
  allDocumentItems: DocumentItemDTO[],
  allDocumentCategories: DocumentCategoryDTO[],
  documentTemplates: any
) {
  const setDocumentTemplate = new Set();
  const setNewItemId = new Set();
  newDocItems.forEach((item) => {
    setDocumentTemplate.add(item.templateId);
    setNewItemId.add(item.id);
  });
  const itemNotContainNews = (allDocumentItems || []).filter(
    (item) => !setNewItemId.has(item.id)
  );
  const newAllItems = itemNotContainNews.concat(newDocItems);
  // at this function -> we also add item to externalIds, itemIds.
  // Here we only process for case add doc Item except case self inspection

  const cacheCategories: {
    id: string;
    selectedExternalIds: string[];
    itemIds: string[];
  }[] = [];
  const cloneAllDocs = [...allDocumentCategories];
  // update itemIds, selected externalIds to relative document categories
  cloneAllDocs.forEach((cate, index) => {
    const items = getItemBelongDocs(cate, newDocItems);
    if (items.length) {
      const newDocIds = items.map((item) => item.id);
      const newSelectedExternalIds = [
        ...new Set([...(cate.selectedExternalIds || []), ...newDocIds]),
      ];
      const newItemIds = [...new Set([...(cate.itemIds || []), ...newDocIds])];
      cloneAllDocs[index] = {
        ...cate,
        selectedExternalIds: newSelectedExternalIds,
        itemIds: newItemIds,
      };
      // when change document item , we need cache selectedExternalIds, itemIds of category
      cacheCategories.push({
        id: cate.id,
        selectedExternalIds: newSelectedExternalIds,
        itemIds: newItemIds,
      });
    }
  });

  const result = reMappingWidthDocItemAfterAddDoc(
    [],
    cloneAllDocs,
    newAllItems,
    documentTemplates
  );

  return {
    cacheCategories,
    ...result,
  };
}
