import React, { memo } from "react";
import { css } from "@emotion/react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbLinkProps,
  BreadcrumbProps as ChakraBreadCrumbProps,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { SvgIcon } from "./SvgIcon";
import { useNavigate } from "react-router-dom";

type Path = {
  name?: string;
  link?: string;
  icon?: {
    component: React.ReactNode;
    width: string | number;
  };
  styleProps?: any;
  onClick?(): void;
};

export interface BreadCrumbProps extends ChakraBreadCrumbProps {
  isWrapText?: boolean;
  paths: Path[];
  hiddenPaths?: Path[];
  separator?: React.ReactElement | string;
  breadcrumbLinkProps?: BreadcrumbLinkProps;
}

const BreadCrumb = (props: BreadCrumbProps) => {
  const {
    paths = [],
    hiddenPaths = [],
    separator = <SvgIcon w="2.2rem" h="2.2rem" src="/img/arrow-right3.svg" />,
    breadcrumbLinkProps,
    isWrapText,
    ...rest
  } = props;
  const navigate = useNavigate();

  const renderEllipsisPath = () => {
    return (
      <Menu>
        <MenuButton as="button">
          <Flex cursor="pointer" flexDir="row" alignItems="center">
            <Text cursor="pointer">…</Text>
            {separator}
          </Flex>
        </MenuButton>
        <MenuList
          paddingY="0"
          minW="20rem"
          maxW="25rem"
          boxShadow={
            "0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 0px 4px 0px rgba(0, 0, 0, 0.06)"
          }
        >
          {hiddenPaths.map((path, index) => {
            const { link, onClick } = path;

            return (
              <Tooltip key={index} label={path.name}>
                <MenuItem
                  h="4rem"
                  display="inline-block"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  borderTop={index > 0 ? "1px solid #d2d2d2" : undefined}
                  onClick={() => (onClick ? onClick() : link && navigate(link))}
                >
                  {path.name}
                </MenuItem>
              </Tooltip>
            );
          })}
        </MenuList>
      </Menu>
    );
  };

  const renderBreadCrumbItem = (path: Path, index: number) => {
    const { name, link, icon, styleProps, onClick } = path;

    const isCurrentPage = !!link || !!onClick;

    return (
      <BreadcrumbItem key={index}>
        <BreadcrumbLink
          fontFamily="Noto Sans JP"
          display="flex"
          alignItems={"center"}
          color={index < paths.length - 1 ? "#1795FE" : "black"}
          onClick={() => (onClick ? onClick() : link && navigate(link))}
          gap="0.5rem"
          title={name}
          isCurrentPage={isCurrentPage}
          css={css`
            &:hover {
              text-decoration: ${isCurrentPage ? "underline" : "none"};
              cursor: ${isCurrentPage ? "pointer" : "inherit"};
            }
          `}
          {...styleProps}
          {...breadcrumbLinkProps}
        >
          {icon?.component}
          {name ? (
            <Text
              as="span"
              {...(!isWrapText && {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              })}
            >
              {name}
            </Text>
          ) : (
            <Spinner />
          )}
        </BreadcrumbLink>
      </BreadcrumbItem>
    );
  };

  return (
    <Breadcrumb separator={separator} {...rest}>
      {hiddenPaths.length && renderEllipsisPath()}
      {paths?.map((path, index) => renderBreadCrumbItem(path, index))}
    </Breadcrumb>
  );
};

export default memo(BreadCrumb);
