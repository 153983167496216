import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { DeleteIcon } from "components/icon";
import MenuDropdown from "components/MenuDropdown";
import { useMemo, useState } from "react";

export interface ItemData {
  id: any;
  src: string;
  title: any;
}

const Presigned = withPresignedUrl(Image);

interface ItemProps {
  aspectRatio?: number | string;
  item: ItemData;
  isActive?: boolean;
  onClick(e: React.MouseEvent<HTMLDivElement>): void;
  onDelete(item: ItemData): void;
}

const Item: React.FC<ItemProps> = ({
  aspectRatio,
  item,
  isActive,
  onClick,
  onDelete,
}) => {
  const [isLoadedImage, setIsLoadedImage] = useState(false);
  const onLoadImage = (e: any) => {
    setIsLoadedImage(true);
  };

  const options = useMemo(() => {
    return [
      {
        title: (
          <Center
            fontSize="1.4rem"
            gap="0.5rem"
            color="#EC3B3B"
            fontWeight={600}
          >
            <DeleteIcon width="2.2rem" height="2.2rem" />
            <Text>削除</Text>
          </Center>
        ) as any,
        onClick: () => {
          onDelete(item);
        },
      },
    ];
  }, [item, onDelete]);

  return (
    <Box
      onClick={onClick}
      borderWidth={"2px"}
      borderStyle={"solid"}
      borderRadius="4px"
      borderColor={isActive ? "#009BE0" : "transparent"}
      p="1rem"
      cursor={"pointer"}
      transition={"all .3s ease-in-out"}
      boxShadow={"0px 0px 5px 0px rgba(0, 0, 0, 0.1)"}
      bg={isActive ? "#E0F2FE" : undefined}
      _hover={{
        bg: isActive ? "#cee0ea" : "#ebebeb",
      }}
      h="100%"
    >
      <Flex>
        <Presigned
          bg="#D9D9D9"
          objectFit={"contain"}
          objectPosition={"center"}
          aspectRatio={aspectRatio}
          w="100%"
          h="auto"
          src={item.src}
          onLoad={onLoadImage}
        />
      </Flex>
      <Flex pt="1rem" gap="1rem">
        <Box
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          flexGrow={1}
          display="flex"
          alignItems="center"
          fontWeight={isActive ? "600" : "400"}
          fontSize={"1.4rem"}
          color={isActive ? "#171717" : "#737373"}
        >
          {item.title}
        </Box>
        <Box>
          <MenuDropdown
            menuListProps={{ maxWidth: "20rem", zIndex: 9999 }}
            display="flex"
            minHeight={"0"}
            menuButtonProps={{
              width: "inherit !important",
              sx: {
                button: {
                  textAlign: "center",
                  display: "block",
                },
                "button:not(:last-child)": {
                  borderBottom: "1px solid #dddd",
                },
              },
            }}
            textAlign="right"
            options={options}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default Item;
