import { useBoolean } from "@chakra-ui/react";
import { inspectionContentApi } from "apiClient/v2";
import { message } from "components/base";
import { set } from "lodash-es";
import { useCallback, useMemo, useState } from "react";
import { logDev } from "utils/logs";
import { arrayToObject } from "utils/object";
import { initInspectionData } from "../context/FormBuilderContext/actions";
import {
  FormBuilderActions,
  FormBuilderState,
  schema,
} from "../context/FormBuilderContext/types";

interface Props {
  state: FormBuilderState;
  dispatch: React.Dispatch<FormBuilderActions>;
}

const useSaveInspectionContent = (props: Props) => {
  const { state, dispatch } = props;
  const [errors, setErrors] = useState<any>({});
  const [isSavingContent, setIsSavingContent] = useBoolean();

  const isDirty = useMemo(() => {
    if (!state?.inspectionContent?.id) {
      return false;
    }

    const {
      inspectionContent: content,
      contentItems: items,
      itemResults,
    } = state;
    const {
      inspectionContent: defaultContent,
      contentItems: defaultItems = [],
      itemResults: defaultItemResults = [],
    } = state.defaultState || {};

    const isUpdateContent = content?.updatedAt !== defaultContent?.updatedAt;
    const isUpdateItems =
      items.map((item) => item.updatedAt).join(",") !==
      defaultItems.map((item) => item.updatedAt).join(",");
    const isUpdateItemResults =
      itemResults.map((item) => item.updatedAt).join(",") !==
      defaultItemResults.map((item) => item.updatedAt).join(",");

    return isUpdateContent || isUpdateItems || isUpdateItemResults;
  }, [state]);

  const validate = useCallback(async (): Promise<boolean> => {
    setErrors({});
    const result = schema.safeParse(state);

    if (result.error?.errors) {
      const errors: any = {};
      for (const error of result.error?.errors) {
        set(errors, error.path.join("."), { message: error.message });
      }

      setErrors(errors);

      return false;
    }

    return true;
  }, [state]);

  const onSaveContent = useCallback(async () => {
    const handleError = () => {
      message.error("検査内容の保存に失敗しました。");

      // revert data
      if (state.defaultState) {
        const prevInitData: FormBuilderState = structuredClone(
          state.defaultState
        );
        prevInitData.defaultState = structuredClone(state.defaultState);
        dispatch(initInspectionData(prevInitData));
      }
    };

    try {
      setIsSavingContent.on();
      const isDataValid = await validate();
      if (!isDataValid) {
        message.error(
          "必須項目が未入力です。すべての必須フィールドに入力してください。"
        );
        setTimeout(() => {
          const divInvalid = document.querySelector("div[data-invalid]");
          divInvalid?.scrollIntoView({ block: "start", behavior: "smooth" });
        }, 300);

        return Promise.reject();
      }

      const { itemResults: defItemResults = [], contentItems: defItems = [] } =
        state.defaultState || {};

      const { itemResults, contentItems: items } = state || {};

      const mapItem = arrayToObject(items, "id");
      const mapItemResult = arrayToObject(itemResults, "id");

      // check items need delete
      const deletedItemIds: string[] = [];
      defItems.forEach((item) => {
        if (!mapItem?.[item.id] && item.id) {
          deletedItemIds.push(item.id);
        }
      });
      // check item results need delete
      const deletedItemResultIds: string[] = [];
      defItemResults.forEach((item) => {
        if (!mapItemResult?.[item.id] && item.id) {
          deletedItemResultIds.push(item.id);
        }
      });

      const payload = await inspectionContentApi.updateInspectionContent({
        inspectionContent: state.inspectionContent,
        contentItems: state.contentItems,
        itemResults: state.itemResults,
        deleteInspectionItemIds: deletedItemIds,
        deleteItemResultIds: deletedItemResultIds,
      });
      const result = payload?.data;
      if (!result) {
        handleError();

        return Promise.reject();
      }

      // update init data
      const newInitData: FormBuilderState = {
        inspectionContent: result?.inspectionContent ?? state.inspectionContent,
        contentItems: result?.contentItems ?? state.contentItems,
        itemResults: result?.itemResults ?? state.itemResults,
      };

      const channel = new BroadcastChannel("InspectionContent");
      const data = {
        inspectionTypeId: newInitData.inspectionContent.inspectionTypeId,
        isChanged: true,
      };
      channel.postMessage(JSON.stringify(data));
      channel.close();
      newInitData.defaultState = structuredClone(newInitData);
      dispatch(initInspectionData(newInitData));
      message.success("検査内容が保存されました。");

      return newInitData;
    } catch (err) {
      logDev(err);
      handleError();

      return Promise.reject(err);
    } finally {
      setIsSavingContent.off();
    }
  }, [state, dispatch, setIsSavingContent, validate]);

  return { isDirty, isSavingContent, validate, errors, onSaveContent };
};

export default useSaveInspectionContent;
